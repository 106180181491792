import React from 'react';
import { TitleMenuWrapper } from './styles';

export type TitleMenuProps = {
  loading: string;
};

const defaultProps: TitleMenuProps = {
  loading: '',
};

const TitleMenu: React.FC<TitleMenuProps> = ({ children, ...otherProps }) => {
  return <TitleMenuWrapper {...otherProps}>{children}</TitleMenuWrapper>;
};

TitleMenu.defaultProps = defaultProps;

export default TitleMenu;
