import React from 'react';
import { useAppSelector } from '../../../../app/hooks';
import { getColorList, getColorStatus } from '../../../../redux/colorsSlice';

import Loading from '../Loading';
import RoomImage from '../../../../components/RoomImage';
import { OverlayColor, RoomViewContainer } from './styles';
import HeaderColor from '../../../../components/HeaderColor';
import MediaComponent from '../../../../components/MediaComponent';
import RoomSelector from '../RoomSelector';

export type RoomViewProps = {
  backgroundColor: string;
  onClick: () => void;
};

const RoomView: React.FC<RoomViewProps> = ({ ...props }) => {
  const colorStatus = useAppSelector(getColorStatus);
  const colorList = useAppSelector(getColorList);

  return (
    <>
      <RoomViewContainer {...props} data-testid="room-view">
        {colorStatus == 'loading' && !colorList.length ? (
          <MediaComponent from="tablet">
            <Loading />
          </MediaComponent>
        ) : (
          <HeaderColor />
        )}
        <RoomImage />
        <MediaComponent from="tablet">
          <RoomSelector />
        </MediaComponent>
      </RoomViewContainer>
      <OverlayColor {...props} />
    </>
  );
};

export default RoomView;
