import styled from 'styled-components';

export const InputSpinnerWrapper = styled.div`
  height: 40px;
  display: grid;
  grid-template-columns: 40px 1fr 40px;
  grid-template-rows: 1fr;
  gap: 0 0;
  grid-template-areas: '. . .';

  button:first-child {
    border-radius: 3px 0 0 3px;
    border-right: none;
  }

  button:last-child {
    border-radius: 0 3px 3px 0;
  }
`;

export const InputWrapper = styled.input`
  border: 1px solid #bfbfbf;
  text-align: center;
  font-size: 16px;
  width: calc(100%);
  padding: 2px 1px;
  appearance: none;
  border-radius: 0;
  margin: 0;

  &:focus {
    outline: none;
  }

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

export const ButtonWrapper = styled.button`
  border: 1px solid #bfbfbf;
  color: #000000;
  font-size: 24px;
  background-color: #ffffff;
  padding: 0;
  margin: 0;
  cursor: pointer;
`;
