import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import InputSpinner from '../../../../components/InputSpinner';
import {
  getNumberOfLayers,
  getSquareMeter,
  getTotalGallons,
  setQualitesAndQuantitiesData,
  setNumberOfLayers,
  showCalculateSquareMeters,
  setSquareMeter,
  setTotalGallons,
  resetEstimation,
} from '../../../../redux/calculateQuantitySlice';
import {
  ActionWrapper,
  CalculateMetersFormWrapper,
  FormWrapper,
  HelpBlockWrapper,
  LabelWrapper,
  NumberOfGallons,
  NumberOfLayersInputWrapper,
  UnitOfMeasurementWrapper,
  SquareMeterWrapper,
  LayersOfPaintsWrapper,
  ResultWrapper,
  ResultLabelWrapper,
  ResultValueWrapper,
  IndicationInput,
  InlineIcon,
  PopoverContent,
} from './styles';
import { addBucketDistributionByQuality, squareMeterToGallon } from './utils';
import QuestionIcon from '../../../../images/icons/question-icon.svg';
import InfoIcon from '../../../../images/icons/info.svg';
import {
  Input,
  Popover,
  PopoverContainer,
} from '@digital-retail/journey-ui-kit';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { BREAKPOINTS } from '../../../../utils/constants/breakpoints';
import useCalculateMetersForm from '../../hooks/useCalculateMetersForm';
import { INPUT_REGEX } from '../../../../utils/constants';

const singularUnitOfMeasurement = 'Galon';
const pluralUnitOfMeasurement = 'Galones';

const CalculateMetersForm = () => {
  const dispatch = useAppDispatch();
  const numberOfLayers = useAppSelector(getNumberOfLayers);
  const squareMeter = useAppSelector(getSquareMeter);
  const totalGallons = useAppSelector(getTotalGallons);
  const [paintInformationVisible, setPaintInformationVisible] = useState(false);
  const { finalSkusWithSizes } = useCalculateMetersForm();

  const switchPaintInformation = () =>
    setPaintInformationVisible(!paintInformationVisible);

  const handleChangeSquareMeterInput = (value: string) => {
    dispatch(resetEstimation());
    dispatch(setSquareMeter(value));
  };

  const handleChangeNumberOfLayersInput = (value: number) => {
    dispatch(setNumberOfLayers(value));
  };

  const handleClickAction = () => {
    dispatch(showCalculateSquareMeters());
  };

  const handleClickOutside = (event: any) => {
    const dataTestId = event.target.dataset.testid || null;
    const closePopover = ![
      'popover-info-meters-paint',
      'popover-content',
      'popover-title',
      'popover-description',
    ].includes(dataTestId);
    if (paintInformationVisible && closePopover) {
      setPaintInformationVisible(false);
    }
  };

  useEffect(() => {
    if (totalGallons !== null) {
      dispatch(
        setQualitesAndQuantitiesData(
          addBucketDistributionByQuality(finalSkusWithSizes, totalGallons)
        )
      );
    }
  }, [totalGallons]);

  useEffect(() => {
    const squareMeterInNumber =
      squareMeter !== null ? parseFloat(squareMeter) : 0;
    dispatch(
      setTotalGallons(squareMeterToGallon(squareMeterInNumber, numberOfLayers))
    );
  }, [squareMeter, numberOfLayers]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => document.removeEventListener('click', handleClickOutside);
  }, [paintInformationVisible]);

  const { width: widthScreen } = useWindowSize();

  return (
    <CalculateMetersFormWrapper data-testid="calculate-meters-form">
      <FormWrapper>
        <SquareMeterWrapper>
          <LabelWrapper>
            Ingresa los m<sup>2</sup> &nbsp; a pintar
          </LabelWrapper>
          <Input
            placeholder="Ej. 12"
            data-testid="square-meter-input"
            value={squareMeter?.toString() || ''}
            type="decimal"
            onChange={handleChangeSquareMeterInput}
            customRegex={INPUT_REGEX}
            variant="outline"
          />
          <IndicationInput>Ingresa solo números y puntos</IndicationInput>
        </SquareMeterWrapper>
        <LayersOfPaintsWrapper>
          <LabelWrapper>
            Capas de pinturas
            <PopoverContainer>
              <InlineIcon
                data-testid="icon-info-meters-paint"
                src={InfoIcon}
                onClick={() => switchPaintInformation()}
              />
              <Popover
                data-testid="popover-info-meters-paint"
                size={253}
                align={
                  widthScreen && widthScreen > BREAKPOINTS.tablet
                    ? 'center'
                    : 'right'
                }
                data-visible={paintInformationVisible}
                visible={paintInformationVisible}
              >
                <PopoverContent data-testid="popover-content">
                  <p className="title" data-testid="popover-title">
                    ¿Cuantas capas de pintura necesitas?
                  </p>
                  <p className="description" data-testid="popover-description">
                    Considera comprar suficiente pintura para terminar dos
                    capas, que te ayudará a garantizar el color final y una
                    mejor cobertura para tu proyecto.
                    <br /> Además, para una preparación adecuada de la
                    superficie, puede ser necesaria una capa de primer.
                  </p>
                </PopoverContent>
              </Popover>
            </PopoverContainer>
          </LabelWrapper>
          <NumberOfLayersInputWrapper>
            <InputSpinner
              defaultValue={numberOfLayers || 0}
              onChange={handleChangeNumberOfLayersInput}
              min={1}
            />
          </NumberOfLayersInputWrapper>
        </LayersOfPaintsWrapper>
        <ResultWrapper>
          <ResultLabelWrapper>Pintura estimada:</ResultLabelWrapper>
          <ResultValueWrapper>
            <NumberOfGallons>{totalGallons}</NumberOfGallons>
            <UnitOfMeasurementWrapper>
              {totalGallons === 1
                ? singularUnitOfMeasurement
                : pluralUnitOfMeasurement}
            </UnitOfMeasurementWrapper>
          </ResultValueWrapper>
        </ResultWrapper>
      </FormWrapper>
      <ActionWrapper onClick={handleClickAction}>
        <span>
          Cómo calcular tus m<sup>2</sup>
        </span>
        <InlineIcon src={QuestionIcon} />
      </ActionWrapper>
      <HelpBlockWrapper>
        *El resultado de la calculadora es una estimación cercana de las
        cantidades que necesitas para pintar.
      </HelpBlockWrapper>
    </CalculateMetersFormWrapper>
  );
};

export default CalculateMetersForm;
