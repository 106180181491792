import { useAppSelector } from '../../../../app/hooks';
import {
  getVisibleSheenOptions,
  getSelectedColor,
  getSelectedColorIndex,
  getSelectedSheen,
  getColorList,
  getNoColorsFound,
} from '../../../../redux/colorsSlice';

import {
  ArrowCoat,
  ContainerCoat,
  CoatButton,
  OptionStyled,
  OptionsStyled,
  AsideOptionsStyled,
  MenuColorDesktopStyled,
  GridColor,
  IconStyled,
  SearchInputStyled,
  SearchMessageStyled,
  MainOptionsStyled,
  ColorMesssageStyled,
} from './styles';
import { IColor } from '../../../../models/IColor';
import GridColorButton from '../GridColorButton';
import FormCoat from '../FormCoat';
import { Button } from '@digital-retail/journey-ui-kit';
import FeaturedColorsIcon from '../FeaturedColorsIcon';
import { ReactComponent as SearchIcon } from '../../../../images/icons/search.svg';
import {
  getSearchSeed,
  getTabOption,
} from '../../../../redux/chooseColorSlice';
import { Input } from '@digital-retail/journey-ui-kit';
import { COLORS } from '../../../../utils/constants/colors';
import Box from '../../../../components/Box';
import { getColorFamilyList } from '../../../../redux/configSlice';
import Spinner from '../Spinner';
import useHandleInputValueChange from '../../hooks/useHandleInputValueChange';
import useChooseColor from '../../hooks/useChooseColor';
import useChooseBrightness from '../../hooks/useChooseBrightness';
import NoSheens from '../NoSheens';

export type ButtonIconProps = {
  active?: boolean;
  firstChild?: boolean;
  // eslint-disable-next-line no-unused-vars
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export interface RowCoatProps {
  index: number;
}

export type ColorButtonProps = {
  paintColor: IColor;
  selected: boolean;
};

export type ContainerCoatProps = {
  index: number;
  visible: boolean;
};

export type arrNumber = any[];

const MenuColorDesktop: React.FC = () => {
  const colorFamilyList = useAppSelector(getColorFamilyList);
  const colorList = useAppSelector(getColorList);
  const indexColor = useAppSelector(getSelectedColorIndex);
  const visibleCoatMenu = useAppSelector(getVisibleSheenOptions);
  const selectedSheen = useAppSelector(getSelectedSheen);
  const selectedColor = useAppSelector(getSelectedColor);
  const searchSeed = useAppSelector(getSearchSeed);
  const tabOption = useAppSelector(getTabOption);
  const handleInputValueChange = useHandleInputValueChange();
  const {
    handleClickSelectColorDesktop,
    handleClickSelectColorFamily,
    handleClickShowSearch,
    isSkusLoading,
    regexFinderColor,
  } = useChooseColor();
  const { handleOnClickContinue, hideSheenOptionsDesktop } =
    useChooseBrightness();
  const noColorsFound = useAppSelector(getNoColorsFound);
  const showColorMessage = colorList.length > 0 || !noColorsFound;

  return (
    <MenuColorDesktopStyled
      data-testid="menu-color-desktop"
      onClick={() => hideSheenOptionsDesktop()}
      className={isSkusLoading ? 'loading' : ''}
    >
      {isSkusLoading && <Spinner />}
      <AsideOptionsStyled>
        <OptionsStyled data-testid="tab-options">
          <OptionStyled
            data-testid="tab-option-search"
            firstChild={true}
            active={tabOption === 'SEARCH'}
            onClick={handleClickShowSearch}
          >
            <IconStyled>
              <SearchIcon style={{ fill: COLORS.primary }} />
            </IconStyled>
          </OptionStyled>
          {colorFamilyList.map((colorFamily, colorFamilyIndex) => (
            <OptionStyled
              key={colorFamilyIndex}
              data-testid="tab-option"
              firstChild={false}
              active={colorFamily.id === tabOption}
              onClick={() => {
                handleClickSelectColorFamily(colorFamily.id);
              }}
            >
              <IconStyled>
                <FeaturedColorsIcon
                  disabled={false}
                  colors={colorFamily.featuredColors}
                />
              </IconStyled>
            </OptionStyled>
          ))}
        </OptionsStyled>
      </AsideOptionsStyled>
      <MainOptionsStyled>
        <Box maxHeight="398px" isInfiniteScroll={true}>
          {tabOption === 'SEARCH' && (
            <SearchInputStyled>
              <Input
                data-testid="search-seed-input"
                customRegex={regexFinderColor}
                placeholder="Buscar por nombre o código"
                value={searchSeed}
                type="text"
                onChange={handleInputValueChange}
                inputMode="text"
              />
            </SearchInputStyled>
          )}
          {searchSeed.length !== 0 && noColorsFound && (
            <SearchMessageStyled data-testid="search-message">
              “La búsqueda no obtuvo resultados.”
            </SearchMessageStyled>
          )}
          {showColorMessage && (
            <ColorMesssageStyled>
              Los colores podrían variar según la pantalla.
            </ColorMesssageStyled>
          )}
          <GridColor data-testid="grid">
            <GridColorButton
              colors={colorList}
              selectedColor={selectedColor}
              onSelectColor={handleClickSelectColorDesktop}
            />
            <ContainerCoat
              data-testid="container-coat"
              onClick={(e) => e.stopPropagation()}
              index={indexColor}
              visible={visibleCoatMenu}
            >
              <ArrowCoat index={indexColor} />
              <FormCoat />
              <NoSheens />
            </ContainerCoat>
          </GridColor>
        </Box>
        <CoatButton>
          <Button
            fullWidth={true}
            disabled={selectedSheen.name === '' || selectedColor.id === ''}
            data-testid="button-next-position"
            onClick={handleOnClickContinue}
          >
            Continuar
          </Button>
        </CoatButton>
      </MainOptionsStyled>
    </MenuColorDesktopStyled>
  );
};

export default MenuColorDesktop;
