/* istanbul ignore file */
import { IAvailabilityRequest } from '../../models/IAvailabilityRequest';
import { IColorRequest } from '../../models/IColorsRequest';
import { ISkusRequest } from '../../models/ISkusRequest';
import { getAvailabilityUrl } from '../../utils/availabilityServiceUtils';
import { getPaintServiceUrl } from '../../utils/getPaintServiceUrl';

export default {
  fetchColors: (props: IColorRequest) => {
    const {
      tenant,
      brand,
      group,
      name,
      code,
      nextColorId,
      nextColorName,
      limit = 50,
    } = props;
    const url = `${getPaintServiceUrl(tenant)}/paints/${tenant}/colors`;
    const urlObj = new URL(url);
    const params = new URLSearchParams(urlObj.search);
    if (brand) params.set('brand', brand);
    if (group) params.set('group', group);
    if (name) params.set('name', name);
    if (code) params.set('code', code);
    if (nextColorId) params.set('nextColorId', nextColorId);
    if (nextColorName) params.set('nextColorName', nextColorName);
    if (limit) params.set('limit', limit.toString());

    urlObj.search = params.toString();
    const finalUrl = urlObj.toString();
    return {
      url: finalUrl,
      params: { method: 'GET' },
    };
  },
  fetchConfig: (tenant: string) => ({
    url: `${getPaintServiceUrl(tenant)}/paints/${tenant}/config`,
    params: { method: 'GET' },
  }),
  fetchAvailability: (props: IAvailabilityRequest) => ({
    url: `${getAvailabilityUrl(props)}`,
    params: { method: 'GET' },
  }),
  fetchSkusData: (props: ISkusRequest) => {
    const { tenant, skus } = props;
    const formattedTenant = tenant.toLowerCase();
    const url = `${getPaintServiceUrl(tenant)}/paints/${formattedTenant}/skus`;
    const urlObj = new URL(url);
    const params = new URLSearchParams(urlObj.search);
    if (skus) params.set('skus', skus);
    urlObj.search = params.toString();
    const finalUrl = urlObj.toString();
    return {
      url: finalUrl,
      params: { method: 'GET' },
    };
  },
};
