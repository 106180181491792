import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  getRoomList,
  getSelectedRoom,
  selectRoom,
} from '../../../../redux/roomSlice';
import {
  BackCarouselWrapper,
  ForwardCarouselWrapper,
  RoomContainerWrapper,
  RoomImagenWrapper,
  RoomNameWrapper,
  RoomPickerWrapper,
  RoomCarouselWrapper,
} from './styles';
import {
  findIndexFirstRoomInView,
  createScrollStopListener,
  handleClickBackCarousel,
  handleClickForwardCarousel,
} from './utils';
import { ReactComponent as ArrowCarousel } from '../../../../images/icons/arrow.svg';
import MediaComponent from '../../../../components/MediaComponent';
import { reset } from '../../../../redux/colorsSlice';
import { resetChooseColorState } from '../../../../redux/chooseColorSlice';

const RoomCarousel: React.FC = () => {
  const dispatch = useAppDispatch();
  const rooms = useAppSelector(getRoomList);
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const selectedRoom = useAppSelector(getSelectedRoom);
  const roomRefs = useMemo(
    () => rooms.map(() => React.createRef<HTMLDivElement>()),
    []
  );
  const [pivotRoom, setPivotRoom] = useState<number>(0);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleClickRoomPicker = (roomIndex: number) => {
    dispatch(selectRoom(rooms[roomIndex]));
    // reset when change room
    if (selectedRoom.application.id != rooms[roomIndex].application.id) {
      dispatch(reset());
      dispatch(resetChooseColorState());
    }
  };

  useEffect(() => {
    const destroyListener = createScrollStopListener(containerRef, () => {
      setPivotRoom(findIndexFirstRoomInView({ containerRef, roomRefs }));
    });
    return () => destroyListener(); // when App component is unmounted
  }, []);

  return (
    <RoomCarouselWrapper data-testid="room-carousel">
      <RoomContainerWrapper ref={containerRef} data-testid="room-container">
        {rooms.map(({ name, galleryImage }, roomIndex) => (
          <RoomPickerWrapper
            ref={roomRefs[roomIndex]}
            key={roomIndex}
            onClick={() => handleClickRoomPicker(roomIndex)}
          >
            <RoomImagenWrapper src={galleryImage.mobile} />
            <RoomNameWrapper>{name}</RoomNameWrapper>
          </RoomPickerWrapper>
        ))}
      </RoomContainerWrapper>
      <MediaComponent from="desktop">
        <BackCarouselWrapper
          data-testid="back-carousel"
          onClick={() =>
            handleClickBackCarousel({
              pivotRoom,
              containerRef,
              roomRefs,
              callback: (newPivotRoom) => setPivotRoom(newPivotRoom),
            })
          }
        >
          <ArrowCarousel />
        </BackCarouselWrapper>
        <ForwardCarouselWrapper
          data-testid="forward-carousel"
          onClick={() =>
            handleClickForwardCarousel({
              pivotRoom,
              containerRef,
              roomRefs,
              callback: (newPivotRoom) => setPivotRoom(newPivotRoom),
            })
          }
        >
          <ArrowCarousel />
        </ForwardCarouselWrapper>
      </MediaComponent>
    </RoomCarouselWrapper>
  );
};

export default RoomCarousel;
