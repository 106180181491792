/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from 'react';
import { ButtonWrapper, InputSpinnerWrapper, InputWrapper } from './styles';

type InputSpinnerProps = {
  defaultValue?: number;
  // eslint-disable-next-line no-unused-vars
  onChange?: (value: number) => void;

  min?: number;

  max?: number;
};

const defaultProps: InputSpinnerProps = {};

const InputSpinner: React.FC<InputSpinnerProps> = ({
  defaultValue,
  onChange,
  min,
  max,
}) => {
  const [value, setValue] = useState<string>(defaultValue?.toString() || '');

  const getValueToInt = () => {
    return parseInt(value || '0');
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.currentTarget.value);
  };

  const handleClickDecrease = () => {
    const valueToInt = getValueToInt();
    if (min !== undefined) {
      if (valueToInt === min) {
        return false;
      }
    }
    setValue((valueToInt - 1).toString());
  };

  const handleClickIncrease = () => {
    const valueToInt = getValueToInt();
    if (max !== undefined) {
      if (valueToInt === max) {
        return false;
      }
    }
    setValue((valueToInt + 1).toString());
  };

  useEffect(() => {
    if (onChange !== undefined) {
      onChange(getValueToInt());
    }
  }, [value]);

  return (
    <InputSpinnerWrapper data-testid="input-spinner">
      <ButtonWrapper
        onClick={handleClickDecrease}
        data-testid="button-decrease"
      >
        -
      </ButtonWrapper>
      <InputWrapper
        type="number"
        value={value}
        onChange={handleChange}
        data-testid="input"
        min={min}
        max={max}
        pattern="\d*"
      />
      <ButtonWrapper
        onClick={handleClickIncrease}
        data-testid="button-increase"
      >
        +
      </ButtonWrapper>
    </InputSpinnerWrapper>
  );
};

InputSpinner.defaultProps = defaultProps;

export default InputSpinner;
