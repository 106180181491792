import {
  ButtonIconContainer,
  CardsContainer,
  DesktopWrapper,
  PageTitle,
  VolverButton,
  VolverButtonContainer,
} from './styles';
import QualitiesAndQuantitiesCard from '../../../../../components/QualitiesAndQuantitiesCard';
import { useAppSelector } from '../../../../../app/hooks';
import { getQualities } from '../../../../../redux/configSlice';
import { ReactComponent as LeftArrow } from '../../../../../images/icons/left-arrow.svg';

const QualitiesAndQuantitiesDesktop = ({
  qualitiesAndQuantitiesData,
  handleQuantityChange,
  handleClickPreviousPosition,
}) => {
  const qualities = useAppSelector(getQualities);

  return (
    <DesktopWrapper>
      <VolverButtonContainer onClick={handleClickPreviousPosition}>
        <ButtonIconContainer>
          <LeftArrow />
        </ButtonIconContainer>
        <VolverButton>Volver</VolverButton>
      </VolverButtonContainer>
      <PageTitle>
        Elige la <b>calidad y formato</b> de tu pintura
      </PageTitle>
      <CardsContainer>
        {qualitiesAndQuantitiesData.map((qualityAndQuantityData) => {
          const { quality, values, characteristics } = qualityAndQuantityData;
          return (
            <QualitiesAndQuantitiesCard
              key={quality}
              qualityName={qualities.filter((q) => q.id === quality)[0].name}
              characteristics={characteristics}
              quantityData={values}
              qualityId={quality}
              handleQuantityChange={handleQuantityChange}
            />
          );
        })}
      </CardsContainer>
    </DesktopWrapper>
  );
};

export default QualitiesAndQuantitiesDesktop;
