import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { IGallonsDistribution } from '../../models/IGallonsDistribution';

type Steps = 'initial' | 'calculateMeters';

type CalculateQuantityState = {
  step: Steps;
  squareMeter: string | null;
  numberOfLayers: number;
  totalGallons: number | null;
  gallonDistribution: IGallonsDistribution | null;
  showCalculateSquareMeters: boolean;
  estimatedSquareMeters: number | null;
  roomHeight: string | null;
  roomLong: string | null;
  numberOfRoomDoors: number;
  numberOfRoomWindows: number;
  qualitesAndQuantitiesData: any;
};

const initialState: CalculateQuantityState = {
  step: 'initial',
  squareMeter: null,
  numberOfLayers: 2,
  totalGallons: null,
  gallonDistribution: null,
  showCalculateSquareMeters: false,
  estimatedSquareMeters: null,
  roomHeight: null,
  roomLong: null,
  numberOfRoomDoors: 1,
  numberOfRoomWindows: 0,
  qualitesAndQuantitiesData: null,
};

export const calculateQuantitySlice = createSlice({
  name: 'calculateQuantity',
  initialState,
  reducers: {
    showCalculateSquareMeters: (state) => {
      state.showCalculateSquareMeters = true;
    },
    hideCalculateSquareMeters: (state) => {
      state.showCalculateSquareMeters = false;
    },
    setStep: (state, action: PayloadAction<Steps>) => {
      state.step = action.payload;
    },
    resetEstimation: (state) => {
      state.estimatedSquareMeters = initialState.estimatedSquareMeters;
      state.numberOfRoomDoors = initialState.numberOfRoomDoors;
      state.numberOfRoomWindows = initialState.numberOfRoomWindows;
      state.roomHeight = initialState.roomHeight;
      state.roomLong = initialState.roomLong;
    },
    setEstimatedSquareMeters: (
      state,
      action: PayloadAction<CalculateQuantityState['estimatedSquareMeters']>
    ) => {
      state.estimatedSquareMeters = action.payload;
    },
    setRoomHeight: (
      state,
      action: PayloadAction<CalculateQuantityState['roomHeight']>
    ) => {
      state.roomHeight = action.payload;
    },
    setRoomLong: (
      state,
      action: PayloadAction<CalculateQuantityState['roomLong']>
    ) => {
      state.roomLong = action.payload;
    },
    setNumberOfRoomDoors: (
      state,
      action: PayloadAction<CalculateQuantityState['numberOfRoomDoors']>
    ) => {
      state.numberOfRoomDoors = action.payload;
    },
    setNumberOfRoomWindows: (
      state,
      action: PayloadAction<CalculateQuantityState['numberOfRoomWindows']>
    ) => {
      state.numberOfRoomWindows = action.payload;
    },
    setSquareMeter: (
      state,
      action: PayloadAction<CalculateQuantityState['squareMeter']>
    ) => {
      state.squareMeter = action.payload;
    },
    setNumberOfLayers: (
      state,
      action: PayloadAction<CalculateQuantityState['numberOfLayers']>
    ) => {
      state.numberOfLayers = action.payload;
    },
    setTotalGallons: (
      state,
      action: PayloadAction<CalculateQuantityState['totalGallons']>
    ) => {
      state.totalGallons = action.payload;
    },
    setGallonDistribution: (
      state,
      action: PayloadAction<CalculateQuantityState['gallonDistribution']>
    ) => {
      state.gallonDistribution = action.payload;
    },
    setQualitesAndQuantitiesData: (
      state,
      action: PayloadAction<CalculateQuantityState['qualitesAndQuantitiesData']>
    ) => {
      state.qualitesAndQuantitiesData = action.payload;
    },
    updateGallonDistribution: (
      state,
      action: PayloadAction<CalculateQuantityState['gallonDistribution']>
    ) => {
      state.gallonDistribution = {
        ...state.gallonDistribution,
        ...action.payload,
      };
    },
    resetCalculatorQuantityState: () => initialState,
  },
});

export const {
  showCalculateSquareMeters,
  hideCalculateSquareMeters,
  setStep,
  setSquareMeter,
  setNumberOfLayers,
  setTotalGallons,
  setGallonDistribution,
  updateGallonDistribution,
  setEstimatedSquareMeters,
  resetEstimation,
  setRoomHeight,
  setRoomLong,
  setNumberOfRoomDoors,
  setNumberOfRoomWindows,
  setQualitesAndQuantitiesData,
  resetCalculatorQuantityState,
} = calculateQuantitySlice.actions;

export const getShowCalculateSquareMeters = (state: RootState) =>
  state.calculateQuantity.showCalculateSquareMeters;

export const getSquareMeter = (state: RootState) =>
  state.calculateQuantity.squareMeter;

export const getNumberOfLayers = (state: RootState) =>
  state.calculateQuantity.numberOfLayers;

export const getStep = (state: RootState) => state.calculateQuantity.step;

export const getTotalGallons = (state: RootState) =>
  state.calculateQuantity.totalGallons;

export const getGallonDistribution = (state: RootState) =>
  state.calculateQuantity.gallonDistribution;

export const getEstimatedSquareMeters = (state: RootState) =>
  state.calculateQuantity.estimatedSquareMeters;

export const getRoomHeight = (state: RootState) =>
  state.calculateQuantity.roomHeight;

export const getRoomLong = (state: RootState) =>
  state.calculateQuantity.roomLong;

export const getNumberOfRoomDoors = (state: RootState) =>
  state.calculateQuantity.numberOfRoomDoors;

export const getNumberOfRoomWindows = (state: RootState) =>
  state.calculateQuantity.numberOfRoomWindows;

export const getQualitesAndQuantitiesData = (state: RootState) =>
  state.calculateQuantity.qualitesAndQuantitiesData;

export default calculateQuantitySlice.reducer;
