import {
  SheenOptionsWrapper,
  SheenItemWrapper,
  SheenLabelWrapper,
  SheenColor,
  SheenNameWrapper,
  CloseButton,
  SheenTitle,
} from './styles';
import { useAppSelector } from '../../app/hooks';
import {
  getAvailableSkusData,
  getSelectedColor,
  getSelectedSheen,
  getSheenList,
  getSkusStatus,
  getVisibleSheenOptions,
} from '../../redux/colorsSlice';
import rgbToHex from '../../utils/rgbToHex';
import { getUniqueBrigthnessTypes } from '../../utils/commonUtils';
import useChooseBrightness from '../../features/ChooseColor/hooks/useChooseBrightness';
import { ReactComponent as CloseIcon } from '../../images/icons/close.svg';
import NoSheens from '../../features/ChooseColor/components/NoSheens';
import RadioSelect from '../../features/ChooseColor/components/RadioSelect';
import RoundButton from '../RoundButton';

const SheenOptions = () => {
  const { handleOnClickContinue, hideSheenOptions } = useChooseBrightness();
  const sheenList = useAppSelector(getSheenList);
  const opened = useAppSelector(getVisibleSheenOptions);
  const selectedSheen = useAppSelector(getSelectedSheen);
  const selectedColor = useAppSelector(getSelectedColor);
  const availableSkus = useAppSelector(getAvailableSkusData);
  const possibleSheenTypes = getUniqueBrigthnessTypes(availableSkus);
  const { handleClickChangeSheen } = useChooseBrightness();
  const latestSheenList = sheenList.filter((sheen) => {
    return sheen.id in possibleSheenTypes;
  });
  const statusSkus = useAppSelector(getSkusStatus);
  const loading = statusSkus === 'loading';

  const setSheen = (sheen, isSelectedSheen) =>
    handleClickChangeSheen(sheen, isSelectedSheen);

  const Sheens = () => {
    return (
      <>
        <SheenTitle>Selecciona el acabado</SheenTitle>
        {latestSheenList.map((sheen, sheenIndex) => {
          const { brightness = '0.95' } = sheen;
          const isSheenSelected = sheen.id === selectedSheen.id;
          const optionDisabled = sheen.disabled;

          return (
            <SheenItemWrapper key={sheenIndex}>
              <SheenLabelWrapper className={optionDisabled ? 'disabled' : ''}>
                <RadioSelect
                  middleCenter
                  value={sheen.id}
                  currentValue={selectedSheen.id}
                  onSelect={() => setSheen(sheen, isSheenSelected)}
                  blocked={optionDisabled}
                >
                  <SheenColor
                    data-testid={`radio-button-coat-${sheenIndex}`}
                    style={{
                      backgroundColor: rgbToHex(selectedColor.rgb),
                      backgroundImage: `url(${sheen.image})`,
                    }}
                    brightness={brightness}
                  />
                  <SheenNameWrapper>
                    <h3>{sheen.name}</h3>
                    <span>{sheen.shortDescription}</span>
                  </SheenNameWrapper>
                </RadioSelect>
              </SheenLabelWrapper>
            </SheenItemWrapper>
          );
        })}
        <RoundButton
          disabled={selectedSheen.name === '' || selectedColor.id === ''}
          onClick={handleOnClickContinue}
        >
          Continuar
        </RoundButton>
      </>
    );
  };

  return (
    <SheenOptionsWrapper
      data-testid="coat-options"
      visible={opened && !loading}
    >
      <CloseButton onClick={() => hideSheenOptions()}>
        <CloseIcon />
      </CloseButton>
      {availableSkus && availableSkus.length > 0 ? <Sheens /> : <NoSheens />}
    </SheenOptionsWrapper>
  );
};

export default SheenOptions;
