import { useMemo } from 'react';
import { debounce } from '../../../utils/debounce';
import {
  fetchColorsAsync,
  getSelectedBrands,
} from '../../../redux/colorsSlice';
import { REPLACE } from '../../../utils/constants';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { getCodeRegex, getTenant } from '../../../redux/configSlice';

const useDebounceFetchColors = (wait: number) => {
  const dispatch = useAppDispatch();
  const currentTenant = useAppSelector(getTenant);
  const brands = useAppSelector(getSelectedBrands).join(',');
  const codeRegex = useAppSelector(getCodeRegex);

  const dispatchFetchColorsAction = (value: string) => {
    let nameOrCode = 'name';
    if (codeRegex) {
      const CRX = new RegExp(codeRegex);
      if (CRX.test(value)) nameOrCode = 'code';
    }
    if (value) {
      const props = {
        tenant: currentTenant,
        brand: brands,
        mode: REPLACE,
        [nameOrCode]: value,
      };
      dispatch(fetchColorsAsync(props));
    }
  };

  const debouncedFetchColors = useMemo(() => {
    return debounce(dispatchFetchColorsAction, wait);
  }, [brands]);
  return debouncedFetchColors;
};

export default useDebounceFetchColors;
