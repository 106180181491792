import Cookies from 'js-cookie';
import { COOKIE_NAMES, SOURCES } from '../../../utils/constants';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { getSelectedColor, getSelectedSheen } from '../../../redux/colorsSlice';
import {
  getBrandName,
  getCurrentSource,
  sendCartLinesToMobile,
} from '../../../utils/commonUtils';
import {
  getBrands,
  getColorGroups,
  getQualities,
} from '../../../redux/configSlice';
import {
  addItemsToCart,
  getfinalQualitiesAndQuantitiesData,
} from '../../../redux/settingQualitySlice';

const useAddToCart = () => {
  const dispatch = useAppDispatch();
  const qualitiesAndQuantitiesData = useAppSelector(
    getfinalQualitiesAndQuantitiesData
  );
  const selectedColor = useAppSelector(getSelectedColor);
  const allBrands = useAppSelector(getBrands);
  const qualities = useAppSelector(getQualities);
  const selectedSheen = useAppSelector(getSelectedSheen);
  const allColorGroups = useAppSelector(getColorGroups);

  const {
    name: fandeckName,
    id: fandeckCode,
    brand: brandId,
    group: colorGroupId,
    rgb: colorRgb,
  } = selectedColor;

  const brandName = getBrandName(brandId, allBrands);
  const sheenName = selectedSheen.name;
  const colorGroupName = allColorGroups.filter(
    (group) => group.id === colorGroupId
  )[0]?.name;

  const getCustomInfo = (quantity: any, qualityName: string) => {
    const { id: skuId, description: galonSize } = quantity;
    const customInfo = [
      {
        group: 'journey',
        name: 'journeyType',
        values: ['PAINTS'],
      },
      {
        group: 'journey-paints',
        name: 'brand_name',
        values: [brandName],
      },
      {
        group: 'journey-paints',
        name: 'fandeck_code',
        values: [fandeckCode],
      },
      {
        group: 'journey-paints',
        name: 'fandeck_name',
        values: [fandeckName],
      },
      {
        group: 'journey-paints',
        name: 'sku_base',
        values: [skuId],
      },
      {
        group: 'journey-paints',
        name: 'color_code',
        values: [fandeckCode],
      },
      {
        group: 'journey-paints',
        name: 'color_group',
        values: [colorGroupName],
      },
      {
        group: 'journey-paints',
        name: 'color_rgb',
        values: [colorRgb],
      },
      {
        group: 'journey-paints',
        name: 'sheen_name',
        values: [sheenName],
      },
      {
        group: 'journey-paints',
        name: 'quality_name',
        values: [qualityName],
      },
      {
        group: 'journey-paints',
        name: 'gallon_size',
        values: [galonSize],
      },
    ];

    return customInfo;
  };

  const politicalId = Cookies.get(COOKIE_NAMES.POLITICAL_ID) || '';
  const priceGroupId = Cookies.get(COOKIE_NAMES.PRICE_GROUP_Id) || '';
  const authSid = Cookies.get(COOKIE_NAMES.AUTH_SID) || '';
  const cartId = Cookies.get(COOKIE_NAMES.CART_ID) || '';

  const handleAddToCart = async (qualityId: string) => {
    const quantities = qualitiesAndQuantitiesData.find(
      (q) => q.quality === qualityId
    );
    const cartLines = [] as any;
    const qualityName = qualities.filter((q) => q.id === qualityId)[0].name;
    const filteredQuantities = quantities.values.filter(
      (q: any) => q.distribution > 0
    );

    for (const quantity of filteredQuantities) {
      const cartLine = {
        cartLineNumber: `${quantity.id}${colorRgb.replace(/,/g, '')}`,
        item: {
          variantId: quantity.id,
          offeringId: quantity.id,
        },
        quantity: {
          quantityNumber: quantity.distribution,
          quantityUnit: 'C/U',
        },
        customInfo: getCustomInfo(quantity, qualityName),
      };
      cartLines.push(cartLine);
    }

    const source = getCurrentSource();
    if (source === SOURCES.MOBILE_APP) {
      sendCartLinesToMobile(cartLines);
    } else {
      const props = {
        cartLines,
        authSid,
        cartId,
        politicalId,
        priceGroup: priceGroupId,
      };

      dispatch(addItemsToCart(props));
    }
  };

  return { handleAddToCart };
};

export default useAddToCart;
