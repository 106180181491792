import React, { useEffect, useState } from 'react';
import { BoxContentStyled, BoxStyled, GradientStyled } from './styles';
import clsx from 'clsx';
import { useAppSelector } from '../../app/hooks';
import { getColorStatus } from '../../redux/colorsSlice';
import { getTenant } from '../../redux/configSlice';
import { getSearchSeed } from '../../redux/chooseColorSlice';
import useInfiniteScrollDesktop from '../../features/ChooseColor/hooks/useInfiniteScrollDesktop';

const delta = 4;

type BoxProps = {
  maxHeight?: string;
  isInfiniteScroll?: boolean;
};

const Box: React.FC<BoxProps> = ({
  children,
  maxHeight,
  isInfiniteScroll = false,
}) => {
  const currentTenant = useAppSelector(getTenant);
  const searchSeed = useAppSelector(getSearchSeed);
  const colorStatus = useAppSelector(getColorStatus);
  const [showGradientTop, setShowGradientTop] = useState(false);
  const [showGradientBottom, setShowGradientBottom] = useState(true);
  const { infiniteScrollRef, fetchAndUpdateColorsList } =
    useInfiniteScrollDesktop({ colorStatus, currentTenant, searchSeed });

  const updateState = () => {
    if (infiniteScrollRef.current) {
      const { scrollTop, offsetHeight, scrollHeight } =
        infiniteScrollRef.current;
      setShowGradientTop(!(scrollTop <= delta));
      setShowGradientBottom(
        !(offsetHeight + scrollTop >= scrollHeight - delta)
      );

      if (isInfiniteScroll) {
        fetchAndUpdateColorsList();
      }
    }
  };

  useEffect(() => {
    updateState();
  }, []);

  return (
    <BoxStyled
      ref={infiniteScrollRef}
      onScroll={() => {
        updateState();
      }}
      onClick={() => {
        setTimeout(() => {
          updateState();
        }, 1);
      }}
      style={{ maxHeight }}
    >
      <GradientStyled className={clsx('top', { show: showGradientTop })} />
      <BoxContentStyled>{children}</BoxContentStyled>
      <GradientStyled
        className={clsx('bottom', { show: showGradientBottom })}
      />
    </BoxStyled>
  );
};

export default Box;
