import { useAppSelector } from '../../../../app/hooks';
import {
  getSelectedColor,
  getColorStatus,
  getColorList,
} from '../../../../redux/colorsSlice';
import clsx from 'clsx';
import {
  OptionsWrapper,
  MobileColorPickerWrapper,
  CategoriesWrapper,
  SearchInputStyled,
  SearchMessageStyled,
  SelectorButtonWrapper,
  ResultColorWrapper,
} from './styles';
import { PillButton } from '@digital-retail/journey-ui-kit';
import { camelCase } from '../../../../utils/camelCase';
import FeaturedColorsIcon from '../FeaturedColorsIcon';
import { ReactComponent as SearchIcon } from '../../../../images/icons/newSearchIcon.svg';
import ResultsNotFound from '../../../../images/Product_not_found.png';
import {
  getSearchSeed,
  getTabOption,
} from '../../../../redux/chooseColorSlice';
import { Input } from '@digital-retail/journey-ui-kit';
import { getColorFamilyList } from '../../../../redux/configSlice';
import useHandleInputValueChange from '../../hooks/useHandleInputValueChange';
import LoaderScreen from '../../../../components/LoaderScreen';
import useChooseColor from '../../hooks/useChooseColor';
import { ColorPicker } from '../ColorPicker';

import SheenOptions from '../../../../components/SheenOptions';
import { useInfiniteScrollMobile } from '../../hooks/useInfiniteScrollMobile';
import { GradientStyled } from '../../../../components/Box/styles';
import { useEffect } from 'react';
import LoadingColor from '../../../../components/LoadingColor';
import FilterMenu from '../FilterMenu';

const MobileColorPicker = () => {
  const colorFamilyList = useAppSelector(getColorFamilyList);
  const colorList = useAppSelector(getColorList);
  const selectedColor = useAppSelector(getSelectedColor);
  const colorStatus = useAppSelector(getColorStatus);
  const searchSeed = useAppSelector(getSearchSeed);
  const tabOption = useAppSelector(getTabOption);
  const handleInputValueChange = useHandleInputValueChange();
  const {
    handleClickSelectColor,
    handleClickShowSearch,
    handleClickSelectColorFamily,
    isSkusLoading,
    regexFinderColor,
  } = useChooseColor();
  const { showGradientBottom } = useInfiniteScrollMobile();
  const resultsNotFound =
    searchSeed.length > 0 &&
    colorList.length === 0 &&
    colorStatus !== 'idle' &&
    colorStatus !== 'loading';

  useEffect(() => {
    if (tabOption == null) handleClickShowSearch();
  }, []);

  return (
    <MobileColorPickerWrapper data-testid="mobile-color-picker">
      <CategoriesWrapper>
        <GradientStyled className={clsx('left show')} />
        <SelectorButtonWrapper active={tabOption === 'SEARCH'}>
          <PillButton
            size="sm"
            onClick={() => handleClickShowSearch()}
            Icon={SearchIcon}
            iconStyle={{
              height: 18,
            }}
            disabled={colorStatus === 'loading'}
          >
            Buscador
          </PillButton>
        </SelectorButtonWrapper>
        {colorFamilyList.map((colorFamily, colorFamilyIndex) => (
          <SelectorButtonWrapper
            active={tabOption === colorFamily.id}
            key={colorFamilyIndex}
          >
            <PillButton
              variant="ghost"
              size="sm"
              onClick={() => handleClickSelectColorFamily(colorFamily.id)}
              disabled={colorStatus === 'loading'}
            >
              <FeaturedColorsIcon colors={colorFamily.featuredColors} />
              {camelCase(colorFamily.name)}
            </PillButton>
          </SelectorButtonWrapper>
        ))}
        <GradientStyled className={clsx('right show')} />
      </CategoriesWrapper>
      {tabOption === 'SEARCH' && (
        <SearchInputStyled>
          <Input
            placeholder="Buscar por nombre o código"
            customRegex={regexFinderColor}
            data-testid="search-seed-input"
            value={searchSeed}
            type="text"
            onChange={handleInputValueChange}
            inputMode="text"
          />
        </SearchInputStyled>
      )}
      {resultsNotFound && (
        <SearchMessageStyled data-testid="search-message">
          <img src={ResultsNotFound} />
          <p>No se encontraron resultados</p>
          <span>
            No te desanimes, puedes explorar entre los colores que hay
            disponibles.
          </span>
        </SearchMessageStyled>
      )}
      {colorStatus === 'loading' && <LoadingColor />}
      {colorList.length > 0 && (
        <ResultColorWrapper>
          <FilterMenu />
          <p>
            Los colores <strong>podrían variar según la pantalla.</strong>
          </p>
          <OptionsWrapper data-testid="color-options">
            <ColorPicker
              colors={colorList}
              selectedColor={selectedColor}
              onSelectColor={handleClickSelectColor}
            />
          </OptionsWrapper>
        </ResultColorWrapper>
      )}
      <GradientStyled
        className={clsx('bottom', { show: showGradientBottom })}
      />
      <SheenOptions />
      {isSkusLoading && <LoaderScreen />}
    </MobileColorPickerWrapper>
  );
};

export default MobileColorPicker;
