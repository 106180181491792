export const REPLACE = 'replace';
export const APPEND = 'append';

export const ENVIRONMENTS = {
  DEVELOPMENT: 'development',
  STAGING: 'staging',
  BETA: 'beta',
  PRODUCTION: 'production',
};

export const AVAILABILITY_SERVICE_URL = {
  [ENVIRONMENTS.DEVELOPMENT]: {
    FA: 'https://staging.falabella.com/s/browse/v1/fetchItemDetails',
    SO: '',
  },
  [ENVIRONMENTS.STAGING]: {
    FA: 'https://staging.falabella.com/s/browse/v1/fetchItemDetails',
    SO: '',
  },
  [ENVIRONMENTS.BETA]: {
    FA: 'https://beta.falabella.com/s/browse/v1/fetchItemDetails',
    SO: '',
  },
  [ENVIRONMENTS.PRODUCTION]: {
    FA: 'https://www.falabella.com/s/browse/v1/fetchItemDetails',
    SO: '',
  },
};

export const COOKIE_NAMES = {
  PRICE_GROUP_Id: 'priceGroupId',
  ZONES: 'zones',
  POLITICAL_ID: 'politicalId',
  CART_ID: 'cartId',
  AUTH_SID: 'authSid',
  SOURCE: 'source',
};

export const TENANTS = {
  CL: 'cl',
  CO: 'co',
  AR: 'ar',
  PE: 'pe',
  MX: 'mx',
  BR: 'br',
  UY: 'uy',
};

export const TENANT_CURRENCY_SYMBOL = {
  [TENANTS.CL]: '$',
  [TENANTS.CO]: '$',
  [TENANTS.AR]: '$',
  [TENANTS.PE]: 'S/',
  [TENANTS.MX]: '$',
  [TENANTS.BR]: 'R$',
  [TENANTS.UY]: '$',
};

export const STANDARD_FORMAT_TENANTS = ['mx', 'pe'];
export const NON_STANDARD_FORMAT_TENANTS = ['cl', 'co', 'ar', 'br', 'uy'];

export const tenantSpecificConfig = {
  [ENVIRONMENTS.DEVELOPMENT]: {
    FA: {
      [TENANTS.CL]: {
        cartUrl: 'https://staging.sodimac.cl/sodimac-cl/basket',
        adobeLaunch:
          'https://assets.adobedtm.com/68ade2efaac6/05d444b949be/launch-1bb58d6e7e5d-staging.min.js',
      },
    },
    SO: {},
  },
  [ENVIRONMENTS.STAGING]: {
    FA: {
      [TENANTS.CL]: {
        cartUrl: 'https://staging.sodimac.cl/sodimac-cl/basket',
        adobeLaunch:
          'https://assets.adobedtm.com/68ade2efaac6/05d444b949be/launch-1bb58d6e7e5d-staging.min.js',
      },
    },
    SO: {},
  },
  [ENVIRONMENTS.BETA]: {
    FA: {
      [TENANTS.CL]: {
        cartUrl: 'https://beta.sodimac.cl/sodimac-cl/basket',
        adobeLaunch:
          'https://assets.adobedtm.com/68ade2efaac6/05d444b949be/launch-1bb58d6e7e5d-staging.min.js',
      },
    },
    SO: {},
  },
  [ENVIRONMENTS.PRODUCTION]: {
    FA: {
      [TENANTS.CL]: {
        cartUrl: 'https://www.sodimac.cl/sodimac-cl/basket',
        adobeLaunch:
          'https://assets.adobedtm.com/68ade2efaac6/05d444b949be/launch-e4e4d4b7b172.min.js',
      },
    },
    SO: {},
  },
};

export const MOBILE_POSITION_OFFSET = [70, 230, 300, 70];

export const SOURCES = {
  MOBILE_APP: 'mobileapp',
  WEB: 'web',
};

export const INPUT_REGEX = /^\d{0,4}([.]\d{0,2})?$/;

export const DEFAULT_PRICE_GROUP = '96';
export const DEFAULT_POLITICAL_ID = '15c37b0b-a392-41a9-8b3b-978376c700d5';
