import styled from 'styled-components';
import { ColorWrapperProps } from '..';
import { mediaQuery } from '../../../../../utils';

export const FeaturedColorsIconWrapper = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0;
  grid-template-areas: '. . .';
  width: 32px;
  height: 22px;
  border-radius: 3px;
  overflow: hidden;

  ${mediaQuery.mobileOnly(`
    width: 30px;
    height: 18px;
    border-radius: 2px;
  `)}
`;

export const ColorWrapper = styled.div<ColorWrapperProps>`
  background-color: ${({ color }) => color};
`;
