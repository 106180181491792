import { defaultSheens } from '../../../__mocks__/defaults';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IColor } from '../../../models/IColor';
import { setSearchSeed, setTabOption } from '../../../redux/chooseColorSlice';
import {
  checkForAvailabilityAndFetchSkusAsync,
  fetchColorsAsync,
  getSelectedBrands,
  getSelectedColor,
  getSkusStatus,
  resetSelectedOptions,
  setSelectedColor,
  setSelectedColorIndex,
  setSelectedSheen,
  setVisibleSheenOptions,
} from '../../../redux/colorsSlice';
import { getTenant } from '../../../redux/configSlice';
import { getSelectedRoom } from '../../../redux/roomSlice';
import { parseSkus } from '../../../utils/commonUtils';
import { REPLACE } from '../../../utils/constants';

const useChooseColor = () => {
  const dispatch = useAppDispatch();
  const brands = useAppSelector(getSelectedBrands).join(',');
  const currentTenant = useAppSelector(getTenant);
  const selectedColor = useAppSelector(getSelectedColor);
  const skusStatus = useAppSelector(getSkusStatus);
  const selectedRoom = useAppSelector(getSelectedRoom);

  const handleClickSelectColorFamily = (colorFamilyId: string) => {
    dispatch(resetSelectedOptions());
    dispatch(setTabOption(colorFamilyId));
    dispatch(setSearchSeed(''));
    const props = {
      tenant: currentTenant,
      brand: brands,
      group: colorFamilyId,
      mode: REPLACE,
    };
    dispatch(fetchColorsAsync(props));
  };

  const handleClickSelectColorDesktop = (
    e: any,
    color: IColor,
    position: number
  ) => {
    e.stopPropagation();
    const parsedSkus = parseSkus(color);
    dispatch(setSelectedColor(color));
    dispatch(setSelectedSheen(defaultSheens));
    dispatch(
      checkForAvailabilityAndFetchSkusAsync({
        tenant: currentTenant,
        products: parsedSkus,
        isInterior: selectedRoom.application.id === '3',
      })
    );
    dispatch(setSelectedColorIndex(position));
  };

  const handleClickShowSearch = () => {
    dispatch(setTabOption('SEARCH'));
    dispatch(resetSelectedOptions());
  };

  const handleClickSelectColor = (e: any, color: IColor) => {
    e.stopPropagation();
    const parsedSkus = parseSkus(color);
    dispatch(setSelectedColor(color));
    dispatch(setSelectedSheen(defaultSheens));
    dispatch(
      checkForAvailabilityAndFetchSkusAsync({
        tenant: currentTenant,
        products: parsedSkus,
        isInterior: selectedRoom.application.id === '3',
      })
    );
    dispatch(setVisibleSheenOptions(true));
  };

  const onConfirmColorMobile = () => {
    const parsedSkus = parseSkus(selectedColor);
    dispatch(
      checkForAvailabilityAndFetchSkusAsync({
        tenant: currentTenant,
        products: parsedSkus,
        isInterior: selectedRoom.application.id === '3',
      })
    );
  };

  return {
    handleClickSelectColorDesktop,
    handleClickSelectColorFamily,
    handleClickShowSearch,
    handleClickSelectColor,
    onConfirmColorMobile,
    isSkusLoading: skusStatus === 'loading',
    regexFinderColor: /^$|^(\w|\d|\s|-)+$/,
  };
};

export default useChooseColor;
