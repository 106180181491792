import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { IPaintsConfig } from '../../models/IPaintsConfig';
import { defaultpaintsConfig } from './paintsConfig';
import { fetchConfig } from '../../api/fetchConfig';
import { IStatusAsyncThunk } from '../../models/IStatusAsyncThunk';

type StepperState = {
  tenant: string;
  host: string;
  os: string;
  paintsConfiguration: IPaintsConfig;
  status: IStatusAsyncThunk;
};

const initialState: StepperState = {
  tenant: '',
  host: '',
  os: '',
  paintsConfiguration: defaultpaintsConfig,
  status: 'idle',
};

export const fetchConfigAsync = createAsyncThunk(
  'config/fetchConfigAsync',
  async (tenant: string) => {
    const response = await fetchConfig(tenant);
    return response;
  }
);

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setTenant: (state, action: PayloadAction<StepperState['tenant']>) => {
      state.tenant = action.payload;
    },
    setHost: (state, action: PayloadAction<StepperState['host']>) => {
      state.host = action.payload;
    },
    setOs: (state, action: PayloadAction<StepperState['os']>) => {
      state.os = action.payload;
    },
    resetApp: () => {
      // eslint-disable-next-line no-console
      console.log('RESET APP...');
    },
  },
  extraReducers: (builder) => {
    // GET PAINTS CONFIG
    builder.addCase(fetchConfigAsync.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(fetchConfigAsync.fulfilled, (state, action: any) => {
      state.status = 'idle';
      state.paintsConfiguration = action.payload;
    });
    builder.addCase(fetchConfigAsync.rejected, (state) => {
      state.status = 'failed';
      state.paintsConfiguration = defaultpaintsConfig;
    });
  },
});

//Export actions
export const { setTenant, setHost, setOs, resetApp } = configSlice.actions;

//Definición de selectores
export const getTenant = (state: RootState) => state.config.tenant;
export const getHost = (state: RootState) => state.config.host;
export const getOs = (state: RootState) => state.config.os;
export const getConfigStatus = (state: RootState) => state.config.status;
export const getColorFamilyList = (state: RootState) =>
  state.config.paintsConfiguration.colorGroups;
export const getBrands = (state: RootState) =>
  state.config.paintsConfiguration.brands;
export const getSizes = (state: RootState) =>
  state.config.paintsConfiguration.sizes;
export const getProductDetails = (state: RootState) =>
  state.config.paintsConfiguration.productDetails;
export const getQualities = (state: RootState) =>
  state.config.paintsConfiguration.qualityTypes;
export const getColorGroups = (state: RootState) =>
  state.config.paintsConfiguration.colorGroups;
export const getCodeRegex = (state: RootState) =>
  state.config.paintsConfiguration.codeRegex;

export default configSlice.reducer;
