import styled from 'styled-components';

export const RoomPickerWrapper = styled.div`
  width: 160px;
  height: 98px;
  flex-shrink: 0;
  position: relative;
  padding: 0 10px;
`;

export const RoomImagenWrapper = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 3px;
`;

export const RoomNameWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 3px;
  color: #ffffff;
  font-size: 1.063rem;
  font-weight: bold;
  padding: 5px 20px;
`;

export const ForwardCarouselWrapper = styled.button`
  position: absolute;
  top: 26px;
  right: 0;
  background-color: transparent;
  border: 0;
  width: 52px;
  height: 52px;
  padding: 0;
  cursor: pointer;
  opacity: 0;
  transition: all 0.3s ease 0s;
`;
export const BackCarouselWrapper = styled.button`
  position: absolute;
  top: 26px;
  left: 0;
  background-color: transparent;
  border: 0;
  transform: rotate(180deg);
  width: 52px;
  height: 52px;
  padding: 0;
  cursor: pointer;
  opacity: 0;
  transition: all 0.3s ease 0s;
`;

export const RoomContainerWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;

  ${RoomPickerWrapper} {
    scroll-snap-align: start;
  }

  ${RoomPickerWrapper}:first-child {
    padding-left: 0;

    ${RoomNameWrapper} {
      left: 0;
    }
  }

  ${RoomPickerWrapper}:last-child {
    padding-right: 0;
  }
`;

export const RoomCarouselWrapper = styled.div`
  overflow: hidden;
  position: relative;

  &:hover ${ForwardCarouselWrapper}, &:hover ${BackCarouselWrapper} {
    opacity: 1;
  }
`;
