import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  getQualitesAndQuantitiesData,
  getTotalGallons,
  resetCalculatorQuantityState,
  setStep,
} from '../../../../redux/calculateQuantitySlice';
import { nextPosition } from '../../../../redux/stepperSlice';
import { PillButton } from '@digital-retail/journey-ui-kit';
import { COLORS } from '../../../../utils/constants/colors';
import { ReactComponent as LeftArrow } from '../../../../images/icons/left-arrow.svg';
import { ReactComponent as RightArrow } from '../../../../images/icons/right-arrow.svg';
import { MobileActionsBarWrapper } from './styles';
import { setfinalQualitiesAndQuantitiesData } from '../../../../redux/settingQualitySlice';

const MobileActionsBar = () => {
  const dispatch = useAppDispatch();
  const totalGallons = useAppSelector(getTotalGallons);
  const qAndQData = useAppSelector(getQualitesAndQuantitiesData);

  const handleClickShowMain = () => {
    dispatch(setStep('initial'));
    dispatch(resetCalculatorQuantityState());
  };

  const handleClickNextPosition = () => {
    dispatch(nextPosition());
    dispatch(setfinalQualitiesAndQuantitiesData(qAndQData));
  };

  return (
    <MobileActionsBarWrapper data-testid="mobile-actions-bar">
      <PillButton
        size="xs"
        variant="ghost"
        Icon={LeftArrow}
        iconStyle={{
          fill: COLORS.gray20,
        }}
        style={{
          color: COLORS.gray20,
        }}
        onClick={handleClickShowMain}
        data-testid="button-show-main"
      >
        Opciones para calcular
      </PillButton>
      <PillButton
        size="xs"
        variant="ghost"
        onClick={handleClickNextPosition}
        iconPosition="end"
        disabled={totalGallons === null || totalGallons <= 0}
        style={{
          color: totalGallons !== 0 ? COLORS.primary : COLORS.gray55,
        }}
        Icon={RightArrow}
        iconStyle={{
          fill: totalGallons !== 0 ? COLORS.primary : COLORS.gray55,
        }}
        data-testid="button-next-position"
      >
        Confirmar
      </PillButton>
    </MobileActionsBarWrapper>
  );
};

export default MobileActionsBar;
