import styled from 'styled-components';

export const ColorGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px;
  padding: 10px 0;
  grid-auto-rows: 1fr;
`;

export const ColorCard = styled.button<{ selected: boolean }>`
  border: 1px solid #dddddd;
  padding: 4px;
  display: grid;
  grid-auto-rows: max-content;

  & p {
    font-family: Lato;
    text-align: start;
    margin: 2px;
    line-height: 16px;
    font-size: 12px;
  }
`;

export const ColorWrapper = styled.div<{ background?: string }>`
  background-color: ${({ background = '#333' }) => background};
  height: 40px;
  border-radius: 3px;
  filter: contrast(90%) brightness(0.95);
`;
