import React from 'react';
import MobileColorPicker from '../MobileColorPicker';
import { ContainerMobileWrapper, SidebarWrapper } from './styles';
import MediaComponent from '../../../../components/MediaComponent';
import DesktopColorPicker from '../DesktopColorPicker';

const Sidebar: React.FC = () => {
  return (
    <SidebarWrapper>
      <MediaComponent from="tablet">
        <DesktopColorPicker />
      </MediaComponent>
      <MediaComponent from="mobile" to="tablet">
        <ContainerMobileWrapper>
          <MobileColorPicker />
        </ContainerMobileWrapper>
      </MediaComponent>
    </SidebarWrapper>
  );
};

export default Sidebar;
