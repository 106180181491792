import React from 'react';
import TitleMenu from '../TitleMenu';
import {
  DesktopColorPickerWrapper,
  HeaderWrapper,
  IconColorMenuHeaderWrapper,
  ContentWrapper,
  HeaderLeftWrapper,
  HeaderRightWrapper,
  FilterText,
  IconWrapper,
  FilterMenuContainer,
} from './styles';
import PantomIconTitle from '../../../../images/icons/pantonera.svg';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  getColorStatus,
  getOpenFilterMenu,
  setOpenFilterMenu,
} from '../../../../redux/colorsSlice';
import ListColorFamily from '../ListColorFamily';
import MenuColorDesktop from '../MenuColorDesktop';
import { getTabOption } from '../../../../redux/chooseColorSlice';
import { getColorFamilyList } from '../../../../redux/configSlice';
import { ReactComponent as FilterIcon } from '../../../../images/icons/filter.svg';
import FilterMenu from '../FilterMenu';

const DesktopColorPicker: React.FC = () => {
  const dispatch = useAppDispatch();
  const colorFamilyList = useAppSelector(getColorFamilyList);
  const colorStatus = useAppSelector(getColorStatus);
  const tabOption = useAppSelector(getTabOption);
  const openFilterMenu = useAppSelector(getOpenFilterMenu);

  const handleFilterClick = () => {
    dispatch(setOpenFilterMenu(!openFilterMenu));
  };

  return (
    <DesktopColorPickerWrapper data-testid="desktop-color-picker">
      <HeaderWrapper>
        <HeaderLeftWrapper>
          <IconColorMenuHeaderWrapper
            src={PantomIconTitle}
            alt="PantomTitle"
            style={
              colorStatus === 'loading'
                ? { filter: 'grayscale(1) brightness(2.5)' }
                : undefined
            }
          />
          <TitleMenu loading={colorStatus}>
            {tabOption === 'SEARCH'
              ? 'Buscador'
              : colorFamilyList.find((element) => element.id === tabOption)
                  ?.name || 'Colores'}
          </TitleMenu>
        </HeaderLeftWrapper>
        <HeaderRightWrapper onClick={handleFilterClick}>
          <FilterText>Filtro</FilterText>
          <IconWrapper>
            <FilterIcon />
          </IconWrapper>
        </HeaderRightWrapper>
      </HeaderWrapper>
      {openFilterMenu && (
        <FilterMenuContainer>
          <FilterMenu />
        </FilterMenuContainer>
      )}
      <ContentWrapper>
        {tabOption === null ? <ListColorFamily /> : <MenuColorDesktop />}
      </ContentWrapper>
    </DesktopColorPickerWrapper>
  );
};

export default DesktopColorPicker;
