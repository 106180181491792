import React from 'react';
import { IColor } from '../../models/IColor';
import {
  ColorCodeWrapper,
  ColorDescriptionWrapper,
  ColorIndicatorCircleWrapper,
  ColorIndicatorLineWrapper,
  ColorIndicatorWrapper,
  ColorNameWrapper,
} from './styles';
import { getBrandName } from '../../utils/commonUtils';

export type ColorDescriptionProps = {
  color: IColor;
  first: boolean;
  allBrands: any[];
};

export type ColorDescriptionOnlyRGBProps = {
  rgb: ColorDescriptionProps['color']['rgb'];
};

export type ColorDescriptionOnlyFirstProps = Pick<
  ColorDescriptionProps,
  'first'
>;

const ColorDescription: React.FC<ColorDescriptionProps> = ({
  color,
  first,
  allBrands,
}) => {
  return (
    <ColorDescriptionWrapper data-testid="color-description" first={first}>
      <ColorNameWrapper data-testid="color-name" rgb={color.rgb}>
        {color.name}
      </ColorNameWrapper>
      <ColorCodeWrapper data-testid="color-code" rgb={color.rgb}>
        Código {color.id} &nbsp; | &nbsp; Marca{' '}
        {getBrandName(color.brand, allBrands)}
      </ColorCodeWrapper>
      <ColorIndicatorWrapper>
        <ColorIndicatorLineWrapper rgb={color.rgb} />
        <ColorIndicatorCircleWrapper rgb={color.rgb} />
      </ColorIndicatorWrapper>
    </ColorDescriptionWrapper>
  );
};

export default ColorDescription;
