import React from 'react';
import { Button } from '@digital-retail/journey-ui-kit';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  getQualitesAndQuantitiesData,
  getTotalGallons,
} from '../../../../redux/calculateQuantitySlice';
import { nextPosition } from '../../../../redux/stepperSlice';
import { setfinalQualitiesAndQuantitiesData } from '../../../../redux/settingQualitySlice';

const ButtonGoToNextStep: React.FC = () => {
  const dispatch = useAppDispatch();
  const totalGallons = useAppSelector(getTotalGallons);
  const qAndQData = useAppSelector(getQualitesAndQuantitiesData);

  const handleClick = () => {
    dispatch(nextPosition());
    dispatch(setfinalQualitiesAndQuantitiesData(qAndQData));
  };

  return (
    <Button
      variant="notTransactional"
      size="md"
      pill={true}
      onClick={handleClick}
      disabled={totalGallons === null || totalGallons <= 0}
      data-testid="button-next-position"
    >
      Continuar
    </Button>
  );
};

export default ButtonGoToNextStep;
