const SQUARE_METERS_BY_DOORS_FACTOR = 1.4;
const SQUARE_METERS_BY_WINDOWS_FACTOR = 1;
const DECIMAL_ADJUSTMENT_FACTOR = 100;

type GetSquareMetersByRoomProps = {
  roomHeight: number;
  roomLong: number;
  numberOfRoomDoors: number;
  numberOfRoomWindows: number;
};

export const getSquareMetersByRoom = ({
  roomHeight,
  roomLong,
  numberOfRoomDoors,
  numberOfRoomWindows,
}: GetSquareMetersByRoomProps) => {
  if (roomHeight === 0 || roomLong === 0) {
    return null;
  }

  const result =
    roomHeight * roomLong -
    (numberOfRoomDoors * SQUARE_METERS_BY_DOORS_FACTOR +
      numberOfRoomWindows * SQUARE_METERS_BY_WINDOWS_FACTOR);

  if (Number.isNaN(result)) {
    return null;
  }

  return (
    Math.ceil(result * DECIMAL_ADJUSTMENT_FACTOR) / DECIMAL_ADJUSTMENT_FACTOR
  );
};
