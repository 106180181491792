import { IGallon } from '../../../../../models/IGallon';
import { IGallonsDistribution } from '../../../../../models/IGallonsDistribution';
import {
  LITERS_PER_GALLON,
  LITERS_PER_SQUARE_METER,
} from '../../../../../utils/constants/proportions';

export const squareMeterToGallon = (
  squareMeter: number,
  numberOfLayers: number
) => {
  if (isNaN(squareMeter) || isNaN(numberOfLayers)) {
    return 0;
  }

  const adjustmentFactor = 0.25;
  const surface = squareMeter * numberOfLayers;
  const liters = surface / LITERS_PER_SQUARE_METER;
  const gallons = liters / LITERS_PER_GALLON;
  const wholeNumbers = Math.floor(gallons);
  const decimals = (gallons - wholeNumbers) * 100;
  const adjustedDecimal =
    Math.ceil(decimals / (adjustmentFactor * 100)) * adjustmentFactor;
  const result = wholeNumbers + adjustedDecimal;

  return result;
};

export const sortGallonsBySize = () => {
  return (a: IGallon, b: IGallon) => {
    if (a.size > b.size) {
      return -1;
    } else if (a.size < b.size) {
      return 1;
    } else {
      return 0;
    }
  };
};

export const sortQualitiesById = () => {
  return (a: any, b: any) => {
    if (Number(a.quality) < Number(b.quality)) {
      return -1;
    } else if (Number(a.quality) > Number(b.quality)) {
      return 1;
    } else {
      return 0;
    }
  };
};

export const sortQuantitiesBySize = () => {
  return (a: any, b: any) => {
    if (Number(a.sizeId) < Number(b.sizeId)) {
      return -1;
    } else if (Number(a.sizeId) > Number(b.sizeId)) {
      return 1;
    } else {
      return 0;
    }
  };
};

export const addBucketDistributionByQuality = (
  skuData: any,
  numberOfGallons: number
) => {
  const skusDataWithDistribution = skuData.map((group) => {
    const { values } = group;
    const distribution = bucketDistribution(values, numberOfGallons);

    return {
      ...group,
      values: values.map((value: any) => ({
        ...value,
        distribution: distribution[value.sizeId],
      })),
    };
  });
  const sortedDataByQuality = skusDataWithDistribution.sort(
    sortQualitiesById()
  );
  const sortedQuantitiesData = sortedDataByQuality.map((group) => {
    const { values } = group;
    const sortedValues = values.sort(sortQuantitiesBySize());

    return {
      ...group,
      values: sortedValues,
    };
  });

  return sortedQuantitiesData;
};

export const bucketDistribution = (
  gallons: IGallon[] | any,
  numberOfGallons: number
) => {
  const distribution: IGallonsDistribution = {};
  const gallonsCopy = [...gallons].sort(sortGallonsBySize());
  let numberOfGallonsCopy = numberOfGallons;

  gallonsCopy.forEach((gallon) => {
    const size = parseFloat(gallon.size);
    const key = gallon.sizeId;
    if (numberOfGallonsCopy > 0) {
      const aux = Math.floor(numberOfGallonsCopy / size);
      distribution[key] = aux;
      numberOfGallonsCopy -= aux * size;
    } else {
      distribution[key] = 0;
    }
  });

  if (numberOfGallonsCopy > 0) {
    const smallestGalon = gallonsCopy[gallonsCopy.length - 1];
    const key = smallestGalon.sizeId;
    distribution[key] += 1;
  }

  return distribution;
};
