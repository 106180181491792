import {
  CardTitle,
  CardBody,
  CharacteristicsContainer,
  CharacteristicsHeader,
  CharacteristicsTitle,
  PaintIconWrapper,
  Wrapper,
  CharacteristicsList,
  CharacteristicsListItem,
  CheckIconWrapper,
  CharacteristicsListItemText,
  FooterButton,
  CardFooter,
  QuantitiesContainer,
  TotalQuantityContainer,
  HorizontalLine,
  QuantityLeft,
  QuantityRight,
  QuantityTitle,
  QuantityDescription,
  PriceContainer,
  InfoContainer,
  InfoText,
} from './styles';
import { ReactComponent as PaintIcon } from '../../images/icons/ico-0.5-gl.svg';
import { ReactComponent as CheckIcon } from '../../images/icons/check-green.svg';
import InputSpinner from '../InputSpinner';
import useAddToCart from '../../features/SettingQuality/hooks/useAddToCart';
import {
  getTotalPrice,
  getTotalSize,
} from '../../features/SettingQuality/utils';
import { useAppSelector } from '../../app/hooks';
import { getTenant } from '../../redux/configSlice';
import { ReactComponent as InfoIcon } from '../../images/icons/info.svg';
import { getStep, getTotalGallons } from '../../redux/calculateQuantitySlice';
import { useEffect, useState } from 'react';
import { getCurrentSource } from '../../utils/commonUtils';
import { SOURCES } from '../../utils/constants';

const QualitiesAndQuantitiesCard = ({
  qualityName,
  qualityId,
  characteristics,
  quantityData,
  handleQuantityChange,
}) => {
  const { handleAddToCart } = useAddToCart();
  const currentTenant = useAppSelector(getTenant);
  const totalSize = getTotalSize(quantityData);
  const totalPrice = getTotalPrice(quantityData, currentTenant);
  const actualTotalSize = useAppSelector(getTotalGallons);
  const isFromCalculator = useAppSelector(getStep) === 'calculateMeters';
  const [showInfoMessage, setShowInfoMessage] = useState(false);

  useEffect(() => {
    const showInfoMsg = totalSize !== actualTotalSize && isFromCalculator;
    setShowInfoMessage(showInfoMsg);
  }, []);

  const source = getCurrentSource();
  const isMobileApp = source === SOURCES.MOBILE_APP;

  return (
    <Wrapper isMobileApp={isMobileApp}>
      <CardTitle>
        Calidad <b>{qualityName}</b>
      </CardTitle>
      <CardBody>
        <CharacteristicsContainer>
          <CharacteristicsHeader>
            <PaintIconWrapper>
              <PaintIcon />
            </PaintIconWrapper>
            <CharacteristicsTitle>Características</CharacteristicsTitle>
          </CharacteristicsHeader>
          <CharacteristicsList>
            {characteristics.map((characteristic, index) => {
              return (
                <CharacteristicsListItem key={index}>
                  <CheckIconWrapper>
                    <CheckIcon />
                  </CheckIconWrapper>
                  <CharacteristicsListItemText>
                    {characteristic}
                  </CharacteristicsListItemText>
                </CharacteristicsListItem>
              );
            })}
          </CharacteristicsList>
        </CharacteristicsContainer>
        {showInfoMessage ? (
          <InfoContainer>
            <div>
              <InfoIcon />
            </div>
            <InfoText>
              Debido a la disponibilidad actual, este es el formato que más se
              ajusta a la cantidad que necesitas.
            </InfoText>
          </InfoContainer>
        ) : null}
        <QuantitiesContainer>
          {quantityData.map((data) => {
            const { id, description, specifications } = data;

            return (
              <TotalQuantityContainer key={id}>
                <QuantityLeft>
                  <QuantityTitle>{description}</QuantityTitle>
                  <QuantityDescription>{specifications[0]}</QuantityDescription>
                  <QuantityDescription>{specifications[1]}</QuantityDescription>
                </QuantityLeft>
                <QuantityRight>
                  <InputSpinner
                    defaultValue={data.distribution}
                    min={0}
                    onChange={(value) =>
                      handleQuantityChange(value, id, qualityId)
                    }
                  />
                </QuantityRight>
              </TotalQuantityContainer>
            );
          })}
          <HorizontalLine />
        </QuantitiesContainer>
        <TotalQuantityContainer>
          <div>Cantidad</div>
          <div>{totalSize} galones</div>
        </TotalQuantityContainer>
        <PriceContainer>
          <div>Total</div>
          <div>{totalPrice}</div>
        </PriceContainer>
      </CardBody>
      <CardFooter>
        <FooterButton
          onClick={() => handleAddToCart(qualityId)}
          disabled={totalSize <= 0}
        >
          Agregar al carro
        </FooterButton>
      </CardFooter>
    </Wrapper>
  );
};

export default QualitiesAndQuantitiesCard;
