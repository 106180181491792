export const getPaintServiceUrl = (tenant: string) => {
  const { REACT_APP_SO_PAINT_SERVICE_URL, REACT_APP_FA_PAINT_SERVICE_URL } =
    process.env;
  const upperCaseTenant = tenant.toUpperCase();
  if (upperCaseTenant.startsWith('SO')) {
    return REACT_APP_SO_PAINT_SERVICE_URL;
  }
  if (upperCaseTenant.startsWith('FA')) {
    return REACT_APP_FA_PAINT_SERVICE_URL;
  }
};
