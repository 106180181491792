import styled from 'styled-components';

export const BackButtonWrapper = styled.div`
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 10px;
  margin-left: 10px;
  background-color: transparent;
  z-index: 1;

  button {
    background-color: transparent !important;
    border: none !important;
  }
`;
