import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { getQualities } from '../../../redux/configSlice';
import {
  getfinalQualitiesAndQuantitiesData,
  setfinalQualitiesAndQuantitiesData,
} from '../../../redux/settingQualitySlice';
import { previousPosition } from '../../../redux/stepperSlice';

const useQualitiesAndQuantities = () => {
  const dispatch = useAppDispatch();
  const qualities = useAppSelector(getQualities);
  const qualitiesAndQuantitiesData = useAppSelector(
    getfinalQualitiesAndQuantitiesData
  ) as any;

  const handleQuantityChange = (value: number, id: string, quality: string) => {
    const updatedQAndQData = qualitiesAndQuantitiesData.map((qAndQData) => {
      if (qAndQData.quality === quality) {
        return {
          ...qAndQData,
          values: qAndQData.values.map((q) => {
            if (q.id === id) {
              return {
                ...q,
                distribution: value,
              };
            }
            return q;
          }),
        };
      }
      return qAndQData;
    });

    dispatch(setfinalQualitiesAndQuantitiesData(updatedQAndQData));
  };

  const handleClickPreviousPosition = () => {
    dispatch(previousPosition());
  };

  return {
    qualities,
    qualitiesAndQuantitiesData,
    handleQuantityChange,
    handleClickPreviousPosition,
  };
};

export default useQualitiesAndQuantities;
