import React from 'react';
import { useAppSelector } from '../../app/hooks';
import MediaComponent from '../../components/MediaComponent';
import RoomView from '../../components/RoomView';
import { getSelectedColor } from '../../redux/colorsSlice';
import HeaderColor from '../../components/HeaderColor';
import RoomImage from '../../components/RoomImage';
import { CalculateQuantityWrapper } from './styles';
import rgbToHex from '../../utils/rgbToHex';

const CalculateQuantity: React.FC = () => {
  const selectedColor = useAppSelector(getSelectedColor);

  return (
    <CalculateQuantityWrapper data-testid="calculate-quantity">
      <RoomView backgroundColor={rgbToHex(selectedColor.rgb)}>
        <MediaComponent from="tablet">
          <HeaderColor />
        </MediaComponent>
        <RoomImage />
      </RoomView>
    </CalculateQuantityWrapper>
  );
};

export default CalculateQuantity;
