import styled from 'styled-components';
import { mediaQuery } from '../../../../../utils';

export const ModalAddToCartWrapper = styled.div``;

export const ModalTitleWrapper = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const IconWrapper = styled.div`
  margin-right: 15px;
  height: 32px;
`;

export const ButtonGoToShoppingCartWrapper = styled.div`
  & > * {
    ${mediaQuery.mobileOnly(`
      width: 100%;
    `)}
  }
`;

export const ButtonSeeMoreProductsWrapper = styled.div`
  & > * {
    ${mediaQuery.mobileOnly(`
      width: 100%;
    `)}
  }
`;

export const RecommendationsWrapper = styled.div`
  margin: 10px 0;
  display: flex;

  flex-direction: row;
  flex-wrap: nowrap;

  align-items: center;
  gap: 10px;

  margin-bottom: 20px;

  & > * {
    scroll-snap-align: start;

    ${mediaQuery.mobileOnly(`
      scroll-snap-align: center;
    `)}
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  ${mediaQuery.mobileOnly(`
    flex-direction: column-reverse;
    justify-content: flex-start;
  `)}
`;
