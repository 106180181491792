import React from 'react';
import { RoomViewWrapper } from './styles';

export type RoomViewProps = {
  backgroundColor: string;
};

const RoomView: React.FC<RoomViewProps> = ({ children, ...otherProps }) => {
  return (
    <RoomViewWrapper {...otherProps} data-testid="room-view">
      {children}
    </RoomViewWrapper>
  );
};

export default RoomView;
