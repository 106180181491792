import styled from 'styled-components';
import { mediaQuery } from '../../../../../utils/';

export const CalculationOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: unset;
  align-content: flex-start;
  padding: 10px;
  margin: -5px;

  & > * {
    width: auto;
    margin: 5px;
  }

  ${mediaQuery.tabletToUp(`
    padding: 0px;
    margin: -10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: unset;
    align-content: flex-start;
    
    & > * {
      width: 230px;
      margin: 10px;
    }
  `)}
`;

export const OptionWrapper = styled.div`
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  height: auto;
  cursor: pointer;

  padding: 20px;

  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr;
  grid-template-rows: max-content max-content;
  gap: 0 0;

  grid-template-areas:
    'icon'
    'description';
  align-items: center;

  ${mediaQuery.mobileOnly(`
    padding: 5px;
    height: 46px;
    grid-auto-flow: row;
    grid-template-columns: 56px 1fr 45px;
    grid-template-rows: 1fr;
    grid-template-areas: 'icon description arrow';
  `)}
`;

export const IconWrapper = styled.div`
  grid-area: icon;
  display: flex;
  align-items: center;
  justify-content: space-around;

  svg {
    height: 42px;
  }

  ${mediaQuery.tabletToUp(`
    height: 44px;
    margin-bottom: 8px;
  `)}
`;

export const DescriptionWrapper = styled.div`
  grid-area: description;
  font-size: 14px;

  ${mediaQuery.tabletToUp(`
    text-align: center;

    strong {
      display: block;
    }
  `)}
`;

export const ArrowWrapper = styled.div`
  grid-area: arrow;
  text-align: center;

  svg {
    width: 15px;
    fill: #979797;
  }

  ${mediaQuery.tabletToUp(`
    display: none;
  `)}
`;
