import { memo, FC } from 'react';
import { IColor } from '../../../../models/IColor';
import './style/index.css';
import rgbToHex from '../../../../utils/rgbToHex';
import { getBrandName, isDarkBackground } from '../../../../utils/commonUtils';
import { useAppSelector } from '../../../../app/hooks';
import { getBrands } from '../../../../redux/configSlice';

export type RenderGridDesktopProps = {
  colors: IColor[];
  selectedColor: IColor[] | any;
  onSelectColor: (e: any, color: IColor, colorIndex: number) => void; //eslint-disable-line no-unused-vars
};

const RenderGridDesktop: FC<RenderGridDesktopProps> = ({
  colors,
  selectedColor,
  onSelectColor,
}: RenderGridDesktopProps) => {
  const availableBrands = useAppSelector(getBrands);

  return (
    <>
      {colors.map((color, colorIndex: number) => (
        <button
          key={colorIndex}
          className={`color ${
            selectedColor.id == color.id && 'color-selected'
          }`}
          style={{ backgroundColor: rgbToHex(color.rgb) }}
          onClick={(e) => onSelectColor(e, color, colorIndex)}
        >
          <span className={`code ${isDarkBackground(color.rgb) && 'dark'}`}>
            <span>{color.id}</span>
            <span>{getBrandName(color.brand, availableBrands)}</span>
          </span>
        </button>
      ))}
    </>
  );
};

export default memo(RenderGridDesktop);
