import styled from 'styled-components';
import { ArrowWrapperProps, RoomOverlayWrapperProps } from '..';
import { COLORS } from '../../../../../utils/constants/colors';

export const RoomSelectorWrapper = styled.div`
  &.disabled {
    filter: grayscale(100%);
  }
`;

export const RoomOverlayWrapper = styled.div<RoomOverlayWrapperProps>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  cursor: ${({ isOpen }) => (isOpen ? 'pointer' : 'default')};
  transition: all 0.2s ease 0s;
  opacity: ${({ isOpen }) => Number(isOpen)};
`;

export const MessageWrapper = styled.div`
  text-align: center;
  color: ${COLORS.primary};
  font-weight: bold;
`;

export const SwitchWrapper = styled.div`
  width: 90px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  filter: url(#round);
  z-index: 100;

  &:before {
    content: '';
    display: block;
    padding-top: 50px;
    background-color: #ffffff;
    -webkit-clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 0% 50%);
    clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 0% 50%);
  }
`;

export const RoomSelectorContentWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: calc(100% - 20px);
  background-color: #ffffff;
  padding: 10px;
  border-radius: 3px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 30%);
  cursor: pointer;
`;

export const ArrowWrapper = styled.div<ArrowWrapperProps>`
  position: absolute;
  left: 35px;
  top: 2px;
  transform: rotate(${({ isOpen }) => (isOpen ? 180 : 0)}deg);
`;
