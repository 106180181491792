import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  fetchColors,
  fetchAvailableSkus,
  fetchSkusData,
} from '../../api/colorService';
import { RootState } from '../../app/store';
import { IColorFamily } from '../../models/IColorFamily';
import { IColor } from '../../models/IColor';
import { ISheen } from '../../models/ISheens';
import ListCoats from '../../__mocks__/coats';
import { IStatusAsyncThunk } from '../../models/IStatusAsyncThunk';
import { IQuality } from '../../models/IQuality';
import { IColorRequest } from '../../models/IColorsRequest';
import { APPEND, REPLACE } from '../../utils/constants';
import { IAvailabilityRequest } from '../../models/IAvailabilityRequest';
import { getSkuIds, mergeTwoArrays } from '../../utils/commonUtils';
import { defaultSelectedColor, defaultSheens } from '../../__mocks__/defaults';

type ColorsReducer = {
  status: IStatusAsyncThunk;
  colorFamilyList: IColorFamily[];
  selectedColors: IColor[]; // TODO: Remove
  selectedColor: IColor;
  colorList: IColor[];
  selectedColorIndex: number;
  visibleCoatMenu: boolean;
  selectedSheen: ISheen;
  enableChangeSheen: boolean; // TODO: Remove
  confirmedColor: boolean;
  sheens: ISheen[];
  qualities: IQuality[]; // TODO: Remove
  brandId: string; // TODO: Remove
  selectedBrands: string[];
  nextColorId: string;
  nextColorName: string;
  openFilterMenu: boolean;
  availableSkusData: any[];
  skusStatus: IStatusAsyncThunk;
  finalSkusData: any[];
  noColorsFound: boolean;
};

export const fetchColorsAsync = createAsyncThunk(
  'colors/fetchColorsAsync',
  async (props: IColorRequest) => {
    const mode = props.mode || REPLACE;
    const response = (await fetchColors(props)) as any;
    return {
      colors: response.results,
      nextColorId: response.nextColorId,
      nextColorName: response.nextColorName,
      mode,
    };
  }
);

export const checkForAvailabilityAndFetchSkusAsync = createAsyncThunk(
  'colors/checkForAvailabilityAndFetchSkusAsync',
  async (props: IAvailabilityRequest) => {
    const availabilityResponse = await fetchAvailableSkus(props);
    const isSuccess = availabilityResponse.success;
    let skusData = [] as any;
    if (isSuccess && availabilityResponse.products.length > 0) {
      const products = availabilityResponse.products.map(
        (productData: any) => ({ ...productData.product })
      );
      const skuIds = getSkuIds(products).join(',');
      const skuProps = {
        tenant: props.tenant,
        skus: skuIds,
      };
      const skusResponse = await fetchSkusData(skuProps);
      skusData = mergeTwoArrays(products, skusResponse);
      const { isInterior } = props;
      if (isInterior) {
        skusData = skusData.filter((item: any) => item.indoor === '1');
      } else {
        skusData = skusData.filter((item: any) => item.outdoor === '1');
      }
    }

    return skusData;
  }
);

const initialState: ColorsReducer = {
  status: 'idle',
  colorFamilyList: [],
  selectedColors: [],
  selectedColor: defaultSelectedColor,
  colorList: [],
  selectedColorIndex: -1,
  visibleCoatMenu: false,
  selectedSheen: defaultSheens,
  enableChangeSheen: false,
  confirmedColor: false,
  sheens: ListCoats,
  qualities: [],
  brandId: '7',
  selectedBrands: ['1', '2', '3'], // TODO
  nextColorId: '',
  nextColorName: '',
  openFilterMenu: false,
  availableSkusData: [],
  skusStatus: 'idle',
  finalSkusData: [],
  noColorsFound: false,
};

export const colorsSlice = createSlice({
  name: 'colors',
  initialState,
  reducers: {
    addSelectedColor: (state, action: PayloadAction<IColor>) => {
      state.selectedColors = [...state.selectedColors, action.payload];
      state.visibleCoatMenu = true;
      state.confirmedColor = false;
    },
    setSelectedColorIndex: (
      state,
      action: PayloadAction<ColorsReducer['selectedColorIndex']>
    ) => {
      state.selectedColorIndex = action.payload;
    },
    setVisibleSheenOptions: (
      state,
      action: PayloadAction<ColorsReducer['visibleCoatMenu']>
    ) => {
      state.visibleCoatMenu = action.payload;
    },
    setSelectedSheen: (
      state,
      action: PayloadAction<ColorsReducer['selectedSheen']>
    ) => {
      state.selectedSheen = action.payload;
    },
    setEnableChangeSheen: (
      state,
      action: PayloadAction<ColorsReducer['enableChangeSheen']>
    ) => {
      state.enableChangeSheen = action.payload;
    },
    setConfirmedColor: (
      state,
      action: PayloadAction<ColorsReducer['confirmedColor']>
    ) => {
      state.confirmedColor = action.payload;
    },
    resetSelectedOptions: (state) => {
      state.selectedColor = defaultSelectedColor;
      state.colorList = [];
      state.selectedColorIndex = -1;
      state.visibleCoatMenu = false;
      // eslint-disable-next-line prefer-destructuring
      state.selectedSheen = defaultSheens;
      state.confirmedColor = false;
      state.nextColorId = '';
      state.nextColorName = '';
      state.availableSkusData = [];
      state.finalSkusData = [];
      state.status = 'idle';
    },
    setOpenFilterMenu: (state, action) => {
      state.openFilterMenu = action.payload;
    },
    setSelectedBrands: (state, action) => {
      state.selectedBrands = action.payload;
    },
    setSelectedColor: (state, action) => {
      state.selectedColor = action.payload;
    },
    setAvailableSkuData: (state, action) => {
      state.availableSkusData = action.payload;
    },
    setFinalSkusData: (state, action) => {
      state.finalSkusData = action.payload;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    //COLORS
    builder
      .addCase(fetchColorsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchColorsAsync.fulfilled, (state, action: any) => {
        state.status = 'success';
        const { mode, colors, nextColorId, nextColorName } = action.payload;
        if (mode === REPLACE) {
          state.colorList = [...colors];
          state.noColorsFound = !(colors && colors.length > 0);
        }
        if (mode === APPEND) {
          state.colorList = [...state.colorList, ...colors];
        }
        state.nextColorId = nextColorId || '';
        state.nextColorName = nextColorName || '';
      })
      .addCase(fetchColorsAsync.rejected, (state) => {
        state.status = 'failed';
        state.colorList = [];
        state.nextColorId = '';
        state.nextColorName = '';
        state.noColorsFound = true;
      });

    //SKUS
    builder
      .addCase(checkForAvailabilityAndFetchSkusAsync.pending, (state) => {
        state.skusStatus = 'loading';
        state.visibleCoatMenu = false;
      })
      .addCase(
        checkForAvailabilityAndFetchSkusAsync.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.skusStatus = 'idle';
          state.availableSkusData = action.payload;
          state.visibleCoatMenu = true;
          state.confirmedColor = true;
        }
      )
      .addCase(checkForAvailabilityAndFetchSkusAsync.rejected, (state) => {
        state.skusStatus = 'failed';
        state.availableSkusData = [];
      });
  },
});
//Export actions
export const {
  addSelectedColor,
  setSelectedColorIndex,

  setVisibleSheenOptions,
  setSelectedSheen,
  setEnableChangeSheen,
  setConfirmedColor,
  setOpenFilterMenu,
  setSelectedBrands,
  setSelectedColor,
  setAvailableSkuData,
  setFinalSkusData,
  resetSelectedOptions,
  reset,
} = colorsSlice.actions;

//Definición de selectores
export const getColorStatus = (state: RootState) => state.colors.status;
export const getSelectedColorIndex = (state: RootState) =>
  state.colors.selectedColorIndex;
export const getColorFamilyList = (state: RootState) =>
  state.colors.colorFamilyList;
export const getSelectedColors = (state: RootState) =>
  state.colors.selectedColors;

export const getVisibleSheenOptions = (state: RootState) =>
  state.colors.visibleCoatMenu;
export const getSelectedSheen = (state: RootState) =>
  state.colors.selectedSheen;
export const getEnableChangeSheen = (state: RootState) =>
  state.colors.enableChangeSheen;
export const getSheens = (state: RootState) => state.colors.sheens;
export const getConfirmedColor = (state: RootState) =>
  state.colors.confirmedColor;
export const getSheenList = (state: RootState) => state.colors.sheens;
export const getQualityBase = (state: RootState) => state.colors.qualities;
export const getBrandId = (state: RootState) => state.colors.brandId;

export const getColorList = (state: RootState) => state.colors.colorList;
export const getSelectedBrands = (state: RootState) =>
  state.colors.selectedBrands;
export const getColorRequestData = (state: RootState) => {
  return {
    brand: state.colors.selectedBrands.join(','),
    nextColorId: state.colors.nextColorId,
    nextColorName: state.colors.nextColorName,
  };
};
export const getOpenFilterMenu = (state: RootState) =>
  state.colors.openFilterMenu;
export const getSelectedColor = (state: RootState) =>
  state.colors.selectedColor;
export const getSkusStatus = (state: RootState) => state.colors.skusStatus;
export const getAvailableSkusData = (state: RootState) =>
  state.colors.availableSkusData;
export const getFinalSkusData = (state: RootState) =>
  state.colors.finalSkusData;

export const getNoColorsFound = (state: RootState) =>
  state.colors.noColorsFound;

export default colorsSlice.reducer;
