import React, { useEffect } from 'react';
import {
  ArrowWrapper,
  CalculationOptionsWrapper,
  DescriptionWrapper,
  IconWrapper,
  OptionWrapper,
} from './styles';
import { ReactComponent as IconPorM2 } from '../../../../images/icons/ico-por-m2.svg';
import { ReactComponent as IconPorGalon } from '../../../../images/icons/ico-por-galon.svg';
import { ReactComponent as RightArrow } from '../../../../images/icons/right-arrow.svg';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  resetEstimation,
  setQualitesAndQuantitiesData,
  setStep,
} from '../../../../redux/calculateQuantitySlice';
import { fetchGallonsAsync, getGallons } from '../../../../redux/gallonsSlice';
import { nextPosition } from '../../../../redux/stepperSlice';
import useCalculateMetersForm from '../../hooks/useCalculateMetersForm';
import { setfinalQualitiesAndQuantitiesData } from '../../../../redux/settingQualitySlice';

const CalculationOptions: React.FC = () => {
  const dispatch = useAppDispatch();
  const gallons = useAppSelector(getGallons);
  const { finalSkusWithSizes } = useCalculateMetersForm();

  const handleClickShowCalculateMetersForm = () => {
    dispatch(resetEstimation());
    dispatch(setStep('calculateMeters'));
  };

  const handleClickShowQuantityGallonsForm = () => {
    dispatch(setQualitesAndQuantitiesData(finalSkusWithSizes));
    dispatch(setfinalQualitiesAndQuantitiesData(finalSkusWithSizes));
    dispatch(nextPosition());
  };

  useEffect(() => {
    if (gallons.length === 0) {
      dispatch(fetchGallonsAsync());
    }
  }, []);

  return (
    <CalculationOptionsWrapper data-testid="calculation-options">
      <OptionWrapper
        onClick={handleClickShowCalculateMetersForm}
        data-testid="option-show-calculate-meters-form"
      >
        <IconWrapper>
          <IconPorM2 />
        </IconWrapper>
        <DescriptionWrapper>
          <strong>
            Ingresa los m<sup>2</sup>
          </strong>{' '}
          que necesitas pintar
        </DescriptionWrapper>
        <ArrowWrapper>
          <RightArrow />
        </ArrowWrapper>
      </OptionWrapper>
      <OptionWrapper
        onClick={handleClickShowQuantityGallonsForm}
        data-testid="option-show-quantity-gallons-form"
      >
        <IconWrapper>
          <IconPorGalon />
        </IconWrapper>
        <DescriptionWrapper>
          <strong>Selecciona la cantidad</strong> de galones que necesitas
        </DescriptionWrapper>
        <ArrowWrapper>
          <RightArrow />
        </ArrowWrapper>
      </OptionWrapper>
    </CalculationOptionsWrapper>
  );
};

export default CalculationOptions;
