import React from 'react';
import {
  CheckboxContainer,
  CheckboxLabel,
  StyledInputCheckbox,
} from './styles';

interface Props {
  label: string;
  checked: boolean;
  // eslint-disable-next-line no-unused-vars
  onChange: (event: any) => void;
  value?: string;
}
const StyledCheckbox: React.FC<Props> = ({
  label,
  checked,
  value,
  onChange,
}) => {
  return (
    <CheckboxContainer>
      <StyledInputCheckbox
        id={value}
        checked={checked}
        onChange={onChange}
        value={value}
      />
      <CheckboxLabel htmlFor={value}>{label}</CheckboxLabel>
    </CheckboxContainer>
  );
};

export default StyledCheckbox;
