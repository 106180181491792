/* istanbul ignore file */

import { IItemToCart } from '../../models/IItemToCart';
import { setCartIdCookie } from '../../utils/commonUtils';
import {
  DEFAULT_POLITICAL_ID,
  DEFAULT_PRICE_GROUP,
} from '../../utils/constants';
import { customFetch } from '../../utils/customFetch';

const url = process.env.REACT_APP_CHECKOUT_FALABELLA || 'http://localhost:3000';

type mapCatalystCartItemsProps = {
  cartLineNumber: string;
  variantId: string;
  name: string;
  brandName: string;
  quantity: any;
};
const mapCatalystCartItems = ({
  cartLineNumber,
  variantId,
  name,
  brandName,
  quantity,
}: mapCatalystCartItemsProps) => {
  return {
    product: {
      id: cartLineNumber,
      sku: {
        id: variantId,
      },
      displayName: name,
      brand: brandName,
    },
    totalQuantity: quantity.quantityNumber,
  };
};

type addItemToBasketApiProps = {
  cartLines: IItemToCart[];
  authSid: string;
  cartId?: string;
  politicalId: string;
  priceGroup: string;
};
export const addItemToBasketApi = async ({
  cartLines,
  authSid,
  cartId,
  politicalId,
  priceGroup,
}: addItemToBasketApiProps): Promise<any> => {
  const query = cartId && cartId.length > 0 ? `?cartId=${cartId}` : '';

  const headers: any = {
    'content-type': 'application/json',
    'X-Channel-Id': 'WEB',
    'X-Commerce': 'FCM',
    'X-Ecomm-Name': 'marketplacesodimac-cl',
    'X-Origin-Name': 'PDP',
    'X-Origin-View': 'Product',
    'X-Site-Name': 'sodimac',
  };

  if (authSid) {
    headers.sid = authSid;
  }

  try {
    const cartUrl = `${url}/v1/carts${query}`;
    const response: any = await customFetch({
      url: cartUrl,
      params: {
        method: 'POST',
        headers,
        body: JSON.stringify({
          data: {
            cart: {
              cartLines,
            },
          },
          metadata: {
            priceGroup: priceGroup || DEFAULT_PRICE_GROUP,
            politicalAreaId: politicalId || DEFAULT_POLITICAL_ID,
          },
        }),
      },
    });

    let addToCartResponse: any = {
      success: false,
    };

    if (response.data) {
      const cartLinesResponse = response.data?.cart?.cartLines;
      const cartItems = cartLinesResponse.map(mapCatalystCartItems);
      const cartQuantity = response.data?.cart?.totalProductsQuantityCount;
      const responseCartId = response.data?.cart?.cartId;
      setCartIdCookie(responseCartId);
      if (responseCartId) {
        addToCartResponse = {
          data: {
            cartQuantity,
            cartItems,
            lastAddedQuantity: cartQuantity,
            cartId: responseCartId,
          },
          success: true,
        };
      }
    }
    return addToCartResponse;
  } catch (err) {
    return {
      success: false,
    };
  }
};
