import styled from 'styled-components';
import { RoomViewProps } from '..';
import { mediaQuery } from '../../../../../utils';

export const RoomViewContainer = styled.div`
  flex: 1;
  position: relative;
  transition: all 0.5s;
  z-index: 2;

  ${mediaQuery.tabletToUp(`
    height: 520px;
  `)}
`;

export const OverlayColor = styled.div<RoomViewProps>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  position: absolute;
  width: 100%;
  height: 100%;
  filter: contrast(90%) brightness(0.95);
  ${mediaQuery.tabletToUp(`
    height: 520px;
    width: calc(100% - 300px);
  `)}
`;
