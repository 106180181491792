import styled from 'styled-components';
import { RoomViewProps } from '..';
import { mediaQuery } from '../../../utils';

export const RoomViewWrapper = styled.div<RoomViewProps>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  flex: 1;
  position: relative;
  transition: all 0.5s;

  ${mediaQuery.tabletToUp(`
    height: 520px;
  `)}
`;
