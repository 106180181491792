import styled, { keyframes } from 'styled-components';
import {
  ColorDescriptionOnlyRGBProps,
  ColorDescriptionOnlyFirstProps,
} from '..';
import { mediaQuery } from '../../../utils';
import { isDarkBackground } from '../../../utils/commonUtils';

const getCodeIn = keyframes`
  0% {
    opacity: 0;
    transform: translate(-30px);
    z-index: 5;
  }
  100% {
    opacity: 1;
    transform: translate(0);
    z-index: 5;
  }
`;

const getCodeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export const ColorDescriptionWrapper = styled.div<ColorDescriptionOnlyFirstProps>`
  position: absolute;
  left: 32px;
  top: 32px;

  animation-duration: 0.5s;
  animation-name: ${({ first }) => (first ? getCodeIn : getCodeOut)};
  opacity: ${({ first }) => (first ? 1 : 0)};

  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: max-content 100px;
  grid-template-rows: max-content max-content;
  gap: 0;
  grid-template-areas:
    'colorName colorIndicator'
    'colorCode .';
  width: max-content;

  ${mediaQuery.tabletToDesktop(`
    left: 20px;
    top: 30px;
  `)}

  ${mediaQuery.mobileOnly(`
    left: 20px;
    top: 30px;
    grid-template-columns: 1fr;
    grid-template-rows: max-content max-content  100px;
    grid-template-areas:
      "colorName"
      "colorCode"
      "colorIndicator";
  `)}
`;

export const ColorNameWrapper = styled.div<ColorDescriptionOnlyRGBProps>`
  grid-area: colorName;
  font-size: 35px;
  line-height: 45px;
  font-weight: bold;
  color: ${({ rgb }) => (!isDarkBackground(rgb) ? '#FFFFFF' : '#333333')};
  text-shadow: 0 2px 4px
    ${({ rgb }) =>
      !isDarkBackground(rgb)
        ? 'rgba(0, 0, 0, 0.3)'
        : 'rgba(255, 255, 255, 0.3)'};
  padding-right: 10px;

  ${mediaQuery.tabletOnly(`
    font-size: 38px;
  `)}
  ${mediaQuery.mobileOnly(`
    font-size: 16px;
    line-height: 18px;
  `)}


  ::first-letter {
    text-transform: uppercase;
  }
`;

export const ColorCodeWrapper = styled.div<ColorDescriptionOnlyRGBProps>`
  grid-area: colorCode;
  font-size: 14px;
  line-height: 17px;
  grid-column-start: 1;
  grid-column-end: 3;
  color: ${({ rgb }) => (!isDarkBackground(rgb) ? '#FFFFFF' : '#333333')};
  text-shadow: 0 2px 4px
    ${({ rgb }) =>
      !isDarkBackground(rgb)
        ? 'rgba(0, 0, 0, 0.3)'
        : 'rgba(255, 255, 255, 0.3)'};
`;

export const ColorIndicatorWrapper = styled.div`
  align-content: center;
  display: grid;
  grid-template-columns: 1fr 32px;
  grid-template-rows: max-content;
  gap: 10px;
  grid-template-areas: 'colorIndicatorLine colorIndicatorCircle';
  grid-area: colorIndicator;

  ${mediaQuery.mobileOnly(`
    display: none;
  `)}
`;

export const ColorIndicatorLineWrapper = styled.div<ColorDescriptionOnlyRGBProps>`
  height: calc((20px + 2px) / 2);
  width: auto;
  grid-area: colorIndicatorLine;
  box-sizing: border-box;
  border-width: 0;
  border-color: ${({ rgb }) =>
    !isDarkBackground(rgb)
      ? 'rgba(255, 255, 255, 0.7)'
      : 'rgba(89, 89, 89, 0.7)'};
  border-style: dashed;
  border-bottom-width: 2px;
  border-right-width: 0;
`;

export const ColorIndicatorCircleWrapper = styled.div<ColorDescriptionOnlyRGBProps>`
  grid-area: colorIndicatorCircle;
  border-radius: 50%;
  background-color: ${({ rgb }) => rgb};
  height: 20px;
  width: 20px;
  box-shadow: 0 0 0 5px
    ${({ rgb }) =>
      !isDarkBackground(rgb)
        ? 'rgba(255, 255, 255, 0.7)'
        : 'rgba(89, 89, 89, 0.7)'};
`;
