import styled from 'styled-components';
import { mediaQuery } from '../../../utils';

export const ChooseRoomWrapper = styled.div``;

export const MessageWrapper = styled.div`
  padding: 10px 0 40px;
  color: #333333;
  font-family: Lato, sans-serif;
  line-height: 45px;
  font-size: 2.188rem;
  text-align: center;

  ${mediaQuery.mobileOnly(`
        display: none;
      `)}
`;

export const GridRoomWrapper = styled.div`
  display: grid;
  gap: 15px;
  grid-auto-flow: row;

  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-template-areas:
    'dormitorio baño'
    'dormitorio living'
    'fachada fachada'
    'comedor cocina';
  height: 700px;

  ${mediaQuery.tabletToDesktop(`
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 0.5fr 0.5fr 1fr;
      grid-template-areas:
        'cocina comedor dormitorio'
        'cocina baño dormitorio'
        'cocina baño living'
        'fachada fachada living';
    `)}

  ${mediaQuery.desktopToUp(`
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-template-areas:
        'comedor cocina dormitorio baño'
        'comedor living fachada fachada';
      height: 520px;
    `)}
    
  ${mediaQuery.mobileOnly(`
    gap: 10px;
  `)}
`;
