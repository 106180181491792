import {
  FilterContainer,
  Triangle,
  FilterMenuWrapper,
  FilterHeader,
  FilterTitle,
  FilterClose,
  FilterBody,
  FilterFooter,
  FilterFooterButton,
} from './styles';
import { ReactComponent as CloseIcon } from '../../../../../images/icons/close.svg';
import StyledCheckbox from '../../CheckBox';
import useFilterMenu from '../../../hooks/useFilterMenu';

const FilterMenuDesktop = () => {
  const {
    handleOnCheckBoxUpdate,
    handleOnClose,
    handleOnSave,
    brands,
    checkedBrands,
  } = useFilterMenu();

  return (
    <FilterMenuWrapper>
      <Triangle>
        <svg width="30" height="15" xmlns="http://www.w3.org/2000/svg">
          <polygon points="0,15 15,0 30,15" fill="white" />
          <line
            x1="0"
            y1="15"
            x2="15"
            y2="0"
            stroke="#cccccc"
            strokeWidth="1"
          />
          <line
            x1="15"
            y1="0"
            x2="30"
            y2="15"
            stroke="#cccccc"
            strokeWidth="1"
          />
        </svg>
      </Triangle>
      <FilterContainer>
        <FilterHeader>
          <FilterTitle>Filtra por marca</FilterTitle>
          <FilterClose onClick={handleOnClose}>
            <CloseIcon />
          </FilterClose>
        </FilterHeader>
        <FilterBody>
          {brands.map((brand) => {
            const brandId = brand.id || '';
            const isChecked = checkedBrands.includes(brandId);
            return (
              <StyledCheckbox
                key={brand.id}
                label={brand.name}
                checked={isChecked}
                value={brand.id}
                onChange={(event) => handleOnCheckBoxUpdate(event, brandId)}
              />
            );
          })}
        </FilterBody>
        <FilterFooter>
          <FilterFooterButton onClick={() => handleOnSave(checkedBrands)}>
            Aplicar
          </FilterFooterButton>
        </FilterFooter>
      </FilterContainer>
    </FilterMenuWrapper>
  );
};

export default FilterMenuDesktop;
