import { useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IColorRequest } from '../../../models/IColorsRequest';
import { getTabOption } from '../../../redux/chooseColorSlice';
import {
  fetchColorsAsync,
  getColorRequestData,
} from '../../../redux/colorsSlice';
import { APPEND } from '../../../utils/constants';

const useInfiniteScrollDesktop = ({
  colorStatus,
  currentTenant,
  searchSeed,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const { brand, nextColorId, nextColorName } =
    useAppSelector(getColorRequestData);
  const colorFamilyId = useAppSelector(getTabOption) as string;
  const fetchAndUpdateColorsList = () => {
    if (ref.current) {
      const { scrollTop, offsetHeight, scrollHeight } = ref.current;

      if (
        nextColorId &&
        nextColorName &&
        colorStatus !== 'loading' &&
        scrollTop > 0 &&
        scrollTop >= scrollHeight - 2 * offsetHeight
      ) {
        let props: IColorRequest = {
          brand,
          tenant: currentTenant,
          group: colorFamilyId,
          nextColorId,
          nextColorName,
          mode: APPEND, // Review this
        };
        if (colorFamilyId === 'SEARCH') {
          props = {
            ...props,
            group: '',
            name: searchSeed,
          };
        }
        dispatch(fetchColorsAsync(props));
      }
    }
  };

  return { infiniteScrollRef: ref, fetchAndUpdateColorsList };
};

export default useInfiniteScrollDesktop;
