import styled from 'styled-components';

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #333333;
`;

export const StyledInputCheckbox = styled.input.attrs({ type: 'checkbox' })`
  width: 16px;
  height: 16px;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #cccccc;
`;

export const CheckboxLabel = styled.label`
  padding-left: 8px;
  cursor: pointer;
  font-size: 16px;
`;
