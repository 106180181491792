import React from 'react';
import { ColorWrapper, FeaturedColorsIconWrapper } from './styles';

export const DEFAULT_COLORS_FOR_DISABLED = ['#8C8C8C', '#C9C9C9', '#DDDDDD'];

export type ColorWrapperProps = {
  color: string;
};

type FeaturedColorsIconProps = {
  colors: string[];
  disabled?: boolean;
};

const FeaturedColorsIcon: React.FC<FeaturedColorsIconProps> = ({
  colors,
  disabled,
}) => {
  return (
    <FeaturedColorsIconWrapper data-testid="featured-colors-icon">
      {(disabled ? DEFAULT_COLORS_FOR_DISABLED : colors).map(
        (color, colorIndex) => (
          <ColorWrapper key={colorIndex} color={color} />
        )
      )}
    </FeaturedColorsIconWrapper>
  );
};

FeaturedColorsIcon.defaultProps = {
  disabled: false,
};

export default FeaturedColorsIcon;
