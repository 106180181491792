import styled from 'styled-components';
import { COLORS } from '../../../../../utils/constants/colors';

interface EnabledProp {
  blocked: boolean;
  middleCenter?: boolean;
}

export const RadioButton = styled.input<EnabledProp>`
  appearance: none;
  background-color: #ffffff;
  margin: 0;
  color: #bfbfbf;
  width: 19px;
  height: 18px;
  border-width: 1px;
  border-style: solid;
  border-color: #bfbfbf;
  border-radius: 50%;
  display: grid;
  place-content: center;
  margin-right: 5px;
  cursor: ${({ blocked }) => (!blocked ? 'pointer' : 'default')};

  &::before {
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 50%;
    transform: scale(0);
    transition: 0.12s transform ease-in-out;
    box-shadow: inset 1em 1em ${COLORS.primary};
  }

  &:checked {
    color: var(--form-control-color);
    border-width: 1px;
    border-style: solid;
    border-color: ${COLORS.primary};
  }

  &:checked::before {
    transform: scale(1);
  }
`;

export const RadioWrapper = styled.div<EnabledProp>`
  flex-direction: row;
  display: flex;
  flex: 1;
  background-color: white;
  line-height: 20px;
  align-items: ${({ middleCenter }) => (middleCenter ? 'center' : 'normal')};
`;

export const DataSelectionContainer = styled.div`
  width: 100%;
`;
