import {
  IconWrapper,
  NoSheenButton,
  NoSheenDescription,
  NoSheenTitle,
  NoSheenWrapper,
} from './styles';
import { ReactComponent as WarningIcon } from '../../../../../images/icons/warning.svg';
import useNoSheens from '../../../hooks/useNoSheens';

const NoSheenDesktop = () => {
  const { handleClickClose } = useNoSheens();

  return (
    <NoSheenWrapper>
      <IconWrapper>
        <WarningIcon />
      </IconWrapper>
      <NoSheenTitle>Acabado no disponible</NoSheenTitle>
      <NoSheenDescription>
        No hay acabados disponibles para este color en este momento. Selecciona
        otro color para ver las opciones de acabado.
      </NoSheenDescription>
      <NoSheenButton onClick={handleClickClose}>Entendido</NoSheenButton>
    </NoSheenWrapper>
  );
};

export default NoSheenDesktop;
