type HandleClickForwardCarouselProps = {
  pivotRoom: number;
  containerRef: React.RefObject<HTMLDivElement>;
  roomRefs: React.RefObject<HTMLDivElement>[];
  // eslint-disable-next-line no-unused-vars
  callback: (newPivotRoom: number) => void;
};
export const handleClickForwardCarousel = ({
  pivotRoom,
  containerRef,
  roomRefs,
  callback,
}: HandleClickForwardCarouselProps) => {
  const newPivotRoom = pivotRoom + 1;
  const offsetLeft = roomRefs[newPivotRoom].current?.offsetLeft;
  const isInViewOnRightLastElement = isInViewOnRight({
    parent: containerRef,
    child: roomRefs[roomRefs.length - 1],
  });

  if (
    containerRef.current !== null &&
    offsetLeft !== undefined &&
    isInViewOnRightLastElement === false
  ) {
    containerRef.current.scrollLeft = offsetLeft;
    callback(newPivotRoom);
  }
};

type HandleClickBackCarouselProps = {
  pivotRoom: number;
  containerRef: React.RefObject<HTMLDivElement>;
  roomRefs: React.RefObject<HTMLDivElement>[];
  // eslint-disable-next-line no-unused-vars
  callback: (newPivotRoom: number) => void;
};
export const handleClickBackCarousel = ({
  pivotRoom,
  containerRef,
  roomRefs,
  callback,
}: HandleClickBackCarouselProps) => {
  const newPivotRoom = pivotRoom - 1;
  if (newPivotRoom >= 0) {
    const offsetLeft = roomRefs[newPivotRoom].current?.offsetLeft;
    if (containerRef.current && offsetLeft !== undefined) {
      containerRef.current.scrollLeft = offsetLeft;
      callback(newPivotRoom);
    }
  }
};

type IsInViewOnRightProps = {
  parent: React.RefObject<HTMLDivElement>;
  child: React.RefObject<HTMLDivElement>;
};
export const isInViewOnRight = ({ parent, child }: IsInViewOnRightProps) => {
  const offsetWidth = parent.current?.offsetWidth;
  const scrollLeft = parent.current?.scrollLeft;
  const offsetLeft = child.current?.offsetLeft;
  const offsetWidthElem = child.current?.offsetWidth;

  if (
    offsetWidth !== undefined &&
    scrollLeft !== undefined &&
    offsetLeft !== undefined &&
    offsetWidthElem !== undefined
  ) {
    return offsetWidth + scrollLeft >= offsetLeft + offsetWidthElem;
  }

  return null;
};

export const isInViewOnLeft = (
  parent: React.RefObject<HTMLDivElement>,
  child: React.RefObject<HTMLDivElement>
) => {
  const scrollLeft = parent.current?.scrollLeft;
  const offsetLeft = child.current?.offsetLeft;
  const offsetWidthElem = child.current?.offsetWidth;

  if (
    scrollLeft !== undefined &&
    offsetLeft !== undefined &&
    offsetWidthElem !== undefined
  ) {
    return scrollLeft > offsetLeft;
  }

  return null;
};

export const createScrollStopListener = (
  element: React.RefObject<HTMLDivElement>,
  callback: any,
  timeout?: number
) => {
  let removed = false;
  let handle: ReturnType<typeof setTimeout>;
  const onScroll = () => {
    if (handle) {
      clearTimeout(handle);
    }
    handle = setTimeout(callback, timeout || 200); // default 200 ms
  };
  element.current?.addEventListener('scroll', onScroll);
  return () => {
    if (removed) {
      return;
    }
    removed = true;
    if (handle) {
      clearTimeout(handle);
    }
    element.current?.removeEventListener('scroll', onScroll);
  };
};

type FindIndexFirstRoomInViewProps = {
  containerRef: React.RefObject<HTMLDivElement>;
  roomRefs: React.RefObject<HTMLDivElement>[];
};
export const findIndexFirstRoomInView = ({
  containerRef,
  roomRefs,
}: FindIndexFirstRoomInViewProps) => {
  return roomRefs.findIndex(
    (element) => isInViewOnLeft(containerRef, element) === false
  );
};
