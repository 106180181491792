// Gallery Image Desktop
import CocinaGalleryImageDesktop from '../images/rooms/gallery/cocina-desktop.jpg';
import BanoGalleryImageDesktop from '../images/rooms/gallery/bano-desktop.jpg';
import ComedorGalleryImageDesktop from '../images/rooms/gallery/comedor-desktop.jpg';
import DormitorioGalleryImageDesktop from '../images/rooms/gallery/dormitorio-desktop.jpg';
import FachadaGalleryImageDesktop from '../images/rooms/gallery/fachada-desktop.jpg';
import LivingGalleryImageDesktop from '../images/rooms/gallery/living-desktop.jpg';

// Gallery Image Mobile
import CocinaGalleryImageMobile from '../images/rooms/gallery/cocina-mobile.jpg';
import BanoGalleryImageMobile from '../images/rooms/gallery/bano-mobile.jpg';
import ComedorGalleryImageMobile from '../images/rooms/gallery/comedor-mobile.jpg';
import DormitorioGalleryImageMobile from '../images/rooms/gallery/dormitorio-mobile.jpg';
import FachadaGalleryImageMobile from '../images/rooms/gallery/fachada-mobile.jpg';
import LivingGalleryImageMobile from '../images/rooms/gallery/living-mobile.jpg';

// Paintable Image Desktop
import LivingPaintableImageDesktop from '../images/rooms/paintable/living-desktop.png';
import CocinaPaintableImageDesktop from '../images/rooms/paintable/cocina-desktop.png';
import BanoPaintableImageDesktop from '../images/rooms/paintable/bano-desktop.png';
import ComedorPaintableImageDesktop from '../images/rooms/paintable/comedor-desktop.png';
import DormitorioPaintableImageDesktop from '../images/rooms/paintable/dormitorio-desktop.png';
import FachadaPaintableImageDesktop from '../images/rooms/paintable/fachada-desktop.png';

// Paintable Image Mobile
import LivingPaintableImageMobile from '../images/rooms/paintable/living-mobile.png';
import CocinaPaintableImageMobile from '../images/rooms/paintable/cocina-mobile.png';
import BanoPaintableImageMobile from '../images/rooms/paintable/bano-mobile.png';
import ComedorPaintableImageMobile from '../images/rooms/paintable/comedor-mobile.png';
import DormitorioPaintableImageMobile from '../images/rooms/paintable/dormitorio-mobile.png';
import FachadaPaintableImageMobile from '../images/rooms/paintable/fachada-mobile.png';

import { IRoom } from '../models/IRoom';

export const roomsMock: IRoom[] = [
  {
    name: 'Cocina',
    gridArea: 'cocina',
    galleryImage: {
      desktop: CocinaGalleryImageDesktop,
      mobile: CocinaGalleryImageMobile,
    },
    paintableImage: {
      desktop: CocinaPaintableImageDesktop,
      mobile: CocinaPaintableImageMobile,
    },
    initColor: '#f8d8ab',
    application: {
      id: '3',
      name: 'Interior',
    },
  },
  {
    name: 'Comedor',
    gridArea: 'comedor',
    galleryImage: {
      desktop: ComedorGalleryImageDesktop,
      mobile: ComedorGalleryImageMobile,
    },
    paintableImage: {
      desktop: ComedorPaintableImageDesktop,
      mobile: ComedorPaintableImageMobile,
    },
    initColor: '#f8d8ab',
    application: {
      id: '3',
      name: 'Interior',
    },
  },
  {
    name: 'Dormitorio',
    gridArea: 'dormitorio',
    galleryImage: {
      desktop: DormitorioGalleryImageDesktop,
      mobile: DormitorioGalleryImageMobile,
    },
    paintableImage: {
      desktop: DormitorioPaintableImageDesktop,
      mobile: DormitorioPaintableImageMobile,
    },
    initColor: '#f8d8ab',
    application: {
      id: '3',
      name: 'Interior',
    },
  },
  {
    name: 'Baño',
    gridArea: 'baño',
    galleryImage: {
      desktop: BanoGalleryImageDesktop,
      mobile: BanoGalleryImageMobile,
    },
    paintableImage: {
      desktop: BanoPaintableImageDesktop,
      mobile: BanoPaintableImageMobile,
    },
    initColor: '#f8d8ab',
    application: {
      id: '3',
      name: 'Interior',
    },
  },
  {
    name: 'Living',
    gridArea: 'living',
    galleryImage: {
      desktop: LivingGalleryImageDesktop,
      mobile: LivingGalleryImageMobile,
    },
    paintableImage: {
      desktop: LivingPaintableImageDesktop,
      mobile: LivingPaintableImageMobile,
    },
    initColor: '#f8d8ab',
    application: {
      id: '3',
      name: 'Interior',
    },
  },
  {
    name: 'Fachada',
    gridArea: 'fachada',
    galleryImage: {
      desktop: FachadaGalleryImageDesktop,
      mobile: FachadaGalleryImageMobile,
    },
    paintableImage: {
      desktop: FachadaPaintableImageDesktop,
      mobile: FachadaPaintableImageMobile,
    },
    initColor: '#f8d8ab',
    application: {
      id: '4',
      name: 'Exterior',
    },
  },
];
