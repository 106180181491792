import { useAppDispatch } from '../../../../app/hooks';
import { previousPosition } from '../../../../redux/stepperSlice';
import { PillButton } from '@digital-retail/journey-ui-kit';
import { COLORS } from '../../../../utils/constants/colors';
import { ReactComponent as LeftArrow } from '../../../../images/icons/left-arrow.svg';
import { MobileActionsBarWrapper } from '../MobileActionsBar/styles';

const MobileActionsBarSidebar = () => {
  const dispatch = useAppDispatch();

  const handleClickPreviousPosition = () => {
    dispatch(previousPosition());
  };

  return (
    <MobileActionsBarWrapper data-testid="mobile-actions-bar-sidebar">
      <PillButton
        size="xs"
        variant="ghost"
        Icon={LeftArrow}
        iconStyle={{
          fill: COLORS.gray20,
        }}
        style={{
          color: COLORS.gray20,
        }}
        onClick={handleClickPreviousPosition}
        data-testid="button-previous-position"
      >
        Seleccionar brillo
      </PillButton>
    </MobileActionsBarWrapper>
  );
};

export default MobileActionsBarSidebar;
