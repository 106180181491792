import { useAppSelector } from '../../../../app/hooks';
import MediaComponent from '../../../../components/MediaComponent';
import { getAvailableSkusData } from '../../../../redux/colorsSlice';
import NoSheenDesktop from './Desktop';
import { NoSheenDescription, NoSheenWrapper } from './Desktop/styles';
import AlertImage from '../../../../images/Alert.png';
import useNoSheens from '../../hooks/useNoSheens';
import RoundButton from '../../../../components/RoundButton';

const NoSheens = () => {
  const availableSkus = useAppSelector(getAvailableSkusData);
  const { handleClickClose } = useNoSheens();

  return (
    <>
      {availableSkus && availableSkus.length === 0 ? (
        <>
          <MediaComponent from="tablet">
            <NoSheenDesktop />
          </MediaComponent>
          <MediaComponent from="mobile" to="tablet">
            <NoSheenWrapper>
              <NoSheenDescription>
                <img src={AlertImage} />
                <h2>Acabado no disponible</h2>
                <p>
                  No hay acabados disponibles para este color en este momento.
                  Selecciona otro color para ver las opciones de acabado.
                </p>
              </NoSheenDescription>
              <RoundButton
                wrapperStyle={{ maxWidth: 236 }}
                onClick={handleClickClose}
              >
                Entendido
              </RoundButton>
            </NoSheenWrapper>
          </MediaComponent>
        </>
      ) : null}
    </>
  );
};

export default NoSheens;
