import React from 'react';
import MediaComponent from '../MediaComponent';
import StepperDesktop from '../StepperDesktop';
import Stepper from '../Stepper';
import {
  ContainerWrapper,
  HeaderWrapper,
  HomeWrapper,
  MainWrapper,
  SideBarWrapper,
} from './styles';
import { useInfiniteScrollMobile } from '../../features/ChooseColor/hooks/useInfiniteScrollMobile';

type LayoutProps = {
  sidebar: React.ReactNode;
};

const Layout: React.FC<LayoutProps> = ({ children: main, sidebar }) => {
  const { infiniteScrollRef, fetchAndUpdateColorsList } =
    useInfiniteScrollMobile();
  return (
    <HomeWrapper
      id="home"
      ref={infiniteScrollRef}
      onScroll={fetchAndUpdateColorsList}
    >
      <HeaderWrapper id="header">
        <MediaComponent from="tablet" style={{ width: '100%' }}>
          <StepperDesktop />
        </MediaComponent>
        <MediaComponent from="mobile" to="tablet" style={{ width: '100%' }}>
          <Stepper />
        </MediaComponent>
      </HeaderWrapper>
      <ContainerWrapper id="container">
        <MainWrapper id="main">{main}</MainWrapper>
        <SideBarWrapper id="sidebar">{sidebar}</SideBarWrapper>
      </ContainerWrapper>
    </HomeWrapper>
  );
};

export default Layout;
