import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import CoatOptions from '../../../../components/CoatOptions';
import {
  getSelectedSheen,
  getSheenList,
  setVisibleSheenOptions,
} from '../../../../redux/colorsSlice';
import {
  CloseButtonStyled,
  FormCoatWrapper,
  OptionsWrapper,
  TitleWrapper,
} from './styles';
import { ReactComponent as IconClose } from '../../../../images/icons/close.svg';
import useChooseBrightness from '../../hooks/useChooseBrightness';

const FormSheen: React.FC = () => {
  const dispatch = useAppDispatch();
  const sheenList = useAppSelector(getSheenList);
  const selectedSheen = useAppSelector(getSelectedSheen);
  const { handleClickChangeSheen, areSelectedSkusAvailable } =
    useChooseBrightness();

  const handleClickClose = () => {
    dispatch(setVisibleSheenOptions(false));
  };

  if (!areSelectedSkusAvailable) {
    return <></>;
  }

  return (
    <FormCoatWrapper data-testid="form-coat">
      <TitleWrapper>Elige el brillo</TitleWrapper>
      <CloseButtonStyled onClick={handleClickClose}>
        <IconClose />
      </CloseButtonStyled>
      <OptionsWrapper>
        <CoatOptions
          sheenList={sheenList}
          selectedSheen={selectedSheen}
          setSheen={(sheen, isSheenSelected) =>
            handleClickChangeSheen(sheen, isSheenSelected)
          }
        />
      </OptionsWrapper>
    </FormCoatWrapper>
  );
};

export default FormSheen;
