import { useEffect } from 'react';
import { tenantSpecificConfig } from '../utils/constants';
import { useLocation } from 'react-router-dom';

export const useScripts = () => {
  const queryParams = new URLSearchParams(useLocation().search);

  useEffect(() => {
    const tenant = queryParams.get('tenant');
    if (tenant) {
      const id = 'adobe-launch';
      const adobeDtmScript = document.createElement('script');
      const { REACT_APP_ENVIRONMENT = 'production' } = process.env;
      const businessUnit = tenant.substring(0, 2).toUpperCase();
      const shortTenant = tenant.substring(2).toLowerCase();
      adobeDtmScript.src =
        tenantSpecificConfig[REACT_APP_ENVIRONMENT][businessUnit][
          shortTenant
        ].adobeLaunch;
      adobeDtmScript.id = id;
      adobeDtmScript.async = true;
      document.head.appendChild(adobeDtmScript);
      return () => {
        document.head.removeChild(adobeDtmScript);
      };
    }
  }, []);
};
