import React from 'react';
import { useWindowSize } from '../../hooks/useWindowSize';
import { IMedia } from '../../utils/constants/medias';
import { mediaComponentValidator } from './utils';

type MediaComponentV2Props = {
  /**
   *
   */
  from?: IMedia;

  /**
   *
   */
  to?: IMedia;

  /**
   *
   */
  style?: React.CSSProperties;
};

const defaultProps: MediaComponentV2Props = {};

/**
 * Media Component has the function of rendering its child components depending on the defined rule,
 * by means of the "from" and "to" parameters. Which can have values like: mobile, tablet, desktop, superDesktop.
 * @version 2
 * @param props
 * @returns
 *
 * @example
 * <MediaComponent from="tablet">
 *   Hello World!
 * </MediaComponent>
 *
 * @example
 * <MediaComponent from="mobile" to="tablet">
 *   Hello World!
 * </MediaComponent>
 */
const MediaComponent: React.FC<MediaComponentV2Props> = ({
  from,
  to,
  style,
  children,
}) => {
  const { width } = useWindowSize();

  if (!mediaComponentValidator({ from, to, width })) {
    return null;
  }

  if (style) {
    return <div style={style}>{children}</div>;
  }

  return <>{children}</>;
};

MediaComponent.defaultProps = defaultProps;

export default MediaComponent;
