import React from 'react';
import { Spinner } from '@digital-retail/journey-ui-kit';
import { LoadingColorWrapper } from './styles';

const LoadingColor = () => {
  return (
    <LoadingColorWrapper data-testid="loading-horizontal-centering">
      <Spinner size="sm" />
    </LoadingColorWrapper>
  );
};

export default LoadingColor;
