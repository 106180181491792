import { useAppSelector } from '../../../app/hooks';
import { getProductDetails, getSizes } from '../../../redux/configSlice';
import { getFinalSkusData, getSelectedColor } from '../../../redux/colorsSlice';
import {
  addSizesDataIntoSkusData,
  formatSkusData,
} from '../../../utils/commonUtils';

const useCalculateMetersForm = () => {
  const allSizes = useAppSelector(getSizes);
  const finalSkus = useAppSelector(getFinalSkusData);
  const productDetails = useAppSelector(getProductDetails);
  const formattedFinalSkus = formatSkusData(finalSkus);
  const brandId = useAppSelector(getSelectedColor).brand;
  const formattedFinalSkusWithSizes = addSizesDataIntoSkusData(
    formattedFinalSkus,
    allSizes,
    brandId,
    productDetails
  );

  return {
    finalSkusWithSizes: formattedFinalSkusWithSizes,
  };
};

export default useCalculateMetersForm;
