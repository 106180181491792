import styled from 'styled-components';
import { mediaQuery } from '../../../utils';

export const Wrapper = styled.div<{ isMobileApp: boolean }>`
  border-radius: 3px;
  border: 1px solid #d8d8d8;
  background: #fff;
  width: 357px;
  font-family: Lato;
  font-style: normal;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-bottom: ${({ isMobileApp }) => (isMobileApp ? '50px' : '0')};

  ${mediaQuery.mobileOnly(`
    width: auto;
    flex-shrink: 1;
    border: 1.5px solid #d8d8d8;
    min-width: 300px;
    box-sizing: border-box;
  `)}
`;

export const CardTitle = styled.div`
  color: #fff;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  background: #66abe3;
  padding: 16px;

  ${mediaQuery.mobileOnly(`
    background: #fff;
    color: #595959;
    padding: 16px 16px 0;
  `)}
`;

export const CardBody = styled.div`
  padding: 16px 24px 24px;

  ${mediaQuery.mobileOnly(`
     padding: 8px 16px 16px;
  `)}
`;

export const CharacteristicsContainer = styled.div`
  border-radius: 4px;
  background: #f5f5f5;
  padding: 16px 24px;

  ${mediaQuery.mobileOnly(`
    padding: 16px;
  `)}
`;

export const CharacteristicsHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const PaintIconWrapper = styled.div`
  transform: scale(0.8);
`;

export const CharacteristicsTitle = styled.div`
  color: #595959;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  margin-left: 8px;
`;

export const CharacteristicsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const CharacteristicsListItem = styled.div`
  display: flex;
  align-items: baseline;
`;

export const CheckIconWrapper = styled.div`
  transform: scale(1.2);
`;

export const CharacteristicsListItemText = styled.div`
  color: #595959;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-left: 4px;
`;

export const QuantitiesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HorizontalLine = styled.div`
  border-bottom: 1px solid #bfbfbf;
`;

export const QuantityContainer = styled.div`
  display: flex;
  margin-top: 16px;
  margin-bottom: 11px;
  align-items: center;
  justify-content: space-between;
`;

export const QuantityLeft = styled.div`
  display: flex;
  flex-direction: column;
`;

export const QuantityTitle = styled.div`
  color: #333;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
`;

export const QuantityDescription = styled.div`
  color: #595959;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
`;

export const QuantityRight = styled.div`
  width: 132px;
`;

export const CardFooter = styled.div`
  padding: 0 40px 16px;
`;

export const TotalQuantityContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  color: #595959;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-top: 8px;
`;

export const PriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  color: #333;
  font-family: Lato;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`;

export const FooterButton = styled.button`
  all: unset;
  color: ${(props) => (props.disabled ? '#8c8c8c' : '#dd0021')};
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  border-radius: 3px;
  border-width: ${(props) => (props.disabled ? '0' : '1px')};
  border-color: ${(props) => (props.disabled ? '#ccc' : '#dd0021')};
  border-style: solid;
  padding: 12px 0;
  width: 100%;
  background-color: ${(props) => (props.disabled ? '#f5f5f5' : '#fff')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;

export const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #cfdcf3;
  padding: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
  gap: 4px;
  border-radius: 4px;
`;

export const InfoText = styled.div`
  color: #004085;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;
