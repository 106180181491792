import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { IStatusAsyncThunk } from '../../models/IStatusAsyncThunk';
import { addItemToBasketApi } from '../../api/addItemToBasketApi';

interface SetQualityState {
  addToCart: boolean;
  isAddToCartStatus: IStatusAsyncThunk;
  finalQualitiesAndQuantitiesData: any;
}

const initialState: SetQualityState = {
  addToCart: false,
  isAddToCartStatus: 'idle',
  finalQualitiesAndQuantitiesData: null,
};

export const addItemsToCart = createAsyncThunk(
  'colors/addToCart',
  async (props: any) => {
    const data = await addItemToBasketApi(props);

    return data;
  }
);

export const setQualitySlice = createSlice({
  name: 'settingQuality',
  initialState,
  reducers: {
    showAddToCart: (state) => {
      state.addToCart = true;
    },
    setfinalQualitiesAndQuantitiesData: (state, action: PayloadAction<any>) => {
      state.finalQualitiesAndQuantitiesData = action.payload;
    },
    resetSettingQualityState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(addItemsToCart.pending, (state) => {
        state.isAddToCartStatus = 'loading';
      })
      .addCase(addItemsToCart.fulfilled, (state) => {
        state.isAddToCartStatus = 'idle';
        state.addToCart = true;
      })
      .addCase(addItemsToCart.rejected, (state) => {
        state.isAddToCartStatus = 'failed';
      });
  },
});

export const {
  showAddToCart,
  setfinalQualitiesAndQuantitiesData,
  resetSettingQualityState,
} = setQualitySlice.actions;

export const getAddToCart = (state: RootState) =>
  state.settingQuality.addToCart;

export const getIsCartAddTostatus = (state: RootState) =>
  state.settingQuality.isAddToCartStatus;

export const getfinalQualitiesAndQuantitiesData = (state: RootState) =>
  state.settingQuality.finalQualitiesAndQuantitiesData;

export default setQualitySlice.reducer;
