import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  getEstimatedSquareMeters,
  getNumberOfRoomDoors,
  getNumberOfRoomWindows,
  getRoomHeight,
  getRoomLong,
  getShowCalculateSquareMeters,
  hideCalculateSquareMeters,
  setEstimatedSquareMeters,
  setNumberOfRoomDoors,
  setNumberOfRoomWindows,
  setRoomHeight,
  setRoomLong,
  setSquareMeter,
} from '../../../../redux/calculateQuantitySlice';
import {
  LongInputWrapper,
  HeightInputWrapper,
  ModalCalculateSquareMetersWrapper,
  NumberOfDoorsInputWrapper,
  NumberOfWindowsInputWrapper,
  TitleWrapper,
  LabelWrapper,
  FormWrapper,
  FormCalculateSquareMetersWrapper,
  GraphicHelpWrapper,
  TextHelpWrapper,
  ActionsWrapper,
  ResultContainerWrapper,
  ResultLabelWrapper,
  ResultValueWrapper,
  AlertWrapper,
} from './styles';
import { Modal, Input, Button } from '@digital-retail/journey-ui-kit';
import InputSpinner from '../../../../components/InputSpinner';
import { ReactComponent as ImagenCalculadoraM2 } from '../../../../images/icons/imagen-calculadora-m2.svg';
import { ReactComponent as IndicatorA } from '../../../../images/icons/indicador-A.svg';
import { ReactComponent as IndicatorB } from '../../../../images/icons/indicador-B.svg';
import { getSquareMetersByRoom } from './utils';
import Alert from '../../../../components/Alert';
import { TEXTS } from '../../../../utils/constants/texts';

const ModalCalculateSquareMeters: React.FC = () => {
  const dispatch = useAppDispatch();
  const open = useAppSelector(getShowCalculateSquareMeters);
  const numberOfRoomDoors = useAppSelector(getNumberOfRoomDoors);
  const numberOfRoomWindows = useAppSelector(getNumberOfRoomWindows);
  const roomHeight = useAppSelector(getRoomHeight);
  const roomLong = useAppSelector(getRoomLong);
  const estimatedSquareMeters = useAppSelector(getEstimatedSquareMeters);

  const handleClickClose = () => {
    dispatch(hideCalculateSquareMeters());
  };

  const handleClickConfirm = () => {
    handleClickClose();
    const newSquareMeter =
      estimatedSquareMeters !== null ? estimatedSquareMeters?.toString() : null;
    dispatch(setSquareMeter(newSquareMeter));
  };

  const handleChangeHeight = (value: string) => {
    dispatch(setRoomHeight(value));
  };

  const handleChangeLong = (value: string) => {
    dispatch(setRoomLong(value));
  };

  const handleChangeNumberOfDoors = (value: number) => {
    dispatch(setNumberOfRoomDoors(value));
  };

  const handleChangeNumberOfWindows = (value: number) => {
    dispatch(setNumberOfRoomWindows(value));
  };

  useEffect(() => {
    const squareMetersByRoom = getSquareMetersByRoom({
      roomHeight: roomHeight !== null ? parseFloat(roomHeight) : 0,
      roomLong: roomLong !== null ? parseFloat(roomLong) : 0,
      numberOfRoomDoors,
      numberOfRoomWindows,
    });

    dispatch(setEstimatedSquareMeters(squareMetersByRoom));
  }, [numberOfRoomDoors, numberOfRoomWindows, roomHeight, roomLong]);

  return (
    <ModalCalculateSquareMetersWrapper data-testid="modal-calculate-square-meters">
      <Modal open={open} size="md" onClose={handleClickClose}>
        <TitleWrapper>Mide los muros que deseas pintar</TitleWrapper>
        <FormWrapper>
          <FormCalculateSquareMetersWrapper>
            <HeightInputWrapper>
              <LabelWrapper>
                <IndicatorA />
                <span>Alto (m)</span>
              </LabelWrapper>
              <Input
                type="decimal"
                value={roomHeight?.toString() || ''}
                data-testid="height-input"
                placeholder="Ej: 2.20"
                helper="Mide de suelo a cielo tu muro"
                onChange={handleChangeHeight}
              />
            </HeightInputWrapper>
            <LongInputWrapper>
              <LabelWrapper>
                <IndicatorB />
                <span>Largo (m)</span>
              </LabelWrapper>
              <Input
                type="decimal"
                value={roomLong?.toString() || ''}
                data-testid="long-input"
                placeholder="Ej: 8"
                helper="Suma el largo de los muros a pintar"
                onChange={handleChangeLong}
              />
            </LongInputWrapper>
            <NumberOfDoorsInputWrapper>
              <LabelWrapper>
                <span>Cantidad de puertas</span>
              </LabelWrapper>
              <InputSpinner
                defaultValue={numberOfRoomDoors}
                min={0}
                onChange={handleChangeNumberOfDoors}
              />
            </NumberOfDoorsInputWrapper>
            <NumberOfWindowsInputWrapper>
              <LabelWrapper>
                <span>Cantidad de ventanas</span>
              </LabelWrapper>
              <InputSpinner
                defaultValue={numberOfRoomWindows}
                min={0}
                onChange={handleChangeNumberOfWindows}
              />
            </NumberOfWindowsInputWrapper>
          </FormCalculateSquareMetersWrapper>
          <GraphicHelpWrapper>
            <ImagenCalculadoraM2 />
          </GraphicHelpWrapper>
          <TextHelpWrapper>
            Calculamos un tamaño estándar para las puertas y ventanas.
          </TextHelpWrapper>
          {!(estimatedSquareMeters !== null && estimatedSquareMeters <= 0) ? (
            <ResultContainerWrapper>
              <ResultLabelWrapper>Resultado:</ResultLabelWrapper>
              <ResultValueWrapper>
                {estimatedSquareMeters}{' '}
                <small>
                  m<sup>2</sup>
                </small>
              </ResultValueWrapper>
            </ResultContainerWrapper>
          ) : (
            <AlertWrapper>
              <Alert variant="warning">
                {TEXTS.warningModalCalculateSquareMeter}
              </Alert>
            </AlertWrapper>
          )}
          <ActionsWrapper>
            <Button
              variant="notTransactional"
              size="md"
              onClick={handleClickConfirm}
              data-testid="confirm-button"
              disabled={
                estimatedSquareMeters === null || estimatedSquareMeters <= 0
              }
            >
              Confirmar
            </Button>
          </ActionsWrapper>
        </FormWrapper>
      </Modal>
    </ModalCalculateSquareMetersWrapper>
  );
};

export default ModalCalculateSquareMeters;
