import CalculateQuantity from '../../CalculateQuantity';
import CalculateQuantitySidebar from '../../CalculateQuantity/components/Sidebar';

import ChooseColor from '../../ChooseColor';
import ChooseColorSidebar from '../../ChooseColor/components/Sidebar';
import ChooseRoom from '../../ChooseRoom';
import QualitiesAndQuantities from '../../SettingQuality';

interface View {
  main: React.FC;
  sidebar: React.FC | null;
  title: string;
  mobileTitle: string;
  backButtonLabel: string;
}

export const views: View[] = [
  {
    title: 'Selecciona un ambiente',
    mobileTitle: 'Elige un ambiente',
    main: ChooseRoom,
    sidebar: null,
    backButtonLabel: '',
  },
  {
    title: 'Elige un color y brillo',
    mobileTitle: 'Elige color y brillo',
    main: ChooseColor,
    sidebar: ChooseColorSidebar,
    backButtonLabel: 'Volver a elegir ambiente',
  },
  {
    title: 'Calcula la cantidad',
    mobileTitle: 'Cantidad',
    main: CalculateQuantity,
    sidebar: CalculateQuantitySidebar,
    backButtonLabel: 'Volver a elegir color',
  },
  {
    title: 'Determina la calidad',
    mobileTitle: 'Confirmar',
    main: QualitiesAndQuantities,
    sidebar: null,
    backButtonLabel: 'Volver a ingresar cantidad',
  },
];
