import React from 'react';
import RoomCarousel from '../RoomCarousel';
import Round from '../Round';
import {
  ArrowWrapper,
  MessageWrapper,
  RoomOverlayWrapper,
  RoomSelectorContentWrapper,
  RoomSelectorWrapper,
  SwitchWrapper,
} from './styles';
import arrow from '../../../../images/icons/top.svg';
import { getColorStatus } from '../../../../redux/colorsSlice';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  getIsOpenRoomSelector,
  setOpenRoomSelector,
} from '../../../../redux/roomSlice';

export type ArrowWrapperProps = {
  isOpen: boolean;
};

export type RoomOverlayWrapperProps = {
  isOpen: boolean;
};

const RoomSelector: React.FC = () => {
  const dispatch = useAppDispatch();
  const colorStatus = useAppSelector(getColorStatus);
  const isOpenRoomSelector = useAppSelector(getIsOpenRoomSelector);
  const isDisabled = colorStatus === 'loading';

  const handleClickRoomOverlay = () => {
    if (!isDisabled) {
      dispatch(setOpenRoomSelector(false));
    }
  };

  const handleClick = () => {
    if (!isDisabled) {
      dispatch(setOpenRoomSelector(!isOpenRoomSelector));
    }
  };

  return (
    <RoomSelectorWrapper
      data-testid="room-selector"
      className={`${isDisabled ? 'disabled' : ''}`}
    >
      <RoomOverlayWrapper
        isOpen={isOpenRoomSelector}
        onClick={handleClickRoomOverlay}
        data-testid="room-overlay"
      />

      <RoomSelectorContentWrapper data-testid="room-selector-content">
        <SwitchWrapper onClick={handleClick} data-testid="switch">
          <ArrowWrapper isOpen={isOpenRoomSelector}>
            <img src={arrow} />
          </ArrowWrapper>
        </SwitchWrapper>
        {isOpenRoomSelector ? (
          <RoomCarousel />
        ) : (
          <MessageWrapper onClick={handleClick}>
            Mostrar otros ambientes del hogar
          </MessageWrapper>
        )}
        <Round />
      </RoomSelectorContentWrapper>
    </RoomSelectorWrapper>
  );
};

export default RoomSelector;
