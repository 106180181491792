import styled from 'styled-components';

export const CoatOptionsWrapper = styled.div``;

export const CoatItemWrapper = styled.div`
  padding-top: 6px;
  height: 40px;
`;

export const CoatLabelWrapper = styled.label`
  display: inline-flex;
  cursor: pointer;
  width: 100%;

  &.disabled,
  &.disabled input {
    color: #bfbfbf;
    cursor: not-allowed;
  }
`;

export const CoatNameWrapper = styled.div`
  font-size: 14px;
  align-self: center;

  &.checked {
    font-weight: 700;
  }
`;

export const RadioButtonWrapper = styled.input`
  appearance: none;
  margin: 0 10px 0 7px;
  min-width: 35px;
  width: 40px;
  height: 40px;
  border-radius: 3px;
  border: 0;
  z-index: 1;
  transition: all 0.2s;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;

  &:hover {
    transform: scale(1.238);
    z-index: 2;
  }

  &.checked {
    transform: scale(1.238);
    z-index: 2;
  }
`;
// TODO
