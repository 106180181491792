import React from 'react';
import { getCurrenPosition } from '../../redux/stepperSlice';
import { views } from './views';
import { useAppSelector } from '../../app/hooks';
import Layout from '../../components/Layout';

const Home: React.FC = () => {
  const position = useAppSelector(getCurrenPosition);
  const getMain = (): React.ReactNode => {
    const Component = views[position].main;
    return <Component />;
  };

  const getSidebar = (): React.ReactNode => {
    const Component = views[position].sidebar;
    if (Component) {
      return <Component />;
    }
    return null;
  };

  return <Layout sidebar={getSidebar()}>{getMain()}</Layout>;
};

export default Home;
