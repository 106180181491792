import { useAppDispatch } from '../../../app/hooks';
import { setSearchSeed } from '../../../redux/chooseColorSlice';
import { resetSelectedOptions } from '../../../redux/colorsSlice';
import useDebounceFetchColors from './useDebounceFetchColors';

const useHandleInputValueChange = () => {
  const debouncedFetchColors = useDebounceFetchColors(500);
  const dispatch = useAppDispatch();

  const handleInputValueChange = (value: string) => {
    dispatch(resetSelectedOptions());
    dispatch(setSearchSeed(value));
    debouncedFetchColors(value);
  };

  return handleInputValueChange;
};

export default useHandleInputValueChange;
