import { PropsWithChildren } from 'react';
import { Button } from '@digital-retail/journey-ui-kit';
import { ButtonWrapper } from './styles';

export type RoundButtonProps = PropsWithChildren<{
  onClick?: () => void;
  disabled?: boolean;
  maxWidth?: string;
  wrapperStyle?: React.CSSProperties;
  buttonStyle?: React.CSSProperties;
}>;

const defaultProps: RoundButtonProps = {
  disabled: false,
};

const RoundButton = ({
  onClick,
  children,
  disabled,
  wrapperStyle,
  buttonStyle,
  ...props
}: RoundButtonProps) => {
  return (
    <ButtonWrapper style={wrapperStyle} {...props}>
      <Button
        data-testid="round-button"
        pill
        disabled={disabled}
        style={buttonStyle}
        onClick={onClick}
      >
        {children}
      </Button>
    </ButtonWrapper>
  );
};

RoundButton.defaultProps = defaultProps;

export default RoundButton;
