import styled from 'styled-components';
import { COLORS } from '../../../../../../utils/constants/colors';

export const DesktopWrapper = styled.div`
  border-radius: 3px 0px 0px 3px;
  border: 1px solid #ccc;
  background: #fff;
  padding: 24px 32px;
  flex-direction: column;
`;

export const PageTitle = styled.div`
  color: #333;
  font-family: Lato;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 16px;
`;

export const CardsContainer = styled.div`
  display: flex;
  gap: 24px;
  overflow-x: auto;
`;

export const VolverButtonContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
  align-items: baseline;
`;

export const ButtonIconContainer = styled.div`
  width: 24px;
  height: 24px;
  & svg {
    width: 16px;
    height: 16px;
    fill: ${COLORS.primary};
  }
`;

export const VolverButton = styled.button`
  background: none;
  border: none;
  color: ${COLORS.primary};
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.16px;
  padding: 0;
  cursor: pointer;
`;
