import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { OptionIconStyled, OptionStyled, OptionTextStyled } from './styles';
import { camelCase } from '../../../../utils/camelCase';
import FeaturedColorsIcon from '../FeaturedColorsIcon';
import { setTabOption } from '../../../../redux/chooseColorSlice';
import { ReactComponent as SearchIcon } from '../../../../images/icons/search.svg';
import { COLORS } from '../../../../utils/constants/colors';
import Box from '../../../../components/Box';
import {
  getColorFamilyList,
  getConfigStatus,
  getTenant,
} from '../../../../redux/configSlice';
import {
  fetchColorsAsync,
  getSelectedBrands,
} from '../../../../redux/colorsSlice';
import { REPLACE } from '../../../../utils/constants';

const ListColorFamily = () => {
  const dispatch = useAppDispatch();
  const colorFamilyList = useAppSelector(getColorFamilyList);
  const configStatus = useAppSelector(getConfigStatus);
  const brands = useAppSelector(getSelectedBrands).join(',');
  const currentTenant = useAppSelector(getTenant);
  const handleClickColorOption = (colorFamilyId: string) => {
    const props = {
      tenant: currentTenant,
      brand: brands,
      group: colorFamilyId,
      mode: REPLACE,
    };
    dispatch(fetchColorsAsync(props));
    dispatch(setTabOption(colorFamilyId));
  };

  const handleClickSearch = () => {
    dispatch(setTabOption('SEARCH'));
  };

  return (
    <Box maxHeight="462px">
      <div data-testid="list-color-family">
        <OptionStyled
          loading={configStatus}
          onClick={handleClickSearch}
          data-testid="option"
        >
          <OptionIconStyled>
            <SearchIcon style={{ fill: COLORS.primary }} />
          </OptionIconStyled>
          <OptionTextStyled loading={configStatus}>Buscador</OptionTextStyled>
        </OptionStyled>
        {colorFamilyList.map((colorFamily, colorFamilyIndex) => (
          <OptionStyled
            key={colorFamilyIndex}
            loading={configStatus}
            data-testid="option"
            onClick={() => handleClickColorOption(colorFamily.id)}
          >
            <OptionIconStyled>
              <FeaturedColorsIcon
                disabled={configStatus === 'loading'}
                colors={colorFamily.featuredColors}
              />
            </OptionIconStyled>
            <OptionTextStyled loading={configStatus}>
              {camelCase(colorFamily.name)}
            </OptionTextStyled>
          </OptionStyled>
        ))}
      </div>
    </Box>
  );
};

export default ListColorFamily;
