import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IColorRequest } from '../../../models/IColorsRequest';
import { getSearchSeed, getTabOption } from '../../../redux/chooseColorSlice';
import {
  fetchColorsAsync,
  getColorRequestData,
  getColorStatus,
} from '../../../redux/colorsSlice';
import { APPEND } from '../../../utils/constants';
import { getTenant } from '../../../redux/configSlice';
import { getCurrenPosition } from '../../../redux/stepperSlice';

export const useInfiniteScrollMobile = () => {
  const delta = 4;
  const ref = useRef<HTMLDivElement>(null);
  const position = useAppSelector(getCurrenPosition);
  const currentTenant = useAppSelector(getTenant);
  const searchSeed = useAppSelector(getSearchSeed);
  const colorStatus = useAppSelector(getColorStatus);
  const [showGradientTop, setShowGradientTop] = useState(false);
  const [showGradientBottom, setShowGradientBottom] = useState(true);
  const dispatch = useAppDispatch();
  const { brand, nextColorId, nextColorName } =
    useAppSelector(getColorRequestData);
  const colorFamilyId = useAppSelector(getTabOption) as string;
  const updateState = () => {
    if (ref.current) {
      const { scrollTop, offsetHeight, scrollHeight } = ref.current;
      setShowGradientTop(!(scrollTop <= delta));
      setShowGradientBottom(
        !(offsetHeight + scrollTop >= scrollHeight - delta)
      );
    }
  };

  useEffect(() => {
    updateState();
  }, []);

  const fetchAndUpdateColorsList = () => {
    updateState();
    if (position == 1) {
      if (ref.current) {
        const { scrollTop, offsetHeight, scrollHeight } = ref.current;
        if (
          nextColorId &&
          nextColorName &&
          colorStatus !== 'loading' &&
          scrollTop > 0 &&
          scrollTop >= scrollHeight - 2 * offsetHeight
        ) {
          let props: IColorRequest = {
            brand,
            tenant: currentTenant,
            group: colorFamilyId,
            nextColorId,
            nextColorName,
            mode: APPEND,
          };
          if (colorFamilyId === 'SEARCH') {
            props = {
              ...props,
              group: '',
              name: searchSeed,
            };
          }
          dispatch(fetchColorsAsync(props));
        }
      }
    }
  };

  return {
    infiniteScrollRef: ref,
    fetchAndUpdateColorsList,
    showGradientTop,
    showGradientBottom,
  };
};
