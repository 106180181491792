import { Spinner } from '@digital-retail/journey-ui-kit';
import { OverLay } from './styles';

type Variant = 'success' | 'danger' | 'warning' | 'default';
type Sizes = 'xs' | 'sm' | 'md' | 'lg';

interface LoaderScreenProps {
  variant?: Variant;
  size?: Sizes;
}

export const LoaderScreen: React.FC<LoaderScreenProps> = ({
  variant = 'default',
  size = 'sm',
}) => {
  return (
    <OverLay>
      <Spinner variant={variant} size={size} />
    </OverLay>
  );
};

export default LoaderScreen;
