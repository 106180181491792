import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  25% { transform: rotate(90deg); }
  50% { transform: rotate(180deg); }
  75% { transform: rotate(270deg); }
  100% { transform: rotate(360deg); }
`;

export const SpinnerWrapper = styled.div`
  position: absolute;
  top: 30%;
  left: 50%;
  border: 4px solid #ffffff;
  border-radius: 50%;
  border-top: 4px solid #000;
  width: 24px;
  height: 24px;
  animation: ${spin} 1s linear infinite;
  z-index: 250;
`;
