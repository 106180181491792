import styled from 'styled-components';
import { mediaQuery } from '../../../utils';

export const Wrapper = styled.div`
  padding: 0 40px;

  ${mediaQuery.mobileOnly(`
    padding: 0;
    overflow-x: auto;
    height: fit-content;
  `)}
`;
