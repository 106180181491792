import styled from 'styled-components';

export const FilterMenuWrapper = styled.div`
  position: relative;
`;

export const Triangle = styled.div`
  position: absolute;
  left: 200px;
  z-index: 201;
  top: -15px;
`;

export const FilterContainer = styled.div`
  position: absolute;
  width: 236px;
  z-index: 200;
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid #cccccc;
`;

export const FilterHeader = styled.div`
  display: flex;
  justify-content: space-between;
  color: #333;
  font-family: Lato;
  font-size: 17px;
  font-weight: 700;
  line-height: 22px;
  padding: 12px 12px 8px 12px;
  border-bottom: 1px solid #cccccc;
  align-items: center;
`;

export const FilterTitle = styled.div``;

export const FilterClose = styled.div`
  padding: 5px;
  cursor: pointer;
`;

export const FilterBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 12px;
`;

export const FilterFooter = styled.div`
  padding: 0 12px 12px;
`;

export const FilterFooterButton = styled.button`
  width: 100%;
  outline: none;
  border: none;
  color: #fff;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  border-radius: 3px;
  padding: 10px 0;
  cursor: pointer;
  background-color: #0072ce;
`;
