import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
// TODO
type ChooseColorState = {
  tabOption: number | 'SEARCH' | null | string;
  searchSeed: string;
};

const initialState: ChooseColorState = {
  tabOption: null,
  searchSeed: '',
};

export const chooseColorSlice = createSlice({
  name: 'chooseColor',
  initialState,
  reducers: {
    setTabOption: (
      state,
      action: PayloadAction<ChooseColorState['tabOption']>
    ) => {
      state.tabOption = action.payload;
    },
    setSearchSeed: (
      state,
      action: PayloadAction<ChooseColorState['searchSeed']>
    ) => {
      state.searchSeed = action.payload;
    },
    resetChooseColorState: () => initialState,
  },
});

//Export actions
export const { setSearchSeed, setTabOption, resetChooseColorState } =
  chooseColorSlice.actions;

//Definición de selectores
export const getTabOption = (state: RootState) => state.chooseColor.tabOption;

export const getSearchSeed = (state: RootState) => state.chooseColor.searchSeed;

export default chooseColorSlice.reducer;
