import styled from 'styled-components';

const GRADIENT_HEIGHT = '20px';

export const BoxStyled = styled.div`
  overflow: auto;
  position: relative;
`;

export const BoxContentStyled = styled.div`
  margin-top: calc(${GRADIENT_HEIGHT} * -1);
  margin-bottom: calc(${GRADIENT_HEIGHT} * -1);
`;

export const GradientStyled = styled.div`
  background: rgb(255, 255, 255);
  width: 100%;
  position: sticky;
  transition: all 0.25s ease-out;
  left: 0;
  z-index: 2;
  height: ${GRADIENT_HEIGHT};
  opacity: 0;

  &.show {
    opacity: 1;
  }

  &.top {
    top: 0;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  &.bottom {
    bottom: 0;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    z-index: auto;
  }

  &.left {
    left: 0;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    margin: 0 !important;
    padding-left: 20px;
    margin-right: -15px !important;
    height: 45px;
  }

  &.right {
    right: 0;
    background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    left: auto;
    margin: 0 !important;
    right: 0;
    padding-right: 20px;
    margin-left: -15px !important;
    height: 45px;
  }
`;
