import React from 'react';
import { PillButton } from '@digital-retail/journey-ui-kit';
import { useAppDispatch } from '../../../../app/hooks';
import {
  resetCalculatorQuantityState,
  setStep,
} from '../../../../redux/calculateQuantitySlice';

const ButtonGoToMain = () => {
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(setStep('initial'));
    dispatch(resetCalculatorQuantityState());
  };

  return (
    <PillButton
      size="md"
      variant="ghost"
      onClick={handleClick}
      data-testid="button-show-main"
      style={{
        fontWeight: 700,
        fontSize: '18px',
        lineHeight: '22px',
        letterSpacing: '0.16px',
        border: 'none',
        backgroundColor: 'transparent',
      }}
    >
      Volver
    </PillButton>
  );
};

export default ButtonGoToMain;
