import { IColor } from '../models/IColor';
import Cookies from 'js-cookie';
import { COOKIE_NAMES, MOBILE_POSITION_OFFSET, SOURCES } from './constants';
import { BREAKPOINTS } from './constants/breakpoints';

export const getBrandName = (brandId: string, availableBrands: any) => {
  return availableBrands.filter((brand: any) => brand.id === brandId)[0].name;
};

export const isDarkBackground = (bgColor: string) => {
  const [r, g, b] = bgColor.split(',').map(Number);
  const luma = 0.2126 * (r / 255) + 0.7152 * (g / 255) + 0.0722 * (b / 255);

  return luma > 0.5;
};

export const parseSkus = (colorData: IColor) => {
  const skus = colorData.skus || [];

  return skus.map((sku) => ({ skuId: sku }));
};

export const getSkuIds = (skus: any) => {
  return skus.map((sku: any) => sku.id);
};

export const mergeTwoArrays = (arr1: any, arr2: any) => {
  const arr1Obj = {};

  for (let i = 0; i < arr1.length; i++) {
    arr1Obj[arr1[i].id] = arr1[i];
  }

  for (let i = 0; i < arr2.length; i++) {
    if (arr2[i].id in arr1Obj) {
      arr1Obj[arr2[i].id] = {
        ...arr1Obj[arr2[i].id],
        ...arr2[i],
      };
    }
  }

  return Object.values(arr1Obj);
};

export const getUniqueBrigthnessTypes = (skus: any = []) => {
  const brightnessTypes = skus.map((sku: any) => sku.brightness);
  const uniqueBrightnessObj = {};

  for (let i = 0; i < brightnessTypes.length; i++) {
    const val = brightnessTypes[i];
    if (!(val in uniqueBrightnessObj)) {
      uniqueBrightnessObj[val] = true;
    }
  }

  return uniqueBrightnessObj;
};

export const formatSkusData = (skus: any) => {
  const output = skus.reduce((acc, item) => {
    const found = acc.find((group) => group.quality === item.quality);
    const { quality, ...rest } = item;
    if (found) {
      found.values.push({ ...rest, distribution: 0 });
    } else {
      acc.push({
        quality,
        values: [{ ...rest, distribution: 0 }],
      });
    }

    return acc;
  }, []);

  return output;
};

export const addSizesDataIntoSkusData = (
  skus: any,
  sizes: any,
  brandId: string,
  productDetails: any
) => {
  const mergedData = skus.map((group) => ({
    ...group,
    characteristics:
      productDetails.filter((detail: any) => {
        return detail.brandId === brandId && detail.qualityId === group.quality;
      })[0]?.characteristics || [],
    values: group.values.map((value) => {
      const match = sizes.find((entry) => entry.id === value.size);
      if (match) {
        return {
          ...value,
          size: match.size,
          sizeId: match.id,
          description: match.description,
          specifications: match.specifications,
        };
      }
      return value;
    }),
  }));

  return mergedData;
};

export const setCartIdCookie = (cartId: string) => {
  const cartIdCookie = Cookies.get(COOKIE_NAMES.CART_ID) || '';
  const isCardIdCookieExists = cartIdCookie !== '';
  const hostName = window.location.hostname || '';
  const topLeveldomain = hostName
    .split('.')
    .filter((item, index) => index > 0)
    .join('.');
  if (!isCardIdCookieExists) {
    Cookies.set(COOKIE_NAMES.CART_ID, cartId, {
      expires: 365,
      domain: `.${topLeveldomain}`,
    });
  }
};

export const resizeObserverHandler = (
  entries: any,
  currentPosition: number
) => {
  const width = window.innerWidth || 300;
  const isMobile = width < BREAKPOINTS.tablet;
  for (const entry of entries) {
    const height = entry.target?.offsetHeight || 0;
    const totalHeight = getOffSetHeightForMobile(
      height,
      currentPosition,
      isMobile
    );
    window.parent.postMessage({ iframeHeight: totalHeight }, '*');
  }
};

export const getOffSetHeightForMobile = (
  originalHeight: number,
  position: number,
  isMobile: boolean
) => {
  let offset = 0;
  if (isMobile) {
    offset = MOBILE_POSITION_OFFSET[position] || 800;
  }
  const finalHeight = originalHeight + offset + 'px';

  return finalHeight;
};

export const getCurrentSource = () => {
  const params = new URLSearchParams(window.location.search);
  const source = params.get(COOKIE_NAMES.SOURCE) || SOURCES.WEB;

  return source;
};

export const sendCartLinesToMobile = (cartLines) => {
  window.parent.dispatchEvent(
    new CustomEvent('addToCart', {
      detail: {
        cartLines,
      },
    })
  );
};
