import styled from 'styled-components';

export const NoSheenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 22px 16px 16px;
  font-family: Lato;
  font-style: normal;
  color: #333;
  font-size: 14px;
  line-height: 20px;
`;

export const IconWrapper = styled.div`
  transform: scale(1.6);
  & > svg path {
    fill: #efae30;
  }
  margin-bottom: 8px;
`;

export const NoSheenTitle = styled.div`
  margin-bottom: 4px;
  font-weight: 700;
`;

export const NoSheenDescription = styled.div`
  margin-bottom: 8px;
  font-weight: 400;
  text-align: center;
  p {
    font-size: 14px;
    margin: 0;
  }

  h2 {
    font-size: 16px !important;
    margin-bottom: 2px;
  }
`;

export const NoSheenButton = styled.button`
  width: 100%;
  outline: none;
  border: none;
  color: #fff;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  border-radius: 3px;
  padding: 10px 0;
  cursor: pointer;
  background-color: #0072ce;
`;
