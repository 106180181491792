import { IAvailabilityRequest } from '../../models/IAvailabilityRequest';
import { AVAILABILITY_SERVICE_URL, COOKIE_NAMES, SOURCES } from '../constants';
import Cookies from 'js-cookie';

export const getQueryParams = () => {
  let queryParams = {};
  let politicalId = '';
  let priceGroupId = '';
  let zones = '';
  const params = new URLSearchParams(window.location.search);
  const source = params.get(COOKIE_NAMES.SOURCE) || SOURCES.WEB;

  if (source === SOURCES.MOBILE_APP) {
    politicalId = params.get(COOKIE_NAMES.POLITICAL_ID) || '';
    priceGroupId = params.get(COOKIE_NAMES.PRICE_GROUP_Id) || '';
    zones = params.get(COOKIE_NAMES.ZONES) || '';
  } else {
    politicalId = Cookies.get(COOKIE_NAMES.POLITICAL_ID) || '';
    priceGroupId = Cookies.get(COOKIE_NAMES.PRICE_GROUP_Id) || '';
    zones = Cookies.get(COOKIE_NAMES.ZONES) || '';
  }

  if (politicalId) {
    queryParams = { ...queryParams, politicalId };
  }
  if (priceGroupId) {
    queryParams = { ...queryParams, priceGroupId };
  }
  if (zones) {
    queryParams = { ...queryParams, zones };
  }

  return queryParams;
};

export const getFinalUrl = (url, queryParams) => {
  const encodedParams = Object.entries(queryParams)
    .map(([key, value]) => `"${key}":${JSON.stringify(value)}`)
    .join(',');

  return `${url}?{${encodedParams.replace(/"/g, '%22')}}=null`;
};

export const getAvailabilityUrl = (props: IAvailabilityRequest) => {
  const { REACT_APP_ENVIRONMENT = 'production' } = process.env;
  const { tenant, products } = props;
  const businessUnit = tenant.substring(0, 2).toUpperCase();
  const shortTenant = tenant.substring(2).toLowerCase();
  const availabilityServiceUrl =
    AVAILABILITY_SERVICE_URL[REACT_APP_ENVIRONMENT][businessUnit];
  const finalUrl = `${availabilityServiceUrl}/${shortTenant}`;
  const queryParams: any = { products, ...getQueryParams() };

  return getFinalUrl(finalUrl, queryParams);
};
