/* istanbul ignore file */
import { memo, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getCurrenPosition, setPosition } from '../../redux/stepperSlice';
import { StepperWrapperStyled } from './styles';
import { Stepper } from '@digital-retail/journey-ui-kit';
import { views } from '../../features/Route/views';
import { getColorStatus } from '../../redux/colorsSlice';
import useResetNextSteps from '../../hooks/useResetNextSteps';
import BackButton from '../BackButton';

const StepperWrapper = () => {
  const dispatch = useAppDispatch();
  const position = useAppSelector(getCurrenPosition);
  const colorStatus = useAppSelector(getColorStatus);
  const { resetNextSteps } = useResetNextSteps();
  const [backButtonLabel, setBackButtonLabel] = useState('');

  const idDisabled = colorStatus === 'loading';

  const comeBackTo = (newPosition: number) => {
    if (!idDisabled && newPosition >= 0) {
      dispatch(setPosition(newPosition));
      if (newPosition < position) {
        resetNextSteps(newPosition);
      }
    }
  };

  const handlePreviousButtonClick = () => {
    comeBackTo(position - 1);
  };

  useEffect(() => {
    const label = views[position].backButtonLabel;
    setBackButtonLabel(label);
  }, [position]);

  return (
    <StepperWrapperStyled data-testid="stepper">
      <Stepper
        className={`${idDisabled ? 'disabled' : ''}`}
        currentStep={position}
        titles={views.map((item) => item.mobileTitle)}
        onClick={(stepIndex: number) => comeBackTo(stepIndex)}
        style={{ margin: 'auto' }}
      />
      {backButtonLabel != '' && (
        <BackButton onClick={handlePreviousButtonClick}>
          {backButtonLabel}
        </BackButton>
      )}
    </StepperWrapperStyled>
  );
};

export default memo(StepperWrapper);
