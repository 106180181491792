import styled from 'styled-components';
import { mediaQuery } from '../../../utils';

export const HomeWrapper = styled.div`
  background-color: #fff;
  padding: 0;
  height: 100vh;
  overflow-y: auto;
`;

export const HeaderWrapper = styled.div`
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  ${mediaQuery.mobileOnly(`
    z-index: 6;
    background-color: #fff;
  `)}
`;

export const ContainerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  ${mediaQuery.mobileOnly(`
    flex-direction: column;
  `)}
`;

export const MainWrapper = styled.div`
  display: grid;
  flex: 1 1 0%;
  ${mediaQuery.mobileOnly(`
    position: sticky;
    top: 0;
    width: 100%;
    height: fit-content;
    z-index: 3;
  `)}
`;

export const SideBarWrapper = styled.div`
  height: auto;

  ${mediaQuery.mobileOnly(`
    bottom: 0;
    left: 0;
    width: 100%;
    min-height: 0px;
  `)}
`;
