/* istanbul ignore file */
type IDynamicContent = {
  [index: string]: {
    buttonPill: boolean;
    buttonSeeMore: string;
    buttonGoToCart: string;
    themeUiKit: string;
  };
};
export const DYNAMIC_CONTENT: IDynamicContent = {
  socl: {
    buttonPill: false,
    buttonSeeMore: 'Ver más productos',
    buttonGoToCart: 'Ir al carro de compras',
    themeUiKit: 'sodimac',
  },
  facl: {
    buttonPill: true,
    buttonSeeMore: 'Seguir comprando',
    buttonGoToCart: 'Ir al carro',
    themeUiKit: 'falabella',
  },
} as const;
