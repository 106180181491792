import React, { ReactNode } from 'react';
import { RadioWrapper, RadioButton } from './styles';

interface RadioSelectProps {
  value: string;
  extraValue?: string;
  currentValue: string;
  blocked: boolean;
  children: ReactNode;
  onSelect?: any;
  middleCenter?: boolean;
}

const defaultProps: Pick<RadioSelectProps, 'blocked'> = {
  blocked: false,
};

const RadioSelect = ({
  blocked,
  value,
  extraValue,
  currentValue,
  onSelect,
  middleCenter,
  children,
}: RadioSelectProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    !blocked && onSelect(value, extraValue);
  };

  return (
    <RadioWrapper blocked={blocked} middleCenter={middleCenter}>
      <RadioButton
        type="radio"
        value={value}
        checked={currentValue === value}
        onChange={(event) => handleChange(event)}
        blocked={blocked}
      />
      <>{children}</>
    </RadioWrapper>
  );
};

RadioSelect.defaultProps = defaultProps;

export default RadioSelect;
