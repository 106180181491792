import styled from 'styled-components';
import { mediaQuery } from '../../../utils';

export const HeaderColorWrapper = styled.div``;

export const ColorDescriptionWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: calc(100% - 30pt);
  padding-left: 15pt;
  padding-top: 25pt;
  padding-right: 15pt;
  display: flex;
  flex-direction: row;

  ${mediaQuery.mobileOnly(`
    flex-direction: column;
  `)}
`;

export const ColorWrapper = styled.div``;

export const InformationBoxWrapper = styled.div`
  position: absolute;
  color: #000;
  background-color: #fff;
  border-radius: 3px;
  padding: 10px 10px 15px 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  width: auto;

  ${mediaQuery.mobileOnly(`
    display: none;
  `)}

  ${mediaQuery.tabletToUp(`
    right: 20px;
    bottom: 65px;
    width: 512px;
  `)}
`;

export const NameWrapper = styled.div`
  font-weight: 700;
  margin-bottom: 5px;

  ${mediaQuery.mobileOnly(`
    font-size: 14px;
    line-height: 18px;
  `)}
`;

export const DescriptionWrapper = styled.div`
  ${mediaQuery.mobileOnly(`
    font-size: 12px;
    line-height: 16px;
  `)}
`;
