/* istanbul ignore file */

type CustomFetchProps = {
  url: RequestInfo;
  params?: RequestInit;
  retries?: number;
};

export const customFetch = async <T>({
  url,
  params,
  retries = 2,
}: CustomFetchProps): Promise<T> =>
  fetch(url, params)
    .then(async (response) => {
      if (response.ok) {
        const data = await response.json();
        return data;
      }

      if (retries && retries > 0) {
        return customFetch({ url, params, retries: retries - 1 });
      }

      return Promise.reject(response.status);
    })
    .catch((error) => {
      throw new Error(error);
    });
