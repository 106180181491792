import MediaComponent from '../../../../components/MediaComponent';
import { IRoom } from '../../../../models/IRoom';
import { LabelRoomWrapper, RoomWrapper } from './styles';

export type RoomProps = {
  room: IRoom;
  onClick?: () => void;
};

export type RoomForMediaComponentProps = {
  image: string;
} & Pick<IRoom, 'gridArea'>;

const Room: React.FC<RoomProps> = ({ room, onClick }) => (
  <>
    <MediaComponent from="mobile" to="tablet">
      <RoomWrapper
        image={room.galleryImage.mobile}
        gridArea={room.gridArea}
        onClick={onClick}
      >
        <LabelRoomWrapper>{room.name}</LabelRoomWrapper>
      </RoomWrapper>
    </MediaComponent>
    <MediaComponent from="tablet">
      <RoomWrapper
        image={room.galleryImage.desktop}
        gridArea={room.gridArea}
        onClick={onClick}
      >
        <LabelRoomWrapper>{room.name}</LabelRoomWrapper>
      </RoomWrapper>
    </MediaComponent>
  </>
);

export default Room;
