import { memo, FC } from 'react';
import { useAppSelector } from '../../app/hooks';
import { useWindowSize } from '../../hooks/useWindowSize';
import { getSelectedRoom } from '../../redux/roomSlice';
import { BREAKPOINTS } from '../../utils/constants/breakpoints';

import { RoomImageWrapper } from './styles';

const RoomImage: FC = () => {
  const size = useWindowSize();
  const room = useAppSelector(getSelectedRoom);

  return (
    <RoomImageWrapper
      src={
        size.width && size.width > BREAKPOINTS.tablet
          ? room.paintableImage.desktop
          : room.paintableImage.mobile
      }
      alt={room.name}
    />
  );
};

export default memo(RoomImage);
