import MediaComponent from '../../../../components/MediaComponent';
import FilterMenuDesktop from './Desktop';
import FilterMenuMobile from './Mobile';

const FilterMenu = () => {
  return (
    <>
      <MediaComponent from="tablet">
        <FilterMenuDesktop />
      </MediaComponent>
      <MediaComponent from="mobile" to="tablet">
        <FilterMenuMobile />
      </MediaComponent>
    </>
  );
};

export default FilterMenu;
