import React from 'react';
import MediaComponent from '../../components/MediaComponent';
import QualitiesAndQuantitiesDesktop from './components/QualitiesAndQuantities/Desktop';
import { Wrapper } from './styles';
import useQualitiesAndQuantities from './hooks/useQualitiesAndQuantities';
import QualitiesAndQuantitiesMobile from './components/QualitiesAndQuantities/Mobile';
import LoaderScreen from '../../components/LoaderScreen';
import { useAppSelector } from '../../app/hooks';
import { getIsCartAddTostatus } from '../../redux/settingQualitySlice';
import ModalAddToCart from './components/ModalAddToCart';

const QualitiesAndQuantities: React.FC = () => {
  const {
    qualitiesAndQuantitiesData,
    handleQuantityChange,
    handleClickPreviousPosition,
  } = useQualitiesAndQuantities();
  const isAddToCartStatus = useAppSelector(getIsCartAddTostatus);

  if (!qualitiesAndQuantitiesData) return null;

  return (
    <Wrapper>
      <MediaComponent from="tablet">
        <QualitiesAndQuantitiesDesktop
          qualitiesAndQuantitiesData={qualitiesAndQuantitiesData}
          handleQuantityChange={handleQuantityChange}
          handleClickPreviousPosition={handleClickPreviousPosition}
        />
      </MediaComponent>
      <MediaComponent from="mobile" to="tablet">
        <QualitiesAndQuantitiesMobile
          qualitiesAndQuantitiesData={qualitiesAndQuantitiesData}
          handleQuantityChange={handleQuantityChange}
        />
      </MediaComponent>
      {isAddToCartStatus === 'loading' && <LoaderScreen />}
      <ModalAddToCart />
    </Wrapper>
  );
};

export default QualitiesAndQuantities;
