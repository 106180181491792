import styled from 'styled-components';

export const MobileWrapper = styled.div`
  border-radius: 3px 0px 0px 3px;
  border: 1px solid #ccc;
  background: #fff;
  flex-direction: column;
  padding: 16px 0 0 16px;
  display: flex;
`;

export const PageTitle = styled.div`
  color: #333;
  font-family: Lato;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 16px;
`;

export const CardsContainer = styled.div`
  display: flex;
  gap: 24px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  width: 100%;
`;

export const CardsWrapper = styled.div`
  position: relative;
`;

export const LeftShadow = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 30px;
  z-index: 100;
  pointer-events: none;
  left: 0;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
  display: block;
`;

export const RightShadow = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 30px;
  z-index: 100;
  pointer-events: none;
  right: 0;
  background-image: linear-gradient(
    to left,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
  display: block;
`;
