import { useAppSelector } from '../../../../app/hooks';
import { IColor } from '../../../../models/IColor';
import { getBrands } from '../../../../redux/configSlice';
import { camelCase } from '../../../../utils/camelCase';
import { getBrandName } from '../../../../utils/commonUtils';
import rgbToHex from '../../../../utils/rgbToHex';
import { ColorCard, ColorGrid, ColorWrapper } from './styles';

type ColorPickerProps = {
  colors: IColor[];
  selectedColor: IColor[] | any;
  onSelectColor: (e: any, color: IColor, colorIndex: number) => void; //eslint-disable-line no-unused-vars
};
export const ColorPicker = ({
  colors,
  selectedColor,
  onSelectColor,
}: ColorPickerProps) => {
  const availableBrands = useAppSelector(getBrands);

  return (
    <ColorGrid>
      {colors.map((color, colorIndex: number) => (
        <ColorCard
          key={colorIndex}
          selected={selectedColor.id == color.id}
          onClick={(e) => onSelectColor(e, color, colorIndex)}
        >
          <ColorWrapper background={rgbToHex(color.rgb)} />
          <p>
            <strong>{camelCase(color.name)}</strong>
          </p>
          <p>{getBrandName(color.brand, availableBrands)}</p>
          <p>{color.id}</p>
        </ColorCard>
      ))}
    </ColorGrid>
  );
};
