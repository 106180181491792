import { defaultSelectedColor } from '../../../__mocks__/defaults';
import { useAppDispatch } from '../../../app/hooks';
import {
  setConfirmedColor,
  setSelectedColor,
  setSelectedColorIndex,
  setVisibleSheenOptions,
} from '../../../redux/colorsSlice';

const useNoSheens = () => {
  const dispatch = useAppDispatch();

  const handleClickClose = () => {
    dispatch(setVisibleSheenOptions(false));
    dispatch(setSelectedColor(defaultSelectedColor));
    dispatch(setConfirmedColor(false));
    dispatch(setSelectedColorIndex(-1));
  };

  return {
    handleClickClose,
  };
};

export default useNoSheens;
