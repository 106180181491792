import styled from 'styled-components';
import { mediaQuery } from '../../../../../utils';
import { COLORS } from '../../../../../utils/constants/colors';

export const SidebarWrapper = styled.div`
  ${mediaQuery.mobileOnly(`
    background-color: #ffffff;
  `)}
`;

export const SidebarMobileWrapper = styled.div``;

export const SidebarDesktopWrapper = styled.div`
  padding: 30px 20px;
  border: 1px solid #cccccc;
  border-radius: 3px 0 0 3px;
  height: 100%;
  width: 578px;
  min-height: 458px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const LabelWrapper = styled.div`
  font-size: 29px;
  margin-bottom: 20px;
  color: #333333;
  align-self: flex-start;
`;

export const LabelMobileWrapper = styled.div`
  font-size: 16px;
  padding: 10px;
  color: #333333;
  text-align: center;
`;

export const VolverButton = styled.button`
  background: none;
  border: none;
  color: ${COLORS.primary};
  font-size: 18px;
  font-style: normal;
  line-height: 22px;
  cursor: pointer;
  margin-top: 30px;
  font-weight: 700;
`;
