import { useState } from 'react';
import { IColorRequest } from '../../../models/IColorsRequest';
import {
  fetchColorsAsync,
  getOpenFilterMenu,
  getSelectedBrands,
  resetSelectedOptions,
  setOpenFilterMenu,
  setSelectedBrands,
} from '../../../redux/colorsSlice';
import { REPLACE } from '../../../utils/constants';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { getBrands, getTenant } from '../../../redux/configSlice';
import { getSearchSeed, getTabOption } from '../../../redux/chooseColorSlice';

const useFilterMenu = () => {
  const dispatch = useAppDispatch();
  const brands = useAppSelector(getBrands);
  const selectedBrands = useAppSelector(getSelectedBrands);
  const currentTenant = useAppSelector(getTenant);
  const colorFamilyId = useAppSelector(getTabOption) as string;
  const searchSeed = useAppSelector(getSearchSeed);
  const [checkedBrands, updateCheckedBrands] =
    useState<string[]>(selectedBrands);
  const openFilterMenu = useAppSelector(getOpenFilterMenu);

  let startY = 0;
  const threshold = 20;

  const handleTouchStart = (e) => {
    startY = e.touches[0].clientY;
  };

  const handleTouchEnd = (e) => {
    const endY = e.changedTouches[0].clientY;
    if (endY - startY > threshold) {
      dispatch(setOpenFilterMenu(false));
    }
  };

  const handleOnClose = () => {
    dispatch(setOpenFilterMenu(false));
  };

  const handleOnSave = (updatedBrands) => {
    const brands = updatedBrands;
    dispatch(resetSelectedOptions()); // If we scrolled down, we need to reset the list
    dispatch(setSelectedBrands(brands));
    dispatch(setOpenFilterMenu(false));

    if (colorFamilyId) {
      let props: IColorRequest = {
        tenant: currentTenant,
        brand: brands.join(','),
        mode: REPLACE,
        group: colorFamilyId,
      };
      if (colorFamilyId === 'SEARCH') {
        props = {
          ...props,
          group: '',
          name: searchSeed,
        };
      }
      dispatch(fetchColorsAsync(props));
    }
  };

  const handleOnCheckBoxUpdate = (event: any, brandId: string) => {
    const isChecked = event.target.checked;
    let updatedCheckBrands = [...checkedBrands];
    if (isChecked) {
      updatedCheckBrands = [...checkedBrands, brandId];
    }
    if (!isChecked && checkedBrands.length !== 1) {
      updatedCheckBrands = checkedBrands.filter((item) => item !== brandId);
    }
    updateCheckedBrands(updatedCheckBrands);
  };

  const updateBrands = (event: any, brandId: string) => {
    const isChecked = event.target.checked;
    let updatedCheckBrands = [...checkedBrands];
    if (isChecked) {
      updatedCheckBrands = [...checkedBrands, brandId];
    }
    if (!isChecked && checkedBrands.length !== 1) {
      updatedCheckBrands = checkedBrands.filter((item) => item !== brandId);
    }
    updateCheckedBrands(updatedCheckBrands);
    handleOnSave(updatedCheckBrands);
  };

  return {
    brands,
    checkedBrands,
    openFilterMenu,
    handleOnClose,
    handleOnSave,
    handleOnCheckBoxUpdate,
    handleTouchEnd,
    handleTouchStart,
    updateBrands,
  };
};

export default useFilterMenu;
