import styled from 'styled-components';
import { mediaQuery } from '../../../../../utils';
import { COLORS } from '../../../../../utils/constants/colors';

export const CalculateMetersFormWrapper = styled.div`
  padding: 10px 15px;
  background-color: #f5f5f5;
  margin: 8px;
  border-radius: 3px;

  ${mediaQuery.tabletToUp(`
    padding: 30px;
    margin-bottom: 20px;
    margin-top: 21px;
  `)}
`;

export const LabelWrapper = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
  height: 20px;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
`;

export const IndicationInput = styled.p`
  color: #333333;
  font-family: Lato;
  font-size: 12px;
  line-height: 16px;
  width: 131px;
  text-align: left;
  margin: 1px 0;
`;

export const NumberOfLayersInputWrapper = styled.div`
  width: 140px;
`;

export const FormWrapper = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: max-content max-content;
  gap: 10px;
  grid-template-areas:
    'squareMeter layersOfPaints'
    'result result';
  margin-bottom: 20px;

  ${mediaQuery.tabletToUp(`
  grid-template-columns: 160px 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'squareMeter layersOfPaints result';
  `)}
`;

export const SquareMeterWrapper = styled.div`
  grid-area: squareMeter;
`;

export const LayersOfPaintsWrapper = styled.div`
  grid-area: layersOfPaints;
`;

export const ResultWrapper = styled.div`
  grid-area: result;
  background-color: #f5f5f5;
  padding: 11px 15px;
  align-items: center;
  border-radius: 3px;

  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: 65px 1fr;
  grid-template-rows: 1fr;
  gap: 0 0;
  grid-template-areas: 'resultLabel resultValue';

  ${mediaQuery.tabletToUp(`
    padding: 0;
    grid-template-columns: 1fr;
    grid-template-rows: max-content max-content;
    grid-template-areas:
      'resultLabel'
      'resultValue';
  `)}
`;

export const ResultLabelWrapper = styled.div`
  grid-area: resultLabel;

  font-size: 14px;
  font-weight: bold;

  ${mediaQuery.tabletToUp(`
    margin-bottom: 9px;
  `)}
`;

export const ResultValueWrapper = styled.div`
  grid-area: resultValue;
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
`;

export const NumberOfGallons = styled.span`
  font-size: 29px;
  font-weight: bold;
  text-align: right;
  padding-left: 10px;

  ${mediaQuery.tabletToUp(`
    font-size: 29px;
    line-height: 42px;
    padding-left: 0;
  `)}
`;

export const UnitOfMeasurementWrapper = styled.span`
  font-size: 20px;
  padding-left: 6px;
`;

export const ActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  margin-bottom: 15px;
  width: fit-content;

  span {
    color: ${COLORS.primary};
  }

  & > * {
    cursor: pointer;
  }
`;

export const HelpBlockWrapper = styled.div`
  color: #8c8c8c;
  font-size: 12px;
  margin-bottom: 15px;
`;

export const InlineIcon = styled.img`
  height: 20px;
  width: 20px;
  margin-bottom: -3px;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
`;

export const IconInfoButtonWrapper = styled.button`
  border: 0;
  text-align: center;
  align-self: center;
  width: fit-content;
  background-color: transparent;
  cursor: pointer;
`;

export const PopoverContent = styled.div`
  & .title {
    text-align: left;
    margin-top: 0;
    white-space: break-spaces;
  }

  & .description {
    font-weight: 400;
    text-align: left;
    font-size: 12px;
    white-space: break-spaces;
  }
`;
