import styled from 'styled-components';

export const MobileActionsBarWrapper = styled.div`
  border-top: 1px solid #bfbfbf;
  padding: 10px 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;
