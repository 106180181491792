/* istanbul ignore file */

import { customFetch } from '../../utils/customFetch';
import URL from '../url';
import { IColorRequest } from '../../models/IColorsRequest';
import {
  IAvailabilityRequest,
  IAvailabilityResponse,
} from '../../models/IAvailabilityRequest';
import { ISkusRequest, ISkusResponse } from '../../models/ISkusRequest';

export const fetchColors = (props: IColorRequest) => {
  const { url, params } = URL.fetchColors(props);
  return customFetch({ url, params });
};

export const fetchAvailableSkus = async (
  props: IAvailabilityRequest
): Promise<IAvailabilityResponse> => {
  const { url, params } = URL.fetchAvailability(props);
  return customFetch({ url, params });
};

export const fetchSkusData = async (
  props: ISkusRequest
): Promise<ISkusResponse> => {
  const { url, params } = URL.fetchSkusData(props);
  return customFetch({ url, params });
};
