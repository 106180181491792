import styled from 'styled-components';

export const FormCoatWrapper = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr;
  grid-template-rows: max-content 1fr;
  gap: 0;
  grid-template-areas:
    'title'
    'options';
  padding-bottom: 13px;
`;

export const TitleWrapper = styled.div`
  grid-area: title;
  padding: 10px;
  border-bottom: 1px solid #cccccc;
  font-weight: bold;
`;

export const OptionsWrapper = styled.div`
  grid-area: options;
  padding-left: 5px;
`;

export const ViewWrapper = styled.div`
  grid-area: view;
  align-self: center;
  height: 94px;
  width: 98px;

  img {
    width: 100%;
  }
`;

export const CloseButtonStyled = styled.div`
  position: absolute;
  right: 16px;
  top: 17px;
  width: 10px;
  height: 10px;
  cursor: pointer;

  svg {
    display: block;
  }
`;
