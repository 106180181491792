import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { ChooseColorWrapper } from './styles';
import {
  getSelectedColor,
  getColorStatus,
  setVisibleSheenOptions,
} from '../../redux/colorsSlice';
import RoomView from './components/RoomView';
import { getSelectedRoom } from '../../redux/roomSlice';
import rgbToHex from '../../utils/rgbToHex';

const ChooseColor: React.FC = () => {
  const dispatch = useAppDispatch();
  const room = useAppSelector(getSelectedRoom);
  const selectedColor = useAppSelector(getSelectedColor);
  const colorStatus = useAppSelector(getColorStatus);

  const handleClickCoatMenu = () => dispatch(setVisibleSheenOptions(false));

  return (
    <ChooseColorWrapper data-testid="choose-color">
      <RoomView
        onClick={() => handleClickCoatMenu()}
        backgroundColor={
          colorStatus == 'loading'
            ? rgbToHex(selectedColor.rgb) || room.initColor
            : rgbToHex(selectedColor.rgb) || '#ffffff'
        }
      />
    </ChooseColorWrapper>
  );
};

export default ChooseColor;
