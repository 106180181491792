import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchGallons } from '../../api/fetchGallons';
import { RootState } from '../../app/store';
import { IGallon } from '../../models/IGallon';
import { IStatusAsyncThunk } from '../../models/IStatusAsyncThunk';

interface CalculateQuantityState {
  status: IStatusAsyncThunk;
  value: IGallon[];
}

const initialState: CalculateQuantityState = {
  status: 'idle',
  value: [],
};

export const fetchGallonsAsync = createAsyncThunk(
  'gallons/fetchGallonsAsync',
  async () => {
    const response = await fetchGallons();
    return response;
  }
);

export const gallonSlice = createSlice({
  name: 'gallons',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGallonsAsync.pending, (state) => {
        state.status = 'loading';
        state.value = [];
      })
      .addCase(fetchGallonsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      })
      .addCase(fetchGallonsAsync.rejected, (state) => {
        state.status = 'failed';
        state.value = [];
      });
  },
});

export const getGallons = (state: RootState) => state.gallons.value;
export const getGallonsStatus = (state: RootState) => state.gallons.status;

export default gallonSlice.reducer;
