import React from 'react';

import { getStep } from '../../../../redux/calculateQuantitySlice';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import CalculateMeters from '../CalculateMeters';
import CalculationOptions from '../CalculationOptions';
import MediaComponent from '../../../../components/MediaComponent';
import {
  LabelWrapper,
  SidebarDesktopWrapper,
  SidebarMobileWrapper,
  SidebarWrapper,
  LabelMobileWrapper,
  VolverButton,
} from './styles';
import MobileActionsBarSidebar from '../MobileActionsBarSidebar';
import { previousPosition } from '../../../../redux/stepperSlice';

const Sidebar: React.FC = () => {
  const step = useAppSelector(getStep);
  const dispatch = useAppDispatch();

  const handleClickPreviousPosition = () => {
    dispatch(previousPosition());
  };

  return (
    <SidebarWrapper data-testid="sidebar">
      <MediaComponent from="mobile" to="tablet">
        <SidebarMobileWrapper>
          {step === 'initial' && (
            <>
              <LabelMobileWrapper>
                Elige <strong>cómo calcular</strong> cuanta pintura necesitas
              </LabelMobileWrapper>
              <CalculationOptions />
              <MobileActionsBarSidebar />
            </>
          )}
          {step === 'calculateMeters' && <CalculateMeters />}
        </SidebarMobileWrapper>
      </MediaComponent>
      <MediaComponent from="tablet">
        <SidebarDesktopWrapper>
          {step === 'initial' && (
            <>
              <LabelWrapper>
                Elige <strong>cómo calcular</strong> cuanta pintura necesitas
              </LabelWrapper>
              <CalculationOptions />
              <VolverButton onClick={handleClickPreviousPosition}>
                Volver
              </VolverButton>
            </>
          )}
          {step === 'calculateMeters' && <CalculateMeters />}
        </SidebarDesktopWrapper>
      </MediaComponent>
    </SidebarWrapper>
  );
};

export default Sidebar;
