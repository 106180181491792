import styled from 'styled-components';

export const FilterFooterButton = styled.button`
  width: 100%;
  outline: none;
  border: none;
  color: #fff;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  border-radius: 3px;
  padding: 10px 0;
  cursor: pointer;
  background-color: #0072ce;
`;

export const FilterBottomSheetWrapper = styled.div<{ isOpen: boolean }>`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 300;
  background-color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 4px 16px 16px;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  transition: transform 0.5s ease;
  transform: translateY(${(props) => (props.isOpen ? '0' : '100%')});
`;
export const DragLineWrapper = styled.div`
  width: 40px;
  height: 40px;
  background-color: transparent;
  margin: 0 auto;
  display: inline-block;
  position: absolute;
  left: 45%;
`;

export const DragLine = styled.div`
  width: 40px;
  height: 4px;
  background-color: #c4c4c4;
  border-radius: 10px;
`;

export const CloseIconWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 6px;
  margin-top: 8px;
`;

export const FilterBottomSheetHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #333;
  font-family: Lato;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 24px;
`;

export const MobileFilterBody = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: space-around;
  padding: 10px;
`;
