import styled from 'styled-components';
import { mediaQuery } from '../../../../../utils';

export const CalculateMetersWrapper = styled.div``;

export const TitleWrapper = styled.div`
  font-size: 29px;
  margin-bottom: 20px;
  color: #333333;

  ${mediaQuery.mobileOnly(`
    padding: 15px;
    padding-bottom: 0px;
    font-size: 14px;
    margin-bottom: 10px;
  `)}
`;
