import styled from 'styled-components';
import { ILoadingProp } from '../../../../../models/ILoadingProp';
import { TitleMenuProps } from '../../TitleMenu';

export const OptionStyled = styled.button<ILoadingProp>`
  padding: 0;
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom: 1px solid #cccccc;
  display: inline-flex;
  width: 100%;
  background-color: inherit;
  align-items: center;
  cursor: ${({ loading }) =>
    loading == 'loading' ? 'not-allowed' : 'pointer'};
`;

export const OptionIconStyled = styled.div`
  padding: 20px 0 20px 20px;
  width: 32px;
`;

export const OptionTextStyled = styled.p<TitleMenuProps>`
  margin: 0;
  padding: 20px 14px;
  color: ${({ loading }) => (loading == 'loading' ? '#8C8C8C' : '#333333')};
  font-size: 17px;
  font-weight: 400;
`;
