import { IPaintsConfig } from '../../models/IPaintsConfig';

export const defaultpaintsConfig: IPaintsConfig = {
  brands: [
    {
      name: 'Kolor',
      id: '1',
    },
    {
      name: 'Ceresita',
      id: '2',
    },
    {
      name: 'Sipa',
      id: '3',
    },
  ],
  qualityTypes: [
    {
      name: 'Estandar',
      id: '1',
    },
    {
      name: 'Premium',
      id: '2',
    },
  ],
  sizes: [
    {
      id: '1',
      size: '0.25',
      description: '1/4 Galón',
      specifications: ['14 cm de alto', 'Rinde 9.5 m² con una capa.'],
    },
    {
      id: '2',
      size: '1',
      description: '1 Galón',
      specifications: ['21 cm de alto', 'Rinde 38 m² con una capa.'],
    },
    {
      id: '3',
      size: '2',
      description: '2 Galones',
      specifications: ['22 cm de alto', 'Rinde 76 m² con una capa.'],
    },
    {
      id: '4',
      size: '4',
      description: '4 Galones',
      specifications: ['36 cm de alto', 'Rinde 160 m² con una capa.'],
    },
  ],
  brightnessTypes: [
    {
      name: 'Mate',
      id: '1',
      description: '',
    },
    {
      name: 'Satinado',
      id: '2',
      description: '',
    },
    {
      name: 'Semi Brillo',
      id: '3',
      description: '',
    },
  ],
  types: [
    {
      name: 'Esmalte',
      id: '1',
    },
    {
      name: 'Latex',
      id: '2',
    },
  ],
  colorGroups: [
    {
      name: 'Amarillo',
      id: '1',
      featuredColors: ['#FCED65', '#F0E473', '#FFF6A8'],
    },
    {
      name: 'Azul',
      id: '2',
      featuredColors: ['#4980E4', '#93B3ED', '#D2DFF8'],
    },
    {
      name: 'Morado',
      id: '3',
      featuredColors: ['#8971CE', '#A08DD8', '#D0C6EC'],
    },
    {
      name: 'Naranja',
      id: '4',
      featuredColors: ['#FC9905', '#FCB03E', '#FFD18B'],
    },
    {
      name: 'Negro',
      id: '5',
      featuredColors: ['#141414', '#343434', '#4B4B4B'],
    },
    {
      name: 'Pasteles',
      id: '6',
      featuredColors: ['#A4DCEE', '#FFD2C8', '#D2BEDB'],
    },
    {
      name: 'Rojo',
      id: '7',
      featuredColors: ['#F01405', '#FA473B', '#FF776D'],
    },
    {
      name: 'Verde',
      id: '8',
      featuredColors: ['#4EB556', '#6FCD77', '#9CE4A2'],
    },
  ],
  productDetails: [
    {
      brandId: '1',
      qualityId: '1',
      characteristics: [
        '10 años de garantía.',
        'Lavable, resistente a hongos.',
        'Resiste el clima y las manchas.',
        'Poder cubriente. Usar imprimante.',
      ],
    },
    {
      brandId: '1',
      qualityId: '2',
      characteristics: [
        '15 años de garantía',
        'Resistente a manchas y hongos.',
        'Lavable.',
        'Poder cubriente. Usar imprimante.',
      ],
    },
    {
      brandId: '2',
      qualityId: '1',
      characteristics: [
        'Resiste el clima y las manchas.',
        'Lavable, resistente a hongos.',
        'Poder cubriente. Usar imprimante.',
      ],
    },
    {
      brandId: '3',
      qualityId: '1',
      characteristics: [
        'Resiste el clima y las manchas.',
        'Lavable, resistente a hongos.',
        'Poder cubriente. Usar imprimante.',
      ],
    },
  ],
  codeRegex: '[0-9]',
};
