import styled from 'styled-components';
import { mediaQuery } from '../../../../../utils';

export const ModalCalculateSquareMetersWrapper = styled.div``;

export const TitleWrapper = styled.div`
  color: #333333;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 30px;

  ${mediaQuery.mobileOnly(`
    font-size: 17px;
    margin-bottom: 15px;
  `)}
`;

export const HeightInputWrapper = styled.div`
  grid-area: heightInput;
  width: 100%;
  max-width: 140px;

  ${mediaQuery.tabletToUp(`
    white-space: pre;
  `)}
`;

export const LongInputWrapper = styled.div`
  grid-area: longInput;
  width: 100%;
  max-width: 140px;

  ${mediaQuery.tabletToUp(`
    white-space: pre;
  `)}
`;

export const NumberOfDoorsInputWrapper = styled.div`
  grid-area: numberOfDoorsInput;
  width: 100%;
  max-width: 140px;
`;

export const NumberOfWindowsInputWrapper = styled.div`
  grid-area: numberOfWindowsInput;
  width: 100%;
  max-width: 140px;
`;

export const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  margin-bottom: 10px;
  width: max-content;

  span {
    color: #333333;
    font-size: 14px;
    font-weight: bold;
    padding-left: 2px;
  }
`;

export const FormWrapper = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 200px 1fr;
  grid-template-rows: 1fr;
  gap: 15px;
  grid-template-areas:
    'form graphicHelp'
    'textHelp textHelp'
    'result actions';

  ${mediaQuery.mobileOnly(`
    grid-template-columns: 1fr;
    grid-template-rows: max-content;

    grid-template-areas:
      'graphicHelp'
      'form'
      'textHelp'
      'result'
      'actions';
  `)}
`;

export const FormCalculateSquareMetersWrapper = styled.div`
  grid-area: form;
  align-self: start;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content;
  gap: 30px;
  grid-template-areas:
    'heightInput'
    'longInput'
    'numberOfDoorsInput'
    'numberOfWindowsInput';

  ${mediaQuery.mobileOnly(`
    gap: 15px;
    grid-template-areas:
      'heightInput longInput'
      'numberOfDoorsInput numberOfWindowsInput';
  `)}
`;

export const GraphicHelpWrapper = styled.div`
  grid-area: graphicHelp;
  align-self: center;
  text-align: center;

  ${mediaQuery.mobileOnly(`
    svg {
      width: 206px;
      height: 100%;
    }
  `)}
`;

export const TextHelpWrapper = styled.div`
  font-size: 12px;
  font-style: italic;
  color: #333333;
  grid-area: textHelp;
`;

export const ActionsWrapper = styled.div`
  grid-area: actions;

  ${mediaQuery.mobileOnly(`
    &  * {
      width: 100%;
    }
  `)}

  ${mediaQuery.tabletToUp(`
    justify-self: end;
    align-self: end;
  `)}
`;

export const AlertWrapper = styled.div`
  grid-area: result;
`;

export const ResultContainerWrapper = styled.div`
  grid-area: result;
  height: 70px;
  background-color: #f5f5f5;
  padding: 10px 15px;
  color: #333333;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content;
  grid-template-areas:
    'resultLabel'
    'resultValue';

  ${mediaQuery.mobileOnly(`
    align-items: baseline;
    height: max-content;
    grid-template-columns: min-content 1fr;
    grid-template-rows: min-content;
    grid-template-areas: 'resultLabel resultValue';
  `)}
`;

export const ResultLabelWrapper = styled.div`
  grid-area: resultLabel;
  font-size: 17px;
  font-weight: bold;

  ${mediaQuery.mobileOnly(`
    font-size: 14px;
    margin-right: 10px;
  `)}
`;

export const ResultValueWrapper = styled.div`
  grid-area: resultValue;
  font-size: 35px;
  font-weight: bold;

  small {
    font-size: 17px;
    font-weight: bold;
  }
`;
