import { useEffect, useRef } from 'react';
import { resizeObserverHandler } from '../utils/commonUtils';

const useResizeObserver = (currentPosition: number) => {
  const appRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) =>
      resizeObserverHandler(entries, currentPosition)
    );

    if (appRef.current) {
      resizeObserver.observe(appRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [currentPosition]);

  return { appRef };
};

export default useResizeObserver;
