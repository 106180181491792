//Sheen images
import Mate from '../images/coatImage/1-mate.png';
import BajoBrillo from '../images/coatImage/2-bajobrillo.png';
import Satinado from '../images/coatImage/3-satinado.png';
import SemiBrillante from '../images/coatImage/4-semibrillante.png';
import Brillante from '../images/coatImage/5-brillante.png';

import { ISheen } from '../models/ISheens';

const ListSheens: ISheen[] = [
  {
    id: '1',
    name: 'Mate',
    image: Mate,
    shortDescription:
      'Oculta imperfecciones, fácil de limpiar. Ideal para paredes, techos y salas de estar.',
    description: `
      <ul>
        <li>Sin reflejo</li>
        <li>Disimula las imperfecciones pero es el más difícil de limpiar</li>
        <li>Ideal en paredes o techo</li>
        <li>Recomendado para dormitorios, escritorios o sala de estar</li>
      </ul>
      `,
    brightness: '0.95',
  },
  {
    id: '4',
    name: 'Bajo Brillo',
    image: BajoBrillo,
    shortDescription:
      'Oculta pequeñas imperfecciones de la superficie. Ideal para living, comedor y paredes interiores.',
    description: `
      <ul>
        <li>Poco reflectante y fácil de limpiar</li>
        <li>Oculta las pequeñas imperfecciones de la superficie</li>
        <li>Ideal para las áreas de bajo uso o moderado, como living, comedor o todas las paredes interiores</li>
      </ul>
      `,
    brightness: '0.96',
  },
  {
    id: '2',
    name: 'Satinado',
    image: Satinado,
    shortDescription:
      'Apariencia perlada suave. Oculta imperfecciones. Ideal para áreas de tráfico medio/alto expuestas a la humedad.',
    description: `
      <ul>
        <li>Apariencia perlada suave. Es la mejor opción para tener algo de brillo sin que se noten las imperfecciones</li>
        <li>Ideal para zonas de tránsito medio/alto que tienen exposición a la humedad como baños y cocinas</li>
      </ul>
      `,
    brightness: '0.97',
  },
  {
    id: '3',
    name: 'Semi Brillo',
    image: SemiBrillante,
    shortDescription:
      'Brillo suave para baños, cocinas y superficies de madera. Requiere preparación previa de la superficie.',
    description: `
      <ul>
        <li>Apariencia reflectante suave</li>
        <li>Ideal para paredes de baños y cocinas, puertas y ventanas, techos de madera y suelos</li>
        <li>Resaltan las imperfecciones por lo que se requiere preparar la superficie antes de pintar</li>
      </ul>
      `,
    brightness: '0.98',
  },
  {
    id: '5',
    name: 'Brillante',
    image: Brillante,
    shortDescription:
      'Acabado suave con brillo, disimula imperfecciones. Ideal para áreas de tráfico medio/alto expuestas a la humedad.',
    description: `
      <ul>
        <li>Apariencia luminosa y brillante</li>
        <li>Muy fácil de limpiar</li>
        <li>Ideal para cocina, pasillos o puertas, etc.</li>
      </ul>
      `,
    brightness: '0.99',
  },
];

export default ListSheens;
