import { defaultSheens } from '../../../__mocks__/defaults';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { ISheen } from '../../../models/ISheens';
import {
  getAvailableSkusData,
  getSelectedSheen,
  getVisibleSheenOptions,
  setFinalSkusData,
  setSelectedSheen,
  setVisibleSheenOptions,
} from '../../../redux/colorsSlice';
import { nextPosition } from '../../../redux/stepperSlice';

const useChooseBrightness = () => {
  const dispatch = useAppDispatch();
  const selectedSheen = useAppSelector(getSelectedSheen);
  const availableSkusData = useAppSelector(getAvailableSkusData);
  const visibleCoatMenu = useAppSelector(getVisibleSheenOptions);

  const hideSheenOptions = () => {
    dispatch(setVisibleSheenOptions(false));
    resetSelectedSheen();
  };

  const hideSheenOptionsDesktop = () => {
    dispatch(setVisibleSheenOptions(false));
  };

  const toggleVisibleCoatMenu = () => {
    dispatch(setVisibleSheenOptions(!visibleCoatMenu));
  };

  const handleOnClickContinue = () => {
    const latestAvailableSkusData = availableSkusData.filter((sku) => {
      return sku.brightness == selectedSheen.id;
    });
    dispatch(setFinalSkusData(latestAvailableSkusData));
    dispatch(nextPosition());
  };

  const handleClickChangeSheen = (sheen: ISheen, isSheenSelected: boolean) => {
    dispatch(setSelectedSheen(sheen));
    if (isSheenSelected) {
      resetSelectedSheen();
    }
  };

  const resetSelectedSheen = () => {
    dispatch(setSelectedSheen(defaultSheens));
  };

  return {
    hideSheenOptions,
    handleOnClickContinue,
    handleClickChangeSheen,
    toggleVisibleCoatMenu,
    resetSelectedSheen,
    hideSheenOptionsDesktop,
    areSelectedSkusAvailable: availableSkusData.length > 0,
  };
};

export default useChooseBrightness;
