import styled from 'styled-components';

export const SheenTitle = styled.h3`
  font-size: 17px;
  margin: 5px 0 0;
`;

export const SheenOptionsWrapper = styled.div<{ visible: boolean }>`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  width: calc(100% - 32px);
  position: fixed;
  bottom: 0;
  background-color: white;
  padding: 10px 16px;
  border-radius: 5px 5px 0 0;
  box-shadow: 0px 5px 10px black;
  z-index: 10;

  h2 {
    margin: 0 0 8px;
  }
`;

export const SheenItemWrapper = styled.div`
  padding-top: 16px;
`;

export const SheenLabelWrapper = styled.label`
  display: inline-flex;
  cursor: pointer;
  width: 100%;

  &.disabled,
  &.disabled input {
    color: #bfbfbf;
    cursor: not-allowed;
  }
`;

export const CloseButton = styled.button`
  border: none;
  background: transparent;
  padding: 10px;
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 5px;
`;

export const SheenNameWrapper = styled.div`
  font-size: 12px;
  align-self: center;
  flex: 1;

  h3 {
    font-weight: bold;
    font-size: 14px;
    margin: 0;
  }
  span {
  }
`;

export const SheenColor = styled.div<{ brightness: string }>`
  appearance: none;
  margin: 0 10px 0 7px;
  min-width: 35px;
  width: 40px;
  height: 40px;
  border-radius: 3px;
  border: 0;
  z-index: 1;
  transition: all 0.2s;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  filter: contrast(${({ brightness = '0.95' }) => Number(brightness) * 100}%)
    brightness(${({ brightness = '0.95' }) => brightness});
`;
