import styled from 'styled-components';
import { mediaQuery } from '../../../utils';
import { RoundButtonProps } from '..';

export const ButtonWrapper = styled.div<RoundButtonProps>`
  margin: 16px 0 6px;
  text-align: center;
  button {
    font-weight: bold;
    min-width: 232px;
    font-size: 18px !important;
    ${mediaQuery.mobileOnly(`
      width: 100%;
    `)}
  }
`;
