import React from 'react';
import { CalculateMetersWrapper, TitleWrapper } from './styles';
import CalculateMetersForm from '../CalculateMetersForm';
import MediaComponent from '../../../../components/MediaComponent';
import ButtonGoToMain from '../ButtonGoToMain';
import ButtonGoToNextStep from '../ButtonGoToNextStep';
import MobileActionsBar from '../MobileActionsBar';
import ModalCalculateSquareMeters from '../ModalCalculateSquareMeters';

const CalculateMeters: React.FC = () => {
  return (
    <CalculateMetersWrapper data-testid="calculate-meters">
      <TitleWrapper>
        <strong>Calcula</strong> cuanta pintura necesitas
      </TitleWrapper>
      <CalculateMetersForm />
      <MediaComponent from="tablet" style={{ textAlign: 'right' }}>
        <ButtonGoToMain />
        <ButtonGoToNextStep />
      </MediaComponent>
      <MediaComponent from="mobile" to="tablet">
        <MobileActionsBar />
      </MediaComponent>
      <ModalCalculateSquareMeters />
    </CalculateMetersWrapper>
  );
};

export default CalculateMeters;
