import styled from 'styled-components';
import { COLORS } from '../../../../../utils/constants/colors';

export const MobileColorPickerWrapper = styled.div`
  background-color: #fff;
`;

export const OptionsWrapper = styled.div`
  padding: 1px 13px;
`;

export const CategoriesWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  overflow-x: auto;
  padding: 5px 0 0;
  border-bottom: 1px solid ${COLORS.gray70};
  & > * {
    margin: 0 5px;
  }
`;

export const SelectorButtonWrapper = styled.div<{ active: boolean }>`
  border-bottom: ${({ active }) =>
    active ? `3px solid ${COLORS.primary}` : 'none'};
  button {
    flex-direction: column !important;
    background: none !important;
    border: none !important;
    color: ${COLORS.gray20} !important;
    font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
    padding-bottom: 6px;
    font-size: 14px !important;
  }
`;

export const ResultColorWrapper = styled.div`
  > p {
    padding: 0 13px;
    margin: 0 0 5px;
    font-size: 14px;
  }
`;

export const SearchInputStyled = styled.div`
  margin: auto 16px;
  display: flex;
  align-items: center;
  padding: 10px 0;
  gap: 10px;

  > div {
    width: 100% !important;
  }

  input {
    font-family: 'Lato';
  }
`;

export const SearchMessageStyled = styled.div`
  margin: 0 16px;
  text-align: center;
  font-weight: bold;

  p {
    margin: 0;
    font-size: 17px;
  }

  span {
    font-weight: normal;
    font-size: 14px;
  }
`;

export const IconWrapper = styled.div`
  transform: scale(1.2);
  &:active {
    transform: scale(1.5);
  }
`;

export const BrandFilterContainer = styled.div<{ isOpen: boolean }>`
  transition: transform 0.5s ease;
  transform: translateY(${(props) => (props.isOpen ? '0' : '100%')});
`;

export const BackgroundOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
`;
