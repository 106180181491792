import { useRef, useState, useEffect } from 'react';
import {
  CardsContainer,
  CardsWrapper,
  LeftShadow,
  MobileWrapper,
  PageTitle,
  RightShadow,
} from './styles';
import QualitiesAndQuantitiesCard from '../../../../../components/QualitiesAndQuantitiesCard';
import { useAppSelector } from '../../../../../app/hooks';
import { getQualities } from '../../../../../redux/configSlice';

const QualitiesAndQuantitiesMobile = ({
  qualitiesAndQuantitiesData,
  handleQuantityChange,
}) => {
  const qualities = useAppSelector(getQualities);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);
  const isOnlyOneCard = qualitiesAndQuantitiesData.length === 1;

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;

        setIsAtStart(scrollLeft === 0);
        setIsAtEnd(scrollLeft + clientWidth >= scrollWidth);
      }
    };

    containerRef.current?.addEventListener('scroll', handleScroll);
    return () =>
      containerRef.current?.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <MobileWrapper>
      <PageTitle>
        Elige la <b>calidad y formato</b> de tu pintura
      </PageTitle>
      <CardsWrapper>
        {!isAtStart && <LeftShadow />}
        {!isAtEnd && !isOnlyOneCard && <RightShadow />}
        <CardsContainer ref={containerRef}>
          {qualitiesAndQuantitiesData.map((qualityAndQuantityData) => {
            const { quality, values, characteristics } = qualityAndQuantityData;
            return (
              <QualitiesAndQuantitiesCard
                key={quality}
                qualityName={qualities.filter((q) => q.id === quality)[0].name}
                characteristics={characteristics}
                quantityData={values}
                qualityId={quality}
                handleQuantityChange={handleQuantityChange}
              />
            );
          })}
        </CardsContainer>
      </CardsWrapper>
    </MobileWrapper>
  );
};

export default QualitiesAndQuantitiesMobile;
