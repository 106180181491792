import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import Room from './components/Room';

import {
  getRoomStatus,
  getRoomList,
  fetchRoomAsync,
  selectRoom,
  getSelectedRoom,
} from '../../redux/roomSlice';
import { nextPosition } from '../../redux/stepperSlice';
import { ChooseRoomWrapper, GridRoomWrapper, MessageWrapper } from './styles';
import Loading from '../../components/Loading';
import { IRoom } from '../../models/IRoom';
import { fetchConfigAsync, getTenant } from '../../redux/configSlice';

const ChooseRoom: React.FC = () => {
  const dispatch = useAppDispatch();
  const currentTenant = useAppSelector(getTenant);
  const imagesStatus = useAppSelector(getRoomStatus);
  const rooms = useAppSelector(getRoomList);
  const roomSelected = useAppSelector(getSelectedRoom);
  const onSelectImage = (room: IRoom) => {
    dispatch(selectRoom(room));
    dispatch(nextPosition());
  };

  useEffect(() => {
    if (roomSelected.name == '') {
      dispatch(fetchRoomAsync()); //add "currentTenant" when have Api Rooms
    }
  }, []);

  useEffect(() => {
    if (currentTenant) {
      dispatch(fetchConfigAsync(currentTenant));
    }
  }, [currentTenant]);

  return (
    <ChooseRoomWrapper data-testid="roomId">
      {imagesStatus == 'loading' ? (
        <Loading />
      ) : (
        rooms?.length > 0 && (
          <>
            <MessageWrapper>
              Elige el ambiente <strong>que deseas pintar</strong>
            </MessageWrapper>
            <GridRoomWrapper>
              {rooms.map((room, roomIndex) => (
                <Room
                  key={roomIndex}
                  room={room}
                  onClick={() => onSelectImage(room)}
                />
              ))}
            </GridRoomWrapper>
          </>
        )
      )}
    </ChooseRoomWrapper>
  );
};

export default ChooseRoom;
