import {
  NON_STANDARD_FORMAT_TENANTS,
  TENANT_CURRENCY_SYMBOL,
} from '../../utils/constants';

export const getTotalSize = (products) => {
  if (!Array.isArray(products)) {
    return 0;
  }
  let totalSize = 0;

  for (const product of products) {
    totalSize += product.distribution * parseFloat(product.size);
  }

  return totalSize;
};

export const getNormalPrice = (product) => {
  let normalPrice =
    product?.prices?.filter((price) => price.type === '2')?.[0]
      ?.originalPrice || '';

  if (normalPrice.length === 0) {
    normalPrice = product?.prices?.[0]?.originalPrice || '';
  }

  return normalPrice;
};

export const getNumericPrice = (value, tenant) => {
  let formattedValue = '';
  if (NON_STANDARD_FORMAT_TENANTS.includes(tenant)) {
    formattedValue = value.replace(/\./g, '');
  } else {
    formattedValue = value.replace(/,/g, '');
  }

  return formattedValue.length > 0 ? parseInt(formattedValue) : 0;
};

export const formatNumberToPriceString = (value, tenant) => {
  let sepertor = ',';
  if (NON_STANDARD_FORMAT_TENANTS.includes(tenant)) {
    sepertor = '.';
  }
  const result = value.toString();
  let formatted = '';
  let count = 0;

  for (let i = result.length - 1; i >= 0; i--) {
    count++;
    formatted = result[i] + formatted;

    if (count % 3 === 0 && i !== 0) {
      formatted = sepertor + formatted;
    }
  }

  return formatted;
};

export const getTotalPrice = (products: any, tenant: string) => {
  let totalPrice = 0;
  const shortTenant = tenant.substring(2).toLowerCase();
  for (const product of products) {
    const normalPrice = getNormalPrice(product);
    let formattedPrice = normalPrice;
    formattedPrice = getNumericPrice(normalPrice, shortTenant);
    totalPrice += product.distribution * formattedPrice;
  }

  let formattedTotalPrice = totalPrice as any;
  formattedTotalPrice = `${
    TENANT_CURRENCY_SYMBOL[shortTenant]
  }${formatNumberToPriceString(totalPrice, shortTenant)}`;

  return formattedTotalPrice;
};
