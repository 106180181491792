import styled from 'styled-components';

export const DesktopColorPickerWrapper = styled.div`
  background-color: #ffffff;
  width: 298px;
  height: calc(100% - 2px);
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 3px 0 0 3px;
  position: relative;
  display: grid;
`;

export const HeaderWrapper = styled.div`
  border-bottom: 1px solid #cccccc;
  display: inline-flex;
  width: 100%;
  height: 55px;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderLeftWrapper = styled.div`
  display: flex;
`;

export const HeaderRightWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  cursor: pointer;
`;

export const FilterText = styled.div`
  margin-right: 4px;
  color: #0072ce;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const IconWrapper = styled.div`
  margin-right: 15px;
`;

export const IconColorMenuHeaderWrapper = styled.img`
  padding: 11px 0 11px 15px;
  fill: #333333;
  width: 32px;
  height: 32px;
`;

export const FilterMenuContainer = styled.div`
  position: absolute;
  top: 50px;
  left: 55px;
`;

export const ContentWrapper = styled.div``;
