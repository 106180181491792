import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { roomsMock } from '../__mocks__/rooms';
import { IRoom } from '../models/IRoom';
import { IStatusAsyncThunk } from '../models/IStatusAsyncThunk';

type RoomState = {
  status: IStatusAsyncThunk;
  roomSelected: IRoom;
  rooms: IRoom[];
  isOpenRoomSelector: boolean;
};

const initialState: RoomState = {
  status: 'idle',
  isOpenRoomSelector: false,
  rooms: [],
  roomSelected: {
    name: '',
    gridArea: '',
    galleryImage: {
      mobile: '',
      desktop: '',
    },
    paintableImage: {
      desktop: '',
      mobile: '',
    },
    initColor: '',
    application: {
      id: '3',
      name: 'Interior',
    },
  },
};

const timeout = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const fetchImages = async () => {
  const arr: IRoom[] = roomsMock;
  await timeout(500);
  return arr;
};

export const fetchRoomAsync = createAsyncThunk(
  'images/fetchRoomAsync',
  async () => {
    const response = await fetchImages();
    return response;
  }
);

export const roomSlice = createSlice({
  name: 'room',
  initialState,
  reducers: {
    selectRoom: (state, action: PayloadAction<RoomState['roomSelected']>) => {
      state.roomSelected = action.payload;
    },
    setOpenRoomSelector: (
      state,
      action: PayloadAction<RoomState['isOpenRoomSelector']>
    ) => {
      state.isOpenRoomSelector = action.payload;
    },
  },
  extraReducers: (builder) => {
    //ROOMS
    builder
      .addCase(fetchRoomAsync.pending, (state) => {
        state.status = 'loading';
        state.rooms = [];
      })
      .addCase(fetchRoomAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.rooms = action.payload;
      });
  },
});
//Export actions
export const { selectRoom, setOpenRoomSelector } = roomSlice.actions;

//Definición de selectores
export const getRoomList = (state: RootState) => state.room.rooms;
export const getRoomStatus = (state: RootState) => state.room.status;
export const getSelectedRoom = (state: RootState) => state.room.roomSelected;
export const getIsOpenRoomSelector = (state: RootState) =>
  state.room.isOpenRoomSelector;

export default roomSlice.reducer;
