const getHex = (rgb) => {
  let hex = Number(rgb).toString(16);
  if (hex.length < 2) {
    hex = '0' + hex;
  }
  return hex;
};
const rgbToHex = (rgbString: string) => {
  const rgb = rgbString.split(',');
  if (rgb.length == 3) {
    const red = getHex(rgb[0]);
    const green = getHex(rgb[1]);
    const blue = getHex(rgb[2]);
    return `#${red}${green}${blue}`;
  } else return '#ffffff';
};

export default rgbToHex;
