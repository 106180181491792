import React, { ReactElement } from 'react';
import { AlertContentWrapper, AlertIconWrapper, AlertWrapper } from './styles';
import { ReactComponent as Warning } from '../../../src/images/icons/warning.svg';

type AlertProps = {
  variant: 'warning';
};

type IAlertIcon = {
  // eslint-disable-next-line no-unused-vars
  [key in AlertProps['variant']]: ReactElement;
};

const icon: IAlertIcon = {
  warning: <Warning />,
};

const Alert: React.FC<AlertProps> = ({ variant, children }) => {
  return (
    <AlertWrapper data-testid="alert" className={variant}>
      <AlertIconWrapper>{icon[variant]}</AlertIconWrapper>
      <AlertContentWrapper>{children}</AlertContentWrapper>
    </AlertWrapper>
  );
};

export default Alert;
