import styled from 'styled-components';

export const SidebarWrapper = styled.div`
  background-color: #ffffff;
`;

export const ContainerDesktopWrapper = styled.div`
  width: 298px;
  height: calc(100% - 2px);
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 3px 0 0 3px;
`;

export const MenuColorDesktopWrapper = styled.div`
  flex: 1;
  height: 45vw;
  overflow: overlay;
  margin-top: -1px;
`;

export const ContainerMobileWrapper = styled.div``;

export const MenuHeaderWrapper = styled.div`
  border-bottom: 1px solid #cccccc;
  display: inline-flex;
  width: 100%;
  height: 55px;
`;

export const IconColorMenuHeaderWrapper = styled.img`
  padding: 11px 0 11px 15px;
  fill: #333333;
  width: 32px;
  height: 32px;
`;
