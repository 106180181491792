import React from 'react';
import { Button, Modal, UiKitProvider } from '@digital-retail/journey-ui-kit';
import {
  ActionsWrapper,
  ButtonGoToShoppingCartWrapper,
  ButtonSeeMoreProductsWrapper,
  IconWrapper,
  ModalAddToCartWrapper,
  ModalTitleWrapper,
} from './styles';
import { ReactComponent as Success } from '../../../../images/icons/ico-success.svg';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { getAddToCart } from '../../../../redux/settingQualitySlice';
import { getTenant, resetApp } from '../../../../redux/configSlice';
import { DYNAMIC_CONTENT } from '../../../../utils/constants/dynamicContent';
import { tenantSpecificConfig } from '../../../../utils/constants';

const ModalTitle: React.FC = () => {
  return (
    <ModalTitleWrapper>
      <IconWrapper>
        <Success />
      </IconWrapper>
      Producto agregado al Carro
    </ModalTitleWrapper>
  );
};

const ModalAddToCart: React.FC = () => {
  const dispatch = useAppDispatch();
  const open = useAppSelector(getAddToCart);
  const currentTenant = useAppSelector(getTenant);
  const dynamicContent = DYNAMIC_CONTENT[currentTenant];
  const REACT_APP_ENVIRONMENT =
    process.env.REACT_APP_ENVIRONMENT || 'production';
  const businessUnit = currentTenant.substring(0, 2).toUpperCase();
  const shortTenant = currentTenant.substring(2).toLowerCase();
  const { cartUrl } =
    tenantSpecificConfig[REACT_APP_ENVIRONMENT][businessUnit][shortTenant];

  const handleClickGoToShoppingCart = () => {
    window.parent.location.href = cartUrl;
  };

  const handleClickSeeMoreProducts = () => {
    dispatch(resetApp());
  };

  return (
    <UiKitProvider theme={dynamicContent.themeUiKit}>
      <ModalAddToCartWrapper data-testid="modal-add-to-cart">
        <Modal open={open} variant="success" title={<ModalTitle />}>
          <ActionsWrapper>
            <ButtonSeeMoreProductsWrapper>
              <Button
                variant="ghost"
                size="md"
                data-testid="button-see-more-products"
                onClick={handleClickSeeMoreProducts}
              >
                {dynamicContent.buttonSeeMore}
              </Button>
            </ButtonSeeMoreProductsWrapper>{' '}
            <ButtonGoToShoppingCartWrapper>
              <Button
                variant="transactional"
                size="md"
                data-testid="button-go-to-shopping-cart"
                onClick={handleClickGoToShoppingCart}
                pill={dynamicContent.buttonPill}
                style={{ minWidth: '180px' }}
              >
                {dynamicContent.buttonGoToCart}
              </Button>
            </ButtonGoToShoppingCartWrapper>
          </ActionsWrapper>
        </Modal>
      </ModalAddToCartWrapper>
    </UiKitProvider>
  );
};

export default ModalAddToCart;
