/* istanbul ignore file */
import './App.css';
import { useEffect } from 'react';
import { UiKitProvider } from '@digital-retail/journey-ui-kit';
import Route from './features/Route';
import isValidTenant from './utils/isValidTenat';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { setHost, setOs, setTenant } from './redux/configSlice';
import { views } from './features/Route/views';
import { getCurrenPosition } from './redux/stepperSlice';
import { useLocation } from 'react-router-dom';
import React from 'react';
import useResizeObserver from './hooks/useResizeObserver';
import { useScripts } from './hooks/useScripts';

const App = () => {
  const dispatch = useAppDispatch();
  const currentPosition = useAppSelector(getCurrenPosition);
  const currentView = `Journey tools: pinturas paso ${currentPosition + 1} ${
    views[currentPosition].title
  }`;
  const queryParams = new URLSearchParams(useLocation().search);
  const { appRef } = useResizeObserver(currentPosition);
  useScripts();

  useEffect(() => {
    const host = queryParams.get('host') || '';
    const os = queryParams.get('os') || '';
    dispatch(setHost(host));
    dispatch(setOs(os));
  }, []);

  useEffect(() => {
    const tenant = queryParams.get('tenant') || 'socl';
    if (tenant && isValidTenant(tenant)) {
      dispatch(setTenant(tenant));
    }
    // Adobe launch
    const adobeLaunchDigitalDataObject = () => {
      const digitalData = global.window.digitalData
        ? global.window.digitalData
        : [];
      if (digitalData.page) {
        digitalData.page.canal = global.window.digitalData.page.canal;
        digitalData.page.menuCategory =
          global.window.digitalData.page.menuCategory;
        digitalData.page.loginStatus =
          global.window.digitalData.page.loginStatus;
        digitalData.page.pageName = currentView;
        digitalData.page.pageType = global.window.digitalData.page.pageType;
        digitalData.page.catalyst = global.window.digitalData.page.catalyst;
        digitalData.page.previousUrl =
          global.window.digitalData.page.previousUrl;
        digitalData.page.sessionId = global.window.digitalData.page.sessionId;
        digitalData.page.siteSection =
          global.window.digitalData.page.siteSection;
        digitalData.page.subSection = global.window.digitalData.page.subSection;
        digitalData.page.url = global.window.location.href;
      }
      if (digitalData.server) {
        digitalData.server.name = global.window.digitalData.server.name;
        digitalData.server.line = global.window.digitalData.server.line;
      }
    };
    adobeLaunchDigitalDataObject();
  }, [currentView]);

  return (
    <div data-testid="app" ref={appRef}>
      <UiKitProvider>
        <Route />
      </UiKitProvider>
    </div>
  );
};

export default App;
