import styled from 'styled-components';
import { TitleMenuProps } from '..';

export const TitleMenuWrapper = styled.p<TitleMenuProps>`
  margin: 0;
  padding: 15px 20px;
  font-weight: bold;
  font-size: 20px;
  color: ${({ loading }) => (loading == 'loading' ? '#8C8C8C' : '#333333')};
`;
