import React from 'react';
import { StepperDesktop } from '@digital-retail/journey-ui-kit';
import { StepperDesktopWrapper } from './styles';
import { getCurrenPosition, setPosition } from '../../redux/stepperSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { views } from '../../features/Route/views';
import useResetNextSteps from '../../hooks/useResetNextSteps';

const Stepper = () => {
  const position = useAppSelector(getCurrenPosition);
  const dispatch = useAppDispatch();
  const { resetNextSteps } = useResetNextSteps();

  const comeBackTo = (newPosition: number) => {
    if (newPosition >= 0) {
      dispatch(setPosition(newPosition));
      if (newPosition < position) {
        resetNextSteps(newPosition);
      }
    }
  };

  return (
    <StepperDesktopWrapper data-testid="stepper-desktop">
      <StepperDesktop
        currentStep={position + 1}
        labels={views.map((item) => item.title)}
        onClick={(stepIndex: number) => comeBackTo(stepIndex)}
      />
    </StepperDesktopWrapper>
  );
};

export default Stepper;
