import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from '@reduxjs/toolkit';
import ColorsReducer from '../redux/colorsSlice';
import RoomReducer from '../redux/roomSlice';
import StepperReducer from '../redux/stepperSlice';
import CalculateQuantityReducer from '../redux/calculateQuantitySlice';
import GallonsReducer from '../redux/gallonsSlice';
import SettingQualityReducer from '../redux/settingQualitySlice';
import ConfigReducer from '../redux/configSlice';
import ChooseColorReducer from '../redux/chooseColorSlice';

import { RecursivePartial } from '../utils/recursivePartialType';

const allReducer = {
  config: ConfigReducer,
  room: RoomReducer,
  stepper: StepperReducer,
  colors: ColorsReducer,
  calculateQuantity: CalculateQuantityReducer,
  gallons: GallonsReducer,
  settingQuality: SettingQualityReducer,
  chooseColor: ChooseColorReducer,
};

const combinedReducer = combineReducers({
  ...allReducer,
});

export const rootReducer = (state, action) => {
  if (action.type === 'config/resetApp') {
    return combinedReducer(undefined, action);
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV === 'development',
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type InitialRootState = RecursivePartial<RootState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
