import { IGallon } from '../models/IGallon';

export const gallons: IGallon[] = [
  {
    id: '1',
    size: '0.25',
    description: '1/4 Galón',
    information:
      'Tarro de 14 cm alto &nbsp;|&nbsp; Rinde 9.5 m<sup>2</sup> con una mano',
  },
  {
    id: '2',
    size: '1',
    description: '1 Galón',
    information:
      'Tarro de 21 cm alto &nbsp;|&nbsp; Rinde 38 m<sup>2</sup> con una mano',
  },
  {
    id: '3',
    size: '2',
    description: '2 Galones',
    information:
      'Tarro de 22 cm alto &nbsp;|&nbsp; Rinde 76 m<sup>2</sup> con una mano',
  },
  {
    id: '4',
    size: '4',
    description: '4 Galones',
    information:
      'Tarro de 36 cm alto &nbsp;|&nbsp; Rinde 160 m<sup>2</sup> con una mano',
  },
];
