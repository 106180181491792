import { MobileFilterBody } from './styles';
import StyledCheckbox from '../../CheckBox';
import useFilterMenu from '../../../hooks/useFilterMenu';

const FilterMenuMobile = () => {
  const { updateBrands, brands, checkedBrands } = useFilterMenu();

  const onChange = (event: any, brandId: string) => {
    updateBrands(event, brandId);
  };

  return (
    <MobileFilterBody>
      {brands.map((brand) => {
        const brandId = brand.id || '';
        const isChecked = checkedBrands.includes(brandId);
        return (
          <StyledCheckbox
            key={brand.id}
            label={brand.name}
            checked={isChecked}
            value={brand.id}
            onChange={(event) => onChange(event, brandId)}
          />
        );
      })}
    </MobileFilterBody>
  );
};

export default FilterMenuMobile;
