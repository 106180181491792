/* istanbul ignore file */
import { IMedia } from './medias';

type IBreakPoint = {
  // eslint-disable-next-line no-unused-vars
  [key in IMedia]: number;
};

export const BREAKPOINTS: IBreakPoint = {
  mobile: 320,
  tablet: 720,
  desktop: 1024,
  superDesktop: 1280,
};
