import styled from 'styled-components';
import { RoomForMediaComponentProps } from '..';
import { mediaQuery } from '../../../../../utils';

export const RoomWrapper = styled.button<RoomForMediaComponentProps>`
  grid-area: ${({ gridArea }) => gridArea};
  background-image: url(${({ image }) => image});
  background-color: rgb(255, 255, 255);
  border-radius: 2px;
  border-width: 0;
  cursor: pointer;
  display: inline-grid;
  align-content: flex-start;
  justify-content: flex-start;
  background-size: cover;
  padding: 0;
`;

export const LabelRoomWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 3px;
  color: #ffffff;
  display: block;
  font-size: 1.063rem;
  font-weight: bold;
  margin: 0;
  padding: 5px 20px;
  text-align: center;
  width: fit-content;

  ${mediaQuery.mobileOnly(`
    font-size: 14px;
  `)}
`;
