import React from 'react';
import { Spinner } from '@digital-retail/journey-ui-kit';
import { LoadingWrapper } from './styles';

const Loading = () => {
  return (
    <LoadingWrapper data-testid="loading">
      <Spinner size="sm" />
      <p>
        Estamos preparando la <br />
        pared para pintar
      </p>
    </LoadingWrapper>
  );
};

export default Loading;
