import { IColor } from '../models/IColor';
import { ISheen } from '../models/ISheens';

export const defaultSheens: ISheen = {
  id: '',
  name: '',
  image: '',
  description: '',
};

export const defaultSelectedColor: IColor = {
  name: '',
  brand: '',
  id: '',
  rgb: '',
  group: '',
  skus: [],
  hex: '',
};
