import { PropsWithChildren } from 'react';
import { BackButtonWrapper } from './styles';
import { PillButton } from '@digital-retail/journey-ui-kit';
import { ReactComponent as LeftArrow } from '../../images/icons/left-arrow.svg';
import { COLORS } from '../../utils/constants/colors';

type BackButtonProps = PropsWithChildren<{
  onClick?: () => void;
}>;

const BackButton = ({ children, onClick }: BackButtonProps) => {
  return (
    <BackButtonWrapper>
      <div>
        <PillButton
          size="xs"
          variant="ghost"
          data-testid="back-button"
          onClick={onClick}
          style={{
            fontWeight: 'bold',
            padding: 10,
            fontSize: 14,
          }}
          Icon={LeftArrow}
          iconStyle={{
            fill: COLORS.primary,
            width: 10,
            height: 10,
            marginBottom: 2,
          }}
        >
          {children}
        </PillButton>
      </div>
    </BackButtonWrapper>
  );
};

export default BackButton;
