import { IGallon } from '../../models/IGallon';
import { gallons } from '../../__mocks__/gallons';

const delay = 0;

export const fetchGallons = () =>
  new Promise<IGallon[]>((resolve) => {
    setTimeout(() => {
      resolve(gallons);
    }, delay);
  });
