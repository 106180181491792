import React from 'react';
import {
  HeaderColorWrapper,
  InformationBoxWrapper,
  NameWrapper,
  DescriptionWrapper,
} from './styles';
import { useAppSelector } from '../../app/hooks';
import {
  getConfirmedColor,
  getSelectedColor,
  getSelectedSheen,
} from '../../redux/colorsSlice';
import ColorDescription from '../ColorDescription';
import htmlReactParser from 'html-react-parser';
import { getBrands } from '../../redux/configSlice';

const HeaderColor: React.FC = () => {
  const selectedColor = useAppSelector(getSelectedColor);
  const selectedSheen = useAppSelector(getSelectedSheen);
  const colorIsConfirmed = useAppSelector(getConfirmedColor);
  const showColorDescription = colorIsConfirmed || selectedColor.id !== '';
  const allBrands = useAppSelector(getBrands);

  return (
    <HeaderColorWrapper data-testid="header-color">
      {showColorDescription && (
        <ColorDescription
          color={selectedColor}
          first={true}
          allBrands={allBrands}
        />
      )}
      {colorIsConfirmed && selectedSheen.id !== '' && (
        <InformationBoxWrapper>
          {selectedSheen.name ? (
            <NameWrapper>
              {selectedSheen.name[0].toUpperCase()}
              {selectedSheen.name.substring(1)}
            </NameWrapper>
          ) : null}
          {selectedSheen.description ? (
            <DescriptionWrapper>
              {htmlReactParser(selectedSheen.description)}
            </DescriptionWrapper>
          ) : null}
        </InformationBoxWrapper>
      )}
    </HeaderColorWrapper>
  );
};

export default HeaderColor;
