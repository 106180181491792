import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

interface StepperState {
  position: number;
}

const initialState: StepperState = {
  position: 0,
};

export const stepperSlice = createSlice({
  name: 'stepper',
  initialState,
  reducers: {
    setPosition: (state, action: PayloadAction<number>) => {
      if (action.payload >= 0) {
        state.position = action.payload;
      }
    },
    previousPosition: (state) => {
      state.position -= 1;
    },
    nextPosition: (state) => {
      state.position += 1;
    },
  },
});

//Export actions
export const { setPosition, nextPosition, previousPosition } =
  stepperSlice.actions;

//Definición de selectores
export const getCurrenPosition = (state: RootState) => state.stepper.position;

export default stepperSlice.reducer;
