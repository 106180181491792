import styled from 'styled-components';
import { ButtonIconProps, ContainerCoatProps, RowCoatProps } from '..';

const arrowPosition = [22, 76, 130, 184];

const getIndexRow = (index) => Math.floor(index / 4);

const getIndexColumn = (index: number) => {
  return index < 4
    ? arrowPosition[index]
    : arrowPosition[index - getIndexRow(index) * 4];
};

export const ContainerCoat = styled.div<ContainerCoatProps>`
  height: auto;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: -5px;
  margin-right: -3px;
  transform: scale(1.01);
  transition: all 0.4s !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
  overflow: visible;
  border-radius: 3px;
  z-index: 10;
  grid-column: span 4;
  grid-row: ${({ index }) => getIndexRow(index) + 2};
  ${({ visible }) => !visible && 'display: none'}
`;

export const ArrowCoat = styled.div<RowCoatProps>`
  position: absolute;
  background-color: #ffffff;
  margin-top: -10px;
  height: 20px;
  width: 20px;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.5);
  transform: rotate(45deg);
  z-index: -1;
  ${({ index }) => `left: ${getIndexColumn(index)}px; transition: left 0.1s;`}

  &::after {
    content: '';
    background-color: #ffffff;
    width: 19px;
    height: 40px;
    top: -3px;
    position: absolute;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    left: 7px;
  }
`;

export const CoatButton = styled.div`
  width: 216px;
  position: absolute;
  bottom: 0px;
  padding-left: 15px;
  padding-bottom: 11px;
  padding-top: 11px;
  padding-right: 22px;
`;

//SELECTION COLOR
export const MenuColorDesktopStyled = styled.div`
  display: inline-flex;
  width: 100%;
  height: max-content;

  &.loading {
    opacity: 0.5;
    pointer-events: none;
    position: relative;
  }
`;

export const AsideOptionsStyled = styled.div`
  width: 44px;
  height: auto;
  background-color: #f5f5f5;
  border-right: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
`;

export const OptionsStyled = styled.div`
  justify-content: center;
  width: 38px;
`;

export const OptionStyled = styled.button<ButtonIconProps>`
  border-width: 1px 0 1px 1px;
  border-color: ${({ active }) => (active ? '#cccccc' : 'transparent')};
  opacity: ${({ active }) => (active ? '1' : '0.6')};
  border-radius: 3px 0 0 3px;
  border-style: solid;
  width: 100%;
  cursor: pointer;
  margin-left: 7px;
  padding: ${({ firstChild }) => (firstChild ? '1px 0 0 0' : '0')};
  margin-top: ${({ firstChild }) => (firstChild ? '-1px' : '0')};
  padding-left: ${({ active }) => (active ? '5px' : '0')};
  padding-right: 4px;
  background-color: ${({ active }) => (active ? '#ffffff' : 'transparent')};
  transition-duration: 0.1s;
  transition-property: all;
`;

export const IconStyled = styled.div`
  padding: 0;
  margin: 8px 0;
`;

//Pallet Color
export const GridColor = styled.div`
  width: 212px;
  padding: 20px 14px 20px 12px;
  display: grid;
  grid-template-columns: repeat(4, 50px) !important;
  grid-auto-rows: auto !important;
  grid-gap: 5px;
  height: fit-content;
`;

export const SearchInputStyled = styled.div`
  margin: 2px 5px;
  & > div {
    width: 100% !important;
  }
`;

export const SearchMessageStyled = styled.div`
  margin: 20px 17px 0 17px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
`;

export const MainOptionsStyled = styled.div`
  height: 464px;
  position: relative;
`;

export const ColorMesssageStyled = styled.span`
  padding: 8px 4px 0 4px;
  font-size: 12px;
  display: grid;
`;
