import React from 'react';
import { Spinner } from '@digital-retail/journey-ui-kit';
import { LoadingWrapper } from './styles';

const Loading: React.FC = () => {
  return (
    <LoadingWrapper>
      <Spinner size="sm" />
    </LoadingWrapper>
  );
};

export default Loading;
