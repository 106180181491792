import { ISheen } from '../../models/ISheens';
import { useAppSelector } from '../../app/hooks';
import {
  getAvailableSkusData,
  getSelectedColor,
} from '../../redux/colorsSlice';
import '../../features/ChooseColor/components/GridColorButton/style/index.css';

import {
  CoatItemWrapper,
  CoatLabelWrapper,
  CoatNameWrapper,
  CoatOptionsWrapper,
  RadioButtonWrapper,
} from './styles';
import rgbToHex from '../../utils/rgbToHex';
import { getUniqueBrigthnessTypes } from '../../utils/commonUtils';

type SheenOptionProps = {
  sheenList: ISheen[];
  selectedSheen: ISheen;
  // eslint-disable-next-line no-unused-vars
  setSheen: (sheen: ISheen, isSheenSelected: boolean) => void;
};

const SheenOptions: React.FC<SheenOptionProps> = ({
  sheenList,
  selectedSheen,
  setSheen,
}) => {
  const selectedColor = useAppSelector(getSelectedColor);
  const availableSkusData = useAppSelector(getAvailableSkusData);
  const possibleSheenTypes = getUniqueBrigthnessTypes(availableSkusData);
  const latestSheenList = sheenList.filter((sheen) => {
    return sheen.id in possibleSheenTypes;
  });

  return (
    <CoatOptionsWrapper data-testid="coat-options">
      {latestSheenList.map((sheen, sheenIndex) => {
        const isSheenSelected = sheen.id === selectedSheen.id;

        return (
          <CoatItemWrapper key={sheenIndex}>
            <CoatLabelWrapper className={sheen.disabled ? 'disabled' : ''}>
              <RadioButtonWrapper
                type="radio"
                name="radio"
                data-testid={`radio-button-coat-${sheenIndex}`}
                onClick={() => setSheen(sheen, isSheenSelected)}
                disabled={sheen.disabled}
                style={{
                  backgroundColor: rgbToHex(selectedColor.rgb),
                  backgroundImage: `url(${sheen.image})`,
                }}
                className={isSheenSelected ? 'checked' : ''}
              />
              <CoatNameWrapper className={isSheenSelected ? 'checked' : ''}>
                {sheen.name}
              </CoatNameWrapper>
            </CoatLabelWrapper>
          </CoatItemWrapper>
        );
      })}
    </CoatOptionsWrapper>
  );
};

export default SheenOptions;
