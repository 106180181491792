import { useAppDispatch } from '../app/hooks';
import { resetCalculatorQuantityState } from '../redux/calculateQuantitySlice';
import { resetChooseColorState } from '../redux/chooseColorSlice';
import { reset } from '../redux/colorsSlice';
import { resetSettingQualityState } from '../redux/settingQualitySlice';

const useResetNextSteps = () => {
  const dispatch = useAppDispatch();

  const resetStep2State = () => {
    dispatch(reset());
    dispatch(resetChooseColorState());
  };

  const resetStep3State = () => {
    dispatch(resetCalculatorQuantityState());
  };

  const resetStep4State = () => {
    dispatch(resetSettingQualityState());
  };

  const resetNextSteps = (newPosition) => {
    // step-1 position starts from 0
    if (newPosition === 0) {
      resetStep2State();
      resetStep3State();
      resetStep4State();
    }

    if (newPosition === 1) {
      resetStep3State();
      resetStep4State();
    }

    if (newPosition === 2) {
      resetStep4State();
    }
  };

  return { resetNextSteps };
};

export default useResetNextSteps;
