import styled from 'styled-components';
import { mediaQuery } from '../../../utils';

export const AlertWrapper = styled.div`
  border-radius: 3px;
  padding: 8px;

  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 16px 1fr;
  grid-template-rows: 1fr;
  gap: 6px;
  grid-template-areas: 'iconAlert contentAlert';
  align-items: start;

  ${mediaQuery.tabletToUp(`min-width: calc(315px - (8px * 2));`)}

  &.warning {
    background-color: #fcf5cb;
    color: #866404;
  }
`;

export const AlertIconWrapper = styled.div`
  grid-area: iconAlert;
`;

export const AlertContentWrapper = styled.div`
  grid-area: contentAlert;
  font-size: 14px;
`;
